import React, { useState, useRef, useEffect } from 'react';
import ColorPicker from '../../../components/ColorPicker';
import { getClosestColor } from '../../../components/colorMapper';
import { useDesign } from '../../../hooks/DesignContext';
import sendToDiscord from '../../../utils/discordWebhook';
import '../Page.css';
import '../../design/decor/Design.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { getAuth } from 'firebase/auth';
import CryptoJS from 'crypto-js';
import { generateAuthToken } from '../../../utils/encryptExternalID';
import getImageUrl from '../../../utils/imageUtils';
import WeddingCakesCanvas from './WeddingCakesCanvas';
import './WeddingCakes.css';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const SECRET_KEY = process.env.REACT_APP_WEDIFY_APP_SECRET_KEY;

const encryptPrompt = (plaintext, key) => {
    try {
      const keyBytes = CryptoJS.enc.Hex.parse(key);
      const encrypted = CryptoJS.AES.encrypt(plaintext, keyBytes, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      return encrypted.toString();
    } catch (error) {
      return null;
    }
  };
  

const WeddingCakes = () => {
  const { 
    selectedSize, setSelectedSize,
    selectedTier, setSelectedTier,
    selectedShape, setSelectedShape,
    selectedFlavor, setSelectedFlavor,
    selectedDecoration, setSelectedDecoration,
    selectedColor, setSelectedColor,
    selectedDecorationSubOption, setSelectedDecorationSubOption,
    cakeVisualizationUrls, setCakeVisualizationUrls,
    isGenerationComplete,setIsGenerationComplete,
    cakePrompt, setCakePrompt,
    loading, setLoading,
    progress, setProgress
  } = useDesign();
  
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showAddInput, setShowAddInput] = useState({ size: false, tier: false, flavor: false, decoration: false});
  const [newInput, setNewInput] = useState({ size: '', tier: '', flavor: '', decoration: '' });
  const [currentStep, setCurrentStep] = useState(1);
  
  //Options for Size
  const sizeOptions = [
    { name: 'Small', image: getImageUrl('cake/small.jpg') },
    { name: 'Medium', image: getImageUrl('cake/medium.jpg') },
    { name: 'Large', image: getImageUrl('cake/large.jpg') }
  ];

  const tierOptions = [
    { name: 'One-Tier', image: getImageUrl('cake/onetiercake.jpg') },
    { name: 'Two-Tier', image: getImageUrl('cake/twotiercake.jpg') },
    { name: 'Three-Tier', image: getImageUrl('cake/threetiercake.jpg') },
    { name: 'Four-Tier', image: getImageUrl('cake/fourtiercake.jpg') },
    { name: 'Five-Tier', image: getImageUrl('cake/fivetiercake.jpg') },
    { name: 'Six-Tier', image: getImageUrl('cake/sixtiercake.jpg') },
    { name: 'Add', image: getImageUrl('themesOption/add-plus.jpg') }
  ];

  const shapeOptions = [
    { name: 'Round', image: getImageUrl('cake/circle.jpg') },
    { name: 'Square', image: getImageUrl('cake/square.jpg') },
    { name: 'Heart', image: getImageUrl('cake/heart.jpg') },
    { name: 'Add', image: getImageUrl('themesOption/add-plus.jpg') }
  ];

  const flavorOptions = [
    { name: 'Vanilla', image: getImageUrl('cake/vanillacake.jpg') },
    { name: 'Chocolate', image: getImageUrl('cake/chocolatecake.jpg') },
    { name: 'Red Velvet', image: getImageUrl('cake/redvelvetcake.jpg') },
    { name: 'Add', image: getImageUrl('themesOption/add-plus.jpg') }
  ];

  const decorationOptions = [
    { name: 'Shards', image: getImageUrl('cake/shard.jpg') },
    { name: 'Drips', image: getImageUrl('cake/drip.jpg') },
    { name: 'Fruits', image: getImageUrl('cake/fruit.jpg') },
    { name: 'Floral', image: getImageUrl('cake/floral.jpg') },
    { name: 'Add', image: getImageUrl('themesOption/add-plus.jpg') }
  ];

  // ... (keep color presets)
  const colorPresets = [
    { name: 'White', hex: '#FFFFFF' },     // Pure white - classic wedding cake color
    { name: 'Pearl White', hex: '#F0EAD6' }, // Soft white with warm undertones
    { name: 'Ivory', hex: '#FFFFF0' },     // Creamy white
    { name: 'Gold', hex: '#dcc282' },
    { name: 'Rose Gold', hex: '#B76E79' },
    { name: 'Silver', hex: '#C0C0C0' },
    { name: 'Copper', hex: '#B87333' },
    { name: 'Blush Pink', hex: '#FFB6C1' },
    { name: 'Sage Green', hex: '#9DC183' },
    { name: 'Eucalyptus', hex: '#44D7A8' },
    { name: 'Olive Green', hex: '#808000' },
    { name: 'Navy Blue', hex: '#000080' },
    { name: 'French Blue', hex: '#0072B5' },
    { name: 'Slate Blue', hex: '#6A5ACD' },
    { name: 'Dusty Blue', hex: '#6699CC' },
    { name: 'Periwinkle', hex: '#CCCCFF' },
    { name: 'Burgundy', hex: '#800020' },
    { name: 'Mulberry', hex: '#C54B8C' },
    { name: 'Plum', hex: '#8E4585' },
    { name: 'Champagne', hex: '#F7E7CE' },
    { name: 'Lavender', hex: '#E6E6FA' },
    { name: 'Mauve', hex: '#E0B0FF' },
    { name: 'Terracotta', hex: '#E2725B' },
    { name: 'Rust', hex: '#B7410E' },
    { name: 'Gray', hex: '#808080' },
    { name: 'Taupe', hex: '#483C32' },
    { name: 'Emerald', hex: '#50C878' },
    { name: 'Coral', hex: '#FF7F50' },
    { name: 'Mint', hex: '#98FF98' },
    { name: 'Peach', hex: '#FFE5B4' },
    { name: 'Custom', image: getImageUrl('themesOption/colorPicker.png') }
  ];

  // Handlers
  const handleSizeChange = (size) => {

    setSelectedSize(size);
  }

  const handleTierChange = (tier) => {
   
    setSelectedTier(tier);
  };

  const handleShapeChange = (shape) => {
   
    setSelectedShape(shape);
  }

  const handleFlavorChange = (flavor) => {
    
    setSelectedFlavor(flavor);
  };

  const handleDecorationChange = (decoration) => {
 
    setSelectedDecoration(decoration);
  };

  const handleDecorationSubOptionChange = (subOption) => {
  
    setSelectedDecorationSubOption(subOption);
  };

  const handleColorChange = (color) => {

    setSelectedColor(color.hex);
  };

    // ... (kept similar checkGenerationStatus)
  const checkGenerationStatus = async (generationId) => {
    let attempts = 0;
    const maxAttempts = 30;
    const initialDelay = 1000;
    const maxDelay = 10000;
      
    const poll = async () => {
      if (attempts >= maxAttempts) {
        throw new Error('Generation timed out');
      }
  
      attempts++;
  
      try {
        const token = generateAuthToken(); // Generate token for each request
        const response = await fetch(`${API_BASE_URL}/api/images/get/image/retrieve`, {
            method: 'GET',
            headers: {
              'imageId': generationId,
              'X-Auth-Token': token, // Add the token to the headers
            },
          });
          
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          const contentType = response.headers.get('Content-Type');
          const contentLength = response.headers.get('Content-Length');
  
          if (contentType && contentType.includes('image') && contentLength && parseInt(contentLength) > 0) {
            const blob = await response.blob();
            return URL.createObjectURL(blob);
          } else {
            const delay = Math.min(initialDelay * Math.pow(1.5, attempts), maxDelay);
            await new Promise(resolve => setTimeout(resolve, delay));
            return poll();
          }
        } catch (error) {
          const delay = Math.min(initialDelay * Math.pow(1.5, attempts), maxDelay);
          await new Promise(resolve => setTimeout(resolve, delay));
          return poll();
        }
      };
  
    return poll();
  };

  const fetchCredits = async () => {
    try {
      const token = await generateAuthToken();
      const response = await fetch(`${API_BASE_URL}/api/credits`, {
        method: 'GET',
        headers: {
          'X-Auth-Token': token,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      // Update state or context with the fetched credits if needed
    } catch (error) {
      
    }
  };

  const generateVisualizations = async () => {
    if (!selectedSize || !selectedTier || !selectedShape || !selectedFlavor || !selectedDecoration || !selectedColor) {
      console.error('Not all required fields are selected');
      return;
    }

    setLoading(true);
    setIsGenerationComplete(false);
    setProgress(0);
    setCurrentStep(7);

    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;
        
      if (!currentUser) {
          console.error('Authentication error: No user found');
          throw new Error('No authenticated user found');
      }
  
      setProgress(10);
  
      const token = await generateAuthToken();
      if (!token) {
          console.error('Token generation failed');
          throw new Error('Failed to generate authentication token');
      }
  
      // Get color name - handle both preset and custom colors
      let colorName;
      const selectedColorObj = colorPresets.find(color => color.hex === selectedColor);
      
      if (selectedColorObj) {
        // If it's a preset color, use its name
        colorName = selectedColorObj.name;
      } else {
        // If it's a custom color, get the closest named color
        colorName = getClosestColor(selectedColor);
       
      }
      
      // Define prompts with the color name
      const cakePromptText = `A beautiful ${selectedSize} ${selectedTier} ${selectedShape} shaped wedding cake with ${selectedFlavor} flavor and ${selectedDecoration} decoration in ${colorName} color scheme, professional photography, high end cake design`;
 
      setCakePrompt(cakePromptText);
  
      // Generate image
      const cakeUrl = await generateImage(cakePromptText, token, currentUser.uid);
     
  
      setCakeVisualizationUrls([cakeUrl]);
      setIsGenerationComplete(true);
      setProgress(100);
  
      // Fetch credits after the image is generated
      await fetchCredits();
  
    } catch (error) {
      
    } finally {
      setLoading(false);
    }
  };

  const generateImage = async (prompt, token, userId) => {
    try {
        
      const encryptedPrompt = encryptPrompt(prompt, SECRET_KEY);
        if (!encryptedPrompt) {
          console.error('Prompt encryption failed');
          throw new Error('Failed to encrypt prompt');
        }
  
      const generateResponse = await fetch(`${API_BASE_URL}/api/images/get/image/generate`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': token,
          'whatYouLookinAt': encryptedPrompt,
          'authId': userId,
          'authenticationService': 'firebase',
        }
    });
  
      if (!generateResponse.ok) {
        const responseText = await generateResponse.text();
        console.error('API Error Response:', {
          status: generateResponse.status,
          statusText: generateResponse.statusText,
          body: responseText
        });
        throw new Error(`HTTP error! status: ${generateResponse.status}`);
      }
  
      const generateData = await generateResponse.json();
  
      if (generateData?.sdGenerationJob?.generationId) {
          const generationId = generateData.sdGenerationJob.generationId;
          const imageUrl = await checkGenerationStatus(generationId);
          return imageUrl;
      } else {
          throw new Error('No generationId returned');
      }
    } catch (error) {
        console.error('Error in generateImage:', error);
        throw error;
    }
  };

  const sizeSliderRef = useRef(null);
  const tierSliderRef = useRef(null);
  const flavorSliderRef = useRef(null);
  const shapeSliderRef = useRef(null);
  const decorationSliderRef = useRef(null);
  const sizeIntervalRef = useRef(null);
  const tierIntervalRef = useRef(null);
  const shapeIntervalRef = useRef(null);
  const flavorIntervalRef = useRef(null);
  const decorationIntervalRef = useRef(null);


  const sliderSettings = {
    modules: [Navigation, Autoplay, Pagination],
    spaceBetween: 1,
    slidesPerView: 3,
    speed: 200,
    loop: true,
    allowTouchMove: false,
    pagination: {
      clickable: true,
      type: 'bullets',
      dynamicBullets: true,
      dynamicMainBullets: 3
    }
  };

  const renderMobileStep = () => {
    switch(currentStep) {
      case 1:
        return (
          <>
            <div className="step-header">
              <h2>Choose Your Size</h2>
            </div>
            <div className="mobile-options-grid">
              {sizeOptions.map((size) => (
                <div 
                  key={size.name}
                  onClick={() => size.name === 'Add' ? handleAddClick('size') : handleSizeChange(size.name)}
                  className={`mobile-option-item ${selectedSize === size.name ? 'selected' : ''}`}
                >
                  <img src={size.image} alt={size.name} />
                  <p>{size.name}</p>
                </div>
              ))}
            </div>
            {renderContinueButton(selectedSize, 2)}
          </>
        );
      case 2:
        return (
          <>
            <div className="step-header">
              <h2>Choose Your Cake Tier</h2>
            </div>
            <div className="mobile-options-grid">
              {tierOptions.map((tier) => (
                <div 
                  key={tier.name}
                  onClick={() => {
                    if (tier.name === 'Add') {
                      handleAddClick('tier');
                      return;
                    }
                    handleTierChange(tier.name);
                  }}
                  className={`mobile-option-item ${selectedTier === tier.name ? 'selected' : ''}`}
                >
                  <img src={tier.image} alt={tier.name} />
                  <p>{tier.name}</p>
                </div>
              ))}
            </div>
            {renderContinueButton(selectedTier, 3)}
          </>
        );
        case 3:
        return (
          <>
            <div className="step-header">
              <h2>Choose Your Cake Shape</h2>
            </div>
            <div className="mobile-options-grid">
              {shapeOptions.map((shape) => (
                <div 
                  key={shape.name}
                  onClick={() => {
                    if (shape.name === 'Add') {
                      handleAddClick('shape');
                      return;
                    }
                    handleShapeChange(shape.name);
                  }}
                  className={`mobile-option-item ${selectedShape === shape.name ? 'selected' : ''}`}
                >
                  <img src={shape.image} alt={shape.name} />
                  <p>{shape.name}</p>
                </div>
              ))}
            </div>
            {renderContinueButton(selectedShape, 4)}
          </>
        );
      case 4:
        return (
          <>
            <div className="step-header">
              <h2>Select Your Cake Flavor</h2>
            </div>
            <div className="mobile-options-grid">
              {flavorOptions.map((flavor) => (
                <div 
                  key={flavor.name}
                  onClick={() => {
                    if (flavor.name === 'Add') {
                      handleAddClick('flavor');
                      return;
                    }
                    handleFlavorChange(flavor.name);
                  }}
                  className={`mobile-option-item ${selectedFlavor === flavor.name ? 'selected' : ''}`}
                >
                  <img src={flavor.image} alt={flavor.name} />
                  <p>{flavor.name}</p>
                </div>
              ))}
            </div>
            {renderContinueButton(selectedFlavor, 5)}
          </>
        );
      case 5:
        return (
          <>
            <div className="step-header">
              <h2>Choose Your Cake Decoration</h2>
            </div>
            <div className="mobile-options-grid">
              {decorationOptions.map((decoration) => (
                <div 
                  key={decoration.name}
                  onClick={() => {
                    if (decoration.name === 'Add') {
                      handleAddClick('decoration');
                      return;
                    }
                    handleDecorationChange(decoration.name);
                  }}
                  className={`mobile-option-item ${selectedDecoration === decoration.name ? 'selected' : ''}`}
                >
                  <img src={decoration.image} alt={decoration.name} />
                  <p>{decoration.name}</p>
                  {selectedDecoration === decoration.name && decoration.subOptions && (
                    <select
                      value={selectedDecorationSubOption || ''}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleDecorationSubOptionChange(e.target.value);
                      }}
                      className="decoration-sub-option"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <option value="">Select {decoration.name} Type</option>
                      {decoration.subOptions.map((option) => (
                        <option key={option} value={option}>{option}</option>
                      ))}
                    </select>
                  )}
                </div>
              ))}
            </div>
            {selectedDecoration && (
              <button 
                className="continue-button"
                onClick={() => setCurrentStep(6)}
              >
                Continue
              </button>
            )}
          </>
        );
      case 6:
        return (
          <>
            <div className="step-header">
              <h2>Choose Your Color</h2>
            </div>
            <div className="color-presets-container">
              {colorPresets.map((color) => (
                <div 
                  key={color.name}
                  onClick={() => color.name === 'Custom' 
                    ? setShowColorPicker(true) 
                    : handleColorChange({ hex: color.hex })}
                  className={`color-preset-item ${selectedColor === color.hex ? 'selected' : ''}`}
                >
                  {color.name === 'Custom' ? (
                    <>
                      <img src={color.image} alt="Custom color" className="color-wheel-icon" />
                      <p>Custom Color</p>
                    </>
                  ) : (
                    <>
                      <div 
                        className="color-swatch" 
                        style={{ backgroundColor: color.hex }}
                      />
                      <p>{color.name}</p>
                    </>
                  )}
                </div>
              ))}
            </div>
            <div className="visualization-container">
              <button
                onClick={generateVisualizations}
                disabled={
                  !selectedSize ||
                  !selectedTier ||
                  !selectedShape ||
                  !selectedFlavor || 
                  !selectedDecoration || 
                  !selectedColor || 
                  selectedColor === '#ffffff' || 
                  loading
                }
                className={`visualization-button mobile-generate-button ${
                  (!selectedSize || !selectedTier || !selectedShape || !selectedFlavor || !selectedDecoration || !selectedColor || selectedColor === '#ffffff') ? 'disabled' : ''
                }`}
              >
                {loading ? 'Generating...' : isGenerationComplete ? 'Regenerate' : 'Generate Visualization'}
              </button>
            </div>
            {loading && (
              <div className="progress-container">
                <div className="progress-bar">
                  <div className="progress" style={{ width: `${progress}%` }}></div>
                </div>
              </div>
            )}
          </>
        );
      case 7:
        return (
          <>
            <WeddingCakesCanvas 
              progress={progress}
              loading={loading}
              setLoading={setLoading}
              generateVisualizations={generateVisualizations}
              isGenerationComplete={isGenerationComplete}
              cakeVisualizationUrls={cakeVisualizationUrls}
              cakePrompt={cakePrompt}
            />
          </>
        );
      default:
        return null;
    }
  };

  // ... (keep slider refs and settings)
  
  const renderContinueButton = (condition, nextStep) => {
    if (condition) {
      if (nextStep === 6) {
        return (
          <button
            className="continue-button"
            onClick={async () => {
              setCurrentStep(nextStep);
              await generateVisualizations();
            }}
            disabled={loading}
          >
            {loading ? 'Generating...' : 'Generate'}
          </button>
        );
      }
      return (
        <button
          className="continue-button"
          onClick={() => setCurrentStep(nextStep)}
        >
          Continue
        </button>
      );
    }
    return null;
  };

  const renderProgressBar = () => {
    const steps = [
      { num: 1, name: 'Size' },
      { num: 2, name: 'Tier' },
      { num: 3, name: 'Shape' },
      { num: 4, name: 'Flavor' },
      { num: 5, name: 'Decoration' },
      { num: 6, name: 'Color'},
      { num: 7, name: 'Preview'}
    ];

    // Determine which steps to show
    const visibleSteps = currentStep <= 4 
      ? steps.slice(0, 4)  // Show steps 1-4
      : steps.slice(3, 7); // Show steps 4-7

    return (
      <div className="mobile-progress-bar">
        {visibleSteps.map((step) => (
          <div 
            key={step.num}
            className={`step ${currentStep >= step.num ? 'completed' : ''} ${currentStep === step.num ? 'active' : ''}`}
            onClick={() => {
              // Only allow clicking on completed steps or the next available step
              if (step.num <= currentStep + 1) {
                setCurrentStep(step.num);
              }
            }}
          >
            <div className="step-number">{step.num}</div>
            <div className="step-name">{step.name}</div>
          </div>
        ))}
      </div>
    );
  };

  // Autoplay functions for sliders
  const startAutoplay = (sliderRef, intervalRef, direction) => {
    if (sliderRef.current && sliderRef.current.swiper) {
      const swiper = sliderRef.current.swiper;
      
      if (direction === 'left') {
        swiper.slidePrev();
      } else {
        swiper.slideNext();
      }

      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      intervalRef.current = setInterval(() => {
        if (direction === 'left') {
          swiper.slidePrev();
        } else {
          swiper.slideNext();
        }
      }, 300);
    }
  };

  const stopAutoplay = (intervalRef) => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const handleAddClick = (type) => {
    setShowAddInput(prev => ({ ...prev, [type]: true }));
  };

  const handleInputChange = (e, type) => {
    setNewInput(prev => ({ ...prev, [type]: e.target.value }));
  };

  const handleNewInputSubmit = async (type) => {
    if (!newInput[type].trim()) {
      return;
    }

    // Get current user
    const auth = getAuth();
    const user = auth.currentUser;
    const userEmail = user ? user.email : 'Anonymous';

    // Prepare message for Discord
    const message = {
      username: "Wedify Bot",
      content: `New ${type} suggestion from ${userEmail}`,
      embeds: [{
        title: `New ${type} Suggestion`,
        description: `Suggested ${type}: ${newInput[type]}`,
        fields: [
          {
            name: 'Category',
            value: `Wedding Cake ${type.charAt(0).toUpperCase() + type.slice(1)}`
          },
          {
            name: 'Submitted by',
            value: userEmail
          }
        ],
        color: 3066993 // Green color in decimal
      }]
    };

    try {
      await sendToDiscord(message);
      setNewInput(prev => ({ ...prev, [type]: '' }));
      setShowAddInput(prev => ({ ...prev, [type]: false }));
      alert('Thank you for your suggestion! We will review it shortly.');
    } catch (error) {
      console.error('Error sending suggestion:', error);
      alert('Sorry, there was an error submitting your suggestion. Please try again later.');
    }
  };

  useEffect(() => {
  }, [selectedColor]);

  // Add this ref at the top of your component
  const stepsContainerRef = useRef(null);

  // Add this effect to handle scrolling when step changes
  useEffect(() => {
    if (currentStep > 4 && stepsContainerRef.current) {
      const stepWidth = 80; // Approximate width of each step including margins
      const scrollPosition = (currentStep - 4) * stepWidth;
      stepsContainerRef.current.scrollTo({
        left: scrollPosition,
        behavior: 'smooth'
      });
    }
  }, [currentStep]);

  return (
    <div className="wedding-cakes-design-page">
      {/* Mobile Content*/}
      <div className="wedding-cakes-mobile-content">
        <div className="mobile-design">
          <div className="mobile-content">
            {renderProgressBar()}
            <div className="mobile-step">
              {renderMobileStep()}
            </div>
          </div>
        </div>
      </div>

      {/* Desktop Layout*/}
      <div className="wedding-cakes-desktop-content">
        <div className="design-container">
          <h2 className="page-title">Design Your Wedding Cake</h2>

            {/* Size Section */}
            <div className="design-section size">
              <h3>Select Cake Size</h3>
              <div className="size-options-container">
                {sizeOptions.map((size) => (
                  <div 
                    key={size.name}
                    onClick={() => handleSizeChange(size.name)}
                    className={`size-option ${selectedSize === size.name ? 'selected' : ''}`}
                  >
                    <img src={size.image} alt={size.name} className="size-option-image" />
                    <p>{size.name}</p>
                  </div>
                ))}
              </div>
            </div>

            {/* Tier Section */}
            <div className="design-section tiers">
              <h3>Select Cake Tier</h3>
              <div className="slider-container">
                <div 
                  className="hover-zone hover-zone-left"
                  onMouseEnter={() => startAutoplay(tierSliderRef, tierIntervalRef, 'left')}
                  onMouseLeave={() => stopAutoplay(tierIntervalRef)}
                />
                <Swiper
                  ref={tierSliderRef}
                  {...sliderSettings}
                  className="tier-swiper"
                >
                  {tierOptions.map((tier) => (
                    <SwiperSlide key={tier.name}>
                      <div 
                        onClick={() => {
                          if (tier.name === 'Add') {
                            handleAddClick('tier');
                            return;
                          }
                          handleTierChange(tier.name);
                        }}
                        className={`carousel-item ${selectedTier === tier.name ? 'selected' : ''}`}
                      >
                        <img src={tier.image} alt={tier.name} className="carousel-image" />
                        <p>{tier.name}</p>
                      </div>
                    </SwiperSlide>
                  ))}
                  <div className="swiper-pagination tier-pagination"></div>
                </Swiper>
                <div 
                  className="hover-zone hover-zone-right"
                  onMouseEnter={() => startAutoplay(tierSliderRef, tierIntervalRef, 'right')}
                  onMouseLeave={() => stopAutoplay(tierIntervalRef)}
                />
              </div>
            </div>

            {/* Shape Section */}
            <div className="design-section shapes">
              <h3>Select Cake Shape</h3>
              <div className="slider-container">
                <div 
                  className="hover-zone hover-zone-left"
                  onMouseEnter={() => startAutoplay(shapeSliderRef, shapeIntervalRef, 'left')}
                  onMouseLeave={() => stopAutoplay(shapeIntervalRef)}
                />
                <Swiper
                  ref={shapeSliderRef}
                  {...sliderSettings}
                  className="shape-swiper"
                >
                  {shapeOptions.map((shape) => (
                    <SwiperSlide key={shape.name}>
                      <div 
                        onClick={() => {
                          if (shape.name === 'Add') {
                            handleAddClick('shape');
                            return;
                          }
                          handleShapeChange(shape.name);
                        }}
                        className={`carousel-item ${selectedShape === shape.name ? 'selected' : ''}`}
                      >
                        <img src={shape.image} alt={shape.name} className="carousel-image" />
                        <p>{shape.name}</p>
                      </div>
                    </SwiperSlide>
                  ))}
                  <div className="swiper-pagination shape-pagination"></div>
                </Swiper>
                <div 
                  className="hover-zone hover-zone-right"
                  onMouseEnter={() => startAutoplay(shapeSliderRef, shapeIntervalRef, 'right')}
                  onMouseLeave={() => stopAutoplay(shapeIntervalRef)}
                />
              </div>
            </div>

            {/* Flavor Section */}
            <div className="design-section flavors">
              <h3>Select Cake Flavor</h3>
              <div className="slider-container">
              <div 
                  className="hover-zone hover-zone-left"
                  onMouseEnter={() => startAutoplay(flavorSliderRef, flavorIntervalRef, 'left')}
                  onMouseLeave={() => stopAutoplay(flavorIntervalRef)}
                />
                <Swiper
                  ref={flavorSliderRef}
                  {...sliderSettings}
                  className="flavor-swiper"
                >
                  {flavorOptions.map((flavor) => (
                    <SwiperSlide key={flavor.name}>
                      <div 
                        onClick={() => {
                          if (flavor.name === 'Add') {
                            handleAddClick('flavor');
                            return;
                          }
                          handleFlavorChange(flavor.name);
                        }}
                        className={`carousel-item ${selectedFlavor === flavor.name ? 'selected' : ''}`}
                      >
                        <img src={flavor.image} alt={flavor.name} className="carousel-image" />
                        <p>{flavor.name}</p>
                      </div>
                    </SwiperSlide>
                  ))}
                  <div className="swiper-pagination flavor-pagination"></div>
                </Swiper>
                <div 
                  className="hover-zone hover-zone-right"
                  onMouseEnter={() => startAutoplay(flavorSliderRef, flavorIntervalRef, 'right')}
                  onMouseLeave={() => stopAutoplay(flavorIntervalRef)}
                />
              </div>
            </div>

            {/* Decoration Section */}
            <div className="design-section decorations">
              <h3>Select Cake Decoration</h3>
              <div className="slider-container">
              <div 
                  className="hover-zone hover-zone-left"
                  onMouseEnter={() => startAutoplay(decorationSliderRef, decorationIntervalRef, 'left')}
                  onMouseLeave={() => stopAutoplay(decorationIntervalRef)}
                />
                <Swiper
                  ref={decorationSliderRef}
                  {...sliderSettings}
                  className="decoration-swiper"
                >
                  {decorationOptions.map((decoration) => (
                    <SwiperSlide key={decoration.name}>
                      <div 
                        onClick={() => {
                          if (decoration.name === 'Add') {
                            handleAddClick('decoration');
                            return;
                          }
                          handleDecorationChange(decoration.name);
                        }}
                        className={`carousel-item ${selectedDecoration === decoration.name ? 'selected' : ''}`}
                      >
                        <img src={decoration.image} alt={decoration.name} className="carousel-image" />
                        <p>{decoration.name}</p>
                        {selectedDecoration === decoration.name && decoration.subOptions && (
                          <select
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => handleDecorationSubOptionChange(e.target.value)}
                            value={selectedDecorationSubOption}
                            className="decoration-sub-option"
                          >
                            <option value="">Select Style</option>
                            {decoration.subOptions.map((subOption) => (
                              <option key={subOption} value={subOption}>{subOption}</option>
                            ))}
                          </select>
                        )}
                      </div>
                    </SwiperSlide>
                  ))}
                  <div className="swiper-pagination decoration-pagination"></div>
                </Swiper>
                <div 
                  className="hover-zone hover-zone-right"
                  onMouseEnter={() => startAutoplay(decorationSliderRef, decorationIntervalRef, 'right')}
                  onMouseLeave={() => stopAutoplay(decorationIntervalRef)}
                />
              </div>
            </div>

            {/* Color Section */}
            <div className="design-section colors">
              <h3>Select Color</h3>
              {!showColorPicker ? (
                <div className="color-presets-container">
                  {colorPresets.map((color) => (
                    <div 
                      key={color.name}
                      onClick={() => color.name === 'Custom' 
                        ? setShowColorPicker(true) 
                        : handleColorChange({ hex: color.hex })}
                      className={`color-preset-item ${selectedColor === color.hex ? 'selected' : ''}`}
                    >
                      {color.name === 'Custom' ? (
                        <>
                          <img src={color.image} alt="Custom color" className="color-wheel-icon" />
                          <p>Custom Color</p>
                        </>
                      ) : (
                        <>
                          <div 
                            className="color-swatch" 
                            style={{ backgroundColor: color.hex }}
                          />
                          <p>{color.name}</p>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="color-picker-container">
                  <button 
                    onClick={() => setShowColorPicker(false)}
                    className="back-to-presets-btn"
                  >
                    ← Back to Presets
                  </button>
                  <ColorPicker 
                    selectedColor={selectedColor} 
                    onColorChange={handleColorChange} 
                  />
                </div>
              )}
            </div>

            {/* Generate Button Section */}
            <div className="design-section generate">
              <div className="visualization-container">
                <button
                  onClick={generateVisualizations}
                  disabled={
                    !selectedSize ||
                    !selectedTier ||
                    !selectedShape ||
                    !selectedFlavor || 
                    !selectedDecoration || 
                    !selectedColor || 
                    selectedColor === '#ffffff' || 
                    loading
                  }
                  className={`visualization-button ${
                    (!selectedSize || !selectedTier || !selectedShape || !selectedFlavor || !selectedDecoration || !selectedColor || selectedColor === '#ffffff') ? 'disabled' : ''
                  }`}
                >
                  {loading ? 'Generating...' : isGenerationComplete ? 'Regenerate' : 'Generate Visualization'}
                </button>
                
                {loading && (
                  <div className="progress-container">
                    <div className="progress-bar">
                      <div className="progress" style={{ width: `${progress}%` }}></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Canvas Section - Updated with proper container */}
          <div className="canvas-container">
            <WeddingCakesCanvas 
              progress={progress}
              loading={loading}
              setLoading={setLoading}
              generateVisualizations={generateVisualizations}
              isGenerationComplete={isGenerationComplete}
              cakeVisualizationUrls={cakeVisualizationUrls}
              cakePrompt={cakePrompt}
            />
          </div>
      </div>
      
  
      {showAddInput.size && (
        <div className="add-theme-overlay">
          <div className="add-theme-input">
            <h4>What other size should we add?</h4>
            <input 
              type="text" 
              value={newInput.size}
              onChange={(e) => handleInputChange(e, 'size')}
              placeholder="Suggest new size..."
              autoFocus
            />
            <div className="add-theme-buttons">
              <button onClick={() => setShowAddInput(prev => ({ ...prev, size: false }))}>Cancel</button>
              <button onClick={() => handleNewInputSubmit('size')}>Submit</button>
            </div>
          </div>
        </div>
      )}

      {showAddInput.tier && (
        <div className="add-theme-overlay">
          <div className="add-theme-input">
            <h4>What other tier should we add?</h4>
            <input 
              type="text" 
              value={newInput.tier}
              onChange={(e) => handleInputChange(e, 'tier')}
              placeholder="Suggest new size..."
              autoFocus
            />
            <div className="add-theme-buttons">
              <button onClick={() => setShowAddInput(prev => ({ ...prev, tier: false }))}>Cancel</button>
              <button onClick={() => handleNewInputSubmit('tier')}>Submit</button>
            </div>
          </div>
        </div>
      )}

      {showAddInput.shape && (
        <div className="add-theme-overlay">
          <div className="add-theme-input">
            <h4>What other shape should we add?</h4>
            <input 
              type="text" 
              value={newInput.shape}
              onChange={(e) => handleInputChange(e, 'shape')}
              placeholder="Suggest new size..."
              autoFocus
            />
            <div className="add-theme-buttons">
              <button onClick={() => setShowAddInput(prev => ({ ...prev, shape: false }))}>Cancel</button>
              <button onClick={() => handleNewInputSubmit('shape')}>Submit</button>
            </div>
          </div>
        </div>
      )}


      {showAddInput.flavor && (
        <div className="add-theme-overlay">
          <div className="add-theme-input">
            <h4>What other flavor should we add?</h4>
            <input 
              type="text" 
              value={newInput.flavor}
              onChange={(e) => handleInputChange(e, 'flavor')}
              placeholder="Suggest new flavor..."
              autoFocus
            />
            <div className="add-theme-buttons">
              <button onClick={() => setShowAddInput(prev => ({ ...prev, flavor: false }))}>Cancel</button>
              <button onClick={() => handleNewInputSubmit('flavor')}>Submit</button>
            </div>
          </div>
        </div>
      )}

      {showAddInput.decoration && (
        <div className="add-theme-overlay">
          <div className="add-theme-input">
            <h4>What other decorations should we add?</h4>
            <input 
              type="text" 
              value={newInput.decoration}
              onChange={(e) => handleInputChange(e, 'decoration')}
              placeholder="Suggest new decoration..."
              autoFocus
            />
            <div className="add-theme-buttons">
              <button onClick={() => setShowAddInput(prev => ({ ...prev, decoration: false }))}>Cancel</button>
              <button onClick={() => handleNewInputSubmit('decoration')}>Submit</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WeddingCakes;