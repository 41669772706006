import React, { useState, useEffect, useCallback } from 'react';
import Slider from 'react-slick';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useDesign } from '../../../hooks/DesignContext';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../design/decor/Canvas.css';
import { getClosestColor } from '../../../components/colorMapper';
import Spinner from '../../../components/Spinner.js';
import { generateAuthToken } from '../../../utils/encryptExternalID.js';
import { Circle } from 'lucide-react';
import PaymentPortal from '../../../components/Payment/PaymentPortal.js';
import './Florals.css';
const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};

const getColorInfo = (hexColor) => {
  const rgb = hexToRgb(hexColor);
  const namedColor = getClosestColor(hexColor);
  return {
    namedColor,
    rgb
  };
};

const FloralsCanvas = ({ 
  progress, 
  loading, 
  setLoading,
  generateVisualizations,
  isGenerationComplete,
  bouquetVisualizationUrls,
  boutonniereVisualizationUrls,
  bouquetPrompt,
  boutonnierePrompt
}) => {
  const {
    selectedGreenery,
    selectedFlower,
    selectedColor,
  } = useDesign();

  const [bookmarkedImages, setBookmarkedImages] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [user, setUser] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [credits, setCredits] = useState(null);
  const [showCreditsModal, setShowCreditsModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const generateToken = useCallback(async () => {
    try {
      const token = await generateAuthToken();
      return token;
    } catch (error) {
      return null;
    }
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        fetchCredits(currentUser);
      } else {
        setCredits(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleImageError = (url) => {
    console.error(`Failed to load image: ${url}`);
  };

  const handleBookmark = async (imageUrl, label, prompt) => {
    setIsUploading(true);
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      
      const formData = new FormData();
      formData.append('file', blob, 'image.jpg');

      const colorInfo = getColorInfo(selectedColor);
      const capitalizedColor = colorInfo.namedColor.charAt(0).toUpperCase() + colorInfo.namedColor.slice(1);

      const metadataValuesList = [
        { key: "greenery", value: selectedGreenery },
        { key: "flowers", value: selectedFlower },
        { key: "color", value: capitalizedColor }
      ];

      const selectedOptions = JSON.stringify({ metadataValuesList });

      const token = await generateToken();
      if (!token) {
        throw new Error('Failed to generate authentication token');
      }

      const headers = {
        'contentType': 'jpeg',
        'authService': 'firebase',
        'externalClientId': user.uid,
        'prompt': prompt,
        'explorePage': 'true',
        'cwRed': colorInfo.rgb.r.toString(),
        'cwGreen': colorInfo.rgb.g.toString(),
        'cwBlue': colorInfo.rgb.b.toString(),
        'bookmarked': 'false',
        'selectedOptions': selectedOptions,
        'designCategory': 'wedding florals',
        'X-Auth-Token': token
      };

      const uploadResponse = await fetch('https://wedify.live/api/images/upload/auth/id', {
        method: 'POST',
        headers: headers,
        body: formData
      });

      if (!uploadResponse.ok) {
        const errorText = await uploadResponse.text();
        throw new Error(`HTTP error! status: ${uploadResponse.status}, message: ${errorText}`);
      }

      setBookmarkedImages(prev => ({
        ...prev,
        [imageUrl]: { bookmarked: true }
      }));

      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 3000);
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
  };

  const combineBouquetAndBoutonniereUrls = (bouquetUrls, boutonniereUrls) => {
 
    const combined = [];
    const maxLength = Math.max(bouquetUrls.length, boutonniereUrls.length);
    
    for (let i = 0; i < maxLength; i++) {
      if (i < bouquetUrls.length) {
        combined.push({ 
          url: bouquetUrls[i], 
          label: 'Bridal Bouquet', 
          prompt: decodeURIComponent(bouquetPrompt) 
        });
      }
      if (i < boutonniereUrls.length) {
        combined.push({ 
          url: boutonniereUrls[i], 
          label: 'Boutonniere', 
          prompt: decodeURIComponent(boutonnierePrompt) 
        });
      }
    }
    
   
    return combined;
  };

  const combinedVisualizations = combineBouquetAndBoutonniereUrls(bouquetVisualizationUrls, boutonniereVisualizationUrls);

  const renderCombinedCarousel = () => (
    <>
      <div className="desktop-only">
        <div className="carousel-container">
          <Slider {...settings}>
            {combinedVisualizations.map((item, index) => (
              <div key={index} className="slide-wrapper">
                <div className="image-wrapper">
                  <img
                    src={item.url}
                    alt={`Generated ${item.label.toLowerCase()} visualization ${index + 1}`}
                    className="generated-image"
                    onError={() => handleImageError(item.url)}
                  />
                  <div className="image-label">{item.label}</div>
                  <button 
                    className={`save-button ${bookmarkedImages[item.url]?.bookmarked ? 'saved' : ''}`}
                    onClick={() => handleBookmark(item.url, item.label, item.prompt)}
                    disabled={bookmarkedImages[item.url]?.bookmarked}
                  >
                    {bookmarkedImages[item.url]?.bookmarked ? '✓ Saved' : 'Save'}
                  </button>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="mobile-only">
        {renderMobileStacked()}
      </div>
    </>
  );

  const renderMobileStacked = () => (
    <div className="mobile-stacked-images">
      {combinedVisualizations.map((item, index) => (
        <div key={index} className="mobile-image-item">
          <div className="image-label">{item.label}</div>
          <img 
            src={item.url} 
            alt={`Generated ${item.label.toLowerCase()} visualization ${index + 1}`}
            className="generated-image"
            onError={() => handleImageError(item.url)}
          />
          <button 
            className={`save-button ${bookmarkedImages[item.url]?.bookmarked ? 'saved' : ''}`}
            onClick={() => handleBookmark(item.url, item.label, item.prompt)}
            disabled={bookmarkedImages[item.url]?.bookmarked}
          >
            {bookmarkedImages[item.url]?.bookmarked ? 'Saved' : 'Save'}
          </button>
        </div>
      ))}
    </div>
  );

  const renderProgressCircle = () => {
    
    const steps = [
      { name: 'Greenery', isComplete: !!selectedGreenery },
      { name: 'Flowers', isComplete: !!selectedFlower },
      { name: 'Color', isComplete: !!(selectedColor && selectedColor !== '#ffffff') }
    ];
    
    return (
      <div className="progress-circle-container">
        <div className="steps-progress">
          {steps.map((step, index) => (
            <div 
              key={step.name} 
              className={`step-indicator ${step.isComplete ? 'completed' : ''}`}
            >
              <Circle 
                size={24} 
                className={`circle ${step.isComplete ? 'filled' : ''}`} 
              />
              <span className="step-label">{step.name}</span>
            </div>
          ))}
        </div>
        <div className="progress-text">
          {steps.filter(step => step.isComplete).length} of {steps.length} steps completed
        </div>
      </div>
    );
  };

  const handleVisualizationClick = async () => {
    if (!selectedGreenery || !selectedFlower || !selectedColor || selectedColor === '#ffffff') {
      alert('Please complete all floral design options before generating');
      return;
    }

    if (credits <= 0) {
      setShowCreditsModal(true);
      return;
    }

    try {
      await generateVisualizations();
      // Fetch credits after generation is complete
      const auth = getAuth();
      const currentUser = auth.currentUser;
      if (currentUser) {
        await fetchCredits(currentUser);
      }
    } catch (error) {
      console.error('Error during visualization process:', error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchCredits = async (currentUser) => {
    if (!currentUser) return;
    
    try {
      const token = await generateAuthToken();
      
      const response = await fetch(
        `https://wedify.live/api/login/generation/credits/left?authService=firebase&externalClientId=${currentUser.uid}`,
        {
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'X-Auth-Token': token
          }
        }
      );

      if (response.ok) {
        const creditsData = await response.text();
        setCredits(Number(creditsData));
      }
    } catch (error) {
      console.error('Error fetching credits:', error);
    }
  };

  return (
    <div className="florals-canvas">
      {!isMobile && credits !== null && (
        <button 
          className="credits-display desktop-only"
          onClick={() => setShowCreditsModal(true)}
        >
          <span>{credits} credits</span>
        </button>
      )}

      <h2 className="canvas-title">Your AI Generated Florals</h2>
      {!loading && !bouquetVisualizationUrls.length && !boutonniereVisualizationUrls.length && 
        renderProgressCircle()
      }
      <div className="canvas-grid">
        <div className="canvas-left"></div>
        <div className="canvas-center">
          {loading ? (
            <div className="loading-container">
              <Spinner text="Dream Florals Generating" />
              <div className="progress-indicator">
                <div className="progress-bar">
                  <div className="progress" style={{ width: `${progress}%` }}></div>
                </div>
                <p>Progress: {progress}%</p>
                <p>{progress < 50 ? 'Generating Bouquet...' : 'Generating Boutonniere...'}</p>
              </div>
            </div>
          ) : bouquetVisualizationUrls.length > 0 || boutonniereVisualizationUrls.length > 0 ? (
            renderCombinedCarousel()
          ) : (
            <div className="start-message">
              <p>Click 'Generate Visualizations' to start</p>
            </div>
          )}
        </div>
        <div className="canvas-right"></div>
      </div>
      <button 
        onClick={handleVisualizationClick}
        className={`visualization-button ${(!selectedGreenery || !selectedFlower || !selectedColor || selectedColor === '#ffffff') ? 'disabled' : ''}`}
        disabled={!selectedGreenery || !selectedFlower || !selectedColor || selectedColor === '#ffffff'}
      >
        {loading ? 'Generating...' : 
         credits <= 0 ? 'Out of Credits' : 
         (!selectedGreenery || !selectedFlower || !selectedColor || selectedColor === '#ffffff') ? 'Complete All Options' :
         (bouquetVisualizationUrls.length > 0 || boutonniereVisualizationUrls.length > 0) ? 'Regenerate' : 'Generate Visualizations'}
      </button>
      {showMessage && (
        <div className="bookmark-message">
          {isUploading ? 'Uploading...' : 'Added To User Profile'}
        </div>
      )}
      {isUploading && (
        <div className="upload-overlay">
          <div className="spinner"></div>
          <p>Uploading image...</p>
        </div>
      )}
      <PaymentPortal 
        isOpen={showCreditsModal}
        onClose={() => setShowCreditsModal(false)}
        user={user}
        fetchCredits={fetchCredits}
        credits={credits}
      />
    </div>
  );
};

export default FloralsCanvas;