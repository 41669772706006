import React, { createContext, useState, useContext } from 'react';

const DesignContext = createContext();

export const DesignProvider = ({ children }) => {
  const [selectedTheme, setSelectedTheme] = useState('');
  const [selectedColor, setSelectedColor] = useState('#ffffff');
  const [selectedFlower, setSelectedFlower] = useState('');
  const [selectedSeating, setSelectedSeating] = useState('');
  const [selectedSeatingSubOption, setSelectedSeatingSubOption] = useState('');
  const [stageVisualizationUrls, setStageVisualizationUrls] = useState([]);
  const [tableVisualizationUrls, setTableVisualizationUrls] = useState([]);
  const [stagePrompt, setStagePrompt] = useState('');
  const [tablePrompt, setTablePrompt] = useState('');
  const [selectedVenue, setSelectedVenue] = useState('');
  const [selectedGreenery, setSelectedGreenery] = useState('');
  const [bouquetVisualizationUrls, setBouquetVisualizationUrls] = useState([]);
  const [boutonniereVisualizationUrls, setBoutonniereVisualizationUrls] = useState([]);
  const [bouquetPrompt, setBouquetPrompt] = useState('');
  const [boutonnierePrompt, setBoutonnierePrompt] = useState('');
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedTier, setSelectedTier] = useState('');
  const [selectedShape, setSelectedShape] = useState('');
  const [selectedFlavor, setSelectedFlavor] = useState('');
  const [selectedDecoration, setSelectedDecoration] = useState('');
  const [selectedDecorationSubOption, setSelectedDecorationSubOption] = useState('');
  const [cakePrompt, setCakePrompt] = useState('');
  const [cakeVisualizationUrls, setCakeVisualizationUrls] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedScene, setSelectedScene] = useState('');
  const [selectedDecor, setSelectedDecor] = useState('');
  const [selectedStyle, setSelectedStyle] = useState('');
  const [proposalPrompt, setProposalPrompt] = useState('');
  const [proposalVisualizationUrls, setProposalVisualizationUrls] = useState([]);

  const [isGenerationComplete, setIsGenerationComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  // Add new WeddingAttire states
  const [selectedFit, setSelectedFit] = useState('');
  const [selectedLapel, setSelectedLapel] = useState('');
  const [selectedMaterial, setSelectedMaterial] = useState('');
  const [selectedPattern, setSelectedPattern] = useState('');
  const [selectedJacket, setSelectedJacket] = useState('');
  const [attirePrompt, setAttirePrompt] = useState('');
  const [attireVisualizationUrls, setAttireVisualizationUrls] = useState([]);

  // Add Wedding Dress states
  const [selectedDressStyle, setSelectedDressStyle] = useState('');
  const [selectedNeckline, setSelectedNeckline] = useState('');
  const [selectedFabric, setSelectedFabric] = useState('');
  const [selectedLength, setSelectedLength] = useState('');
  const [selectedDetails, setSelectedDetails] = useState('');
  const [attireType, setAttireType] = useState('');

  // New: Function to generate designs
  const generateDesigns = async () => {
    try {
      // Mock API call or logic to generate designs based on selected options
      const generatedStageImageUrl = 'https://example.com/stage-image.jpg';
      const generatedTableImageUrl = 'https://example.com/table-image.jpg';

      setStageVisualizationUrls([generatedStageImageUrl]);
      setTableVisualizationUrls([generatedTableImageUrl]);

    } catch (error) {
      console.error('Error generating designs:', error);
    }
  };

  const clearState = () => {
    setSelectedTheme('');
    setSelectedColor('#ffffff');
    setSelectedFlower('');
    setSelectedSeating('');
    setSelectedSeatingSubOption('');
    setStageVisualizationUrls([]);
    setTableVisualizationUrls([]);
    setStagePrompt('');
    setTablePrompt('');
    setSelectedVenue('');
    setSelectedGreenery('');
    setSelectedSize('');
    setSelectedTier('');
    setSelectedShape('');
    setSelectedFlavor('');
    setSelectedDecoration('');
    setSelectedDecorationSubOption('');
    setCakePrompt('');
    setCakeVisualizationUrls([]);
    
    // Clear WeddingAttire states
    setSelectedFit('');
    setSelectedLapel('');
    setSelectedMaterial('');
    setSelectedPattern('');
    setSelectedJacket('');
    setAttirePrompt('');
    setAttireVisualizationUrls([]);
    
    // Clear Wedding Dress states
    setSelectedDressStyle('');
    setSelectedNeckline('');
    setSelectedFabric('');
    setSelectedLength('');
    setSelectedDetails('');
    setAttireType('');
  };

  const value = {
    selectedTheme,
    setSelectedTheme,
    selectedColor,
    setSelectedColor,
    selectedFlower,
    setSelectedFlower,
    selectedSeating,
    setSelectedSeating,
    selectedSeatingSubOption,
    setSelectedSeatingSubOption,
    stageVisualizationUrls,
    setStageVisualizationUrls,
    tableVisualizationUrls,
    setTableVisualizationUrls,
    stagePrompt,
    setStagePrompt,
    tablePrompt,
    setTablePrompt,
    generateDesigns, // Exporting generateDesigns
    selectedVenue,
    setSelectedVenue,
    clearState,
    selectedGreenery,
    setSelectedGreenery,
    bouquetVisualizationUrls,
    setBouquetVisualizationUrls,
    boutonniereVisualizationUrls,
    setBoutonniereVisualizationUrls,
    bouquetPrompt,
    setBouquetPrompt,
    boutonnierePrompt,
    setBoutonnierePrompt,
    selectedSize,
    setSelectedSize,
    selectedTier,
    setSelectedTier,
    selectedShape,
    setSelectedShape,
    selectedFlavor,
    setSelectedFlavor,
    selectedDecoration,
    setSelectedDecoration,
    selectedDecorationSubOption,
    setSelectedDecorationSubOption,
    cakePrompt,
    setCakePrompt,
    cakeVisualizationUrls,
    setCakeVisualizationUrls,
    isGenerationComplete,
    setIsGenerationComplete,
    loading,
    setLoading,
    progress,
    setProgress,
    selectedLocation,
    setSelectedLocation,
    selectedTime,
    setSelectedTime,
    selectedScene,
    setSelectedScene,
    selectedDecor,
    setSelectedDecor,
    selectedStyle,
    setSelectedStyle,
    proposalPrompt,
    setProposalPrompt,
    proposalVisualizationUrls,
    setProposalVisualizationUrls,
    selectedFit,
    setSelectedFit,
    selectedLapel,
    setSelectedLapel,
    selectedMaterial,
    setSelectedMaterial,
    selectedPattern,
    setSelectedPattern,
    selectedJacket,
    setSelectedJacket,
    attirePrompt,
    setAttirePrompt,
    attireVisualizationUrls,
    setAttireVisualizationUrls,
    selectedDressStyle, setSelectedDressStyle,
    selectedNeckline, setSelectedNeckline,
    selectedFabric, setSelectedFabric,
    selectedLength, setSelectedLength,
    selectedDetails, setSelectedDetails,
    attireType, setAttireType,
  };

  return (
    <DesignContext.Provider value={value}>
      {children}
    </DesignContext.Provider>
  );
};

export const useDesign = () => useContext(DesignContext);
