import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement } from '@stripe/react-stripe-js';
import { generateAuthToken } from '../../utils/encryptExternalID';
import PaymentForm from './PaymentForm';
import { getAuth } from 'firebase/auth';
import './PaymentPortal.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PRICING_PLANS = [
  {
    name: 'Starter Plan',
    credits: 600,
    price: 0,
    description: 'Included with registration',
    isPopular: false
  },
  {
    name: 'Basic Plan',
    credits: 600,
    price: 1.99,
    description: 'Perfect for getting started',
    isPopular: false
  },
  {
    name: 'Standard Plan',
    credits: 1200,
    price: 3.99,
    description: 'Most popular choice',
    isPopular: true
  },
  {
    name: 'Premium Plan',
    credits: 7500,
    price: 7.99,
    description: 'Best value for power users',
    isPopular: false
  }
];

const PaymentPortal = ({ isOpen, onClose, user, fetchCredits, credits }) => {
  const [currentView, setCurrentView] = useState('initial'); // 'initial', 'pricing', 'payment'
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [error, setError] = useState(null);

  const handleViewPricing = () => {
    setCurrentView('pricing');
  };

  const handleGetCredits = async (plan) => {
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      
      if (!currentUser) {
        throw new Error('Please sign in to purchase credits');
      }

      // Generate auth token
      const token = await generateAuthToken();
      
      // Create payment intent
      const response = await fetch('https://wedify.live/api/create-payment-intent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': token
        },
        body: JSON.stringify({
          amount: plan.price,
          currency: 'usd',
          externalClientId: currentUser.uid,
          authService: 'firebase'
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to create payment intent');
      }

      const { clientSecret } = await response.json();

      setSelectedPlan(plan);
      setClientSecret(clientSecret);
      setCurrentView('payment');
    } catch (error) {
      console.error('Error creating payment intent:', error);
      setError(error.message);
    }
  };

  // If not open, don't render anything
  if (!isOpen) return null;

  return (
    <div className="credits-modal-overlay">
      <div className="credits-modal">
        <div className="modal-header">
          {currentView !== 'initial' && (
            <button 
              className="payment-back-button"
              onClick={() => setCurrentView('initial')}
            >
              ← Back
            </button>
          )}
          <button className="close-modal-button" onClick={onClose}>×</button>
        </div>

        {error && (
          <div className="error-message">
            {error}
            {!user && (
              <button 
                className="sign-in-button"
                onClick={() => window.location.href = '/signin'}
              >
                Sign In
              </button>
            )}
          </div>
        )}

        {/* Initial View */}
        {currentView === 'initial' && (
          <div className="credits-initial-view">
            <h3>Current Credits</h3>
            <div className="credits-count">{credits !== null ? credits : 'Loading...'}</div>
            <div className="credits-buttons">
              <button className="pricing-button" onClick={handleViewPricing}>
                View Pricing
              </button>
              <button 
                className="get-credits-button" 
                onClick={handleViewPricing}
              >
                Get More Credits
              </button>
            </div>
          </div>
        )}

        {/* Pricing View */}
        {currentView === 'pricing' && (
          <div className="pricing-plans-container">
            <h3>Choose Your Plan</h3>
            <div className="pricing-grid">
              {PRICING_PLANS.map((plan) => (
                <div 
                  key={plan.name} 
                  className={`pricing-card ${plan.isPopular ? 'popular' : ''}`}
                >
                  {plan.isPopular && <div className="popular-badge">Most Popular</div>}
                  <h4>{plan.name}</h4>
                  <div className="credits-amount">{plan.credits} Credits</div>
                  <div className="plan-price">
                    ${plan.price.toFixed(2)}
                    {plan.price === 0 && <span className="free-text">FREE</span>}
                  </div>
                  <p className="plan-description">{plan.description}</p>
                  <button 
                    className="select-plan-button"
                    onClick={() => handleGetCredits(plan)}
                    disabled={plan.price === 0}
                  >
                    {plan.price === 0 ? 'Included' : 'Select Plan'}
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Payment View */}
        {currentView === 'payment' && selectedPlan && clientSecret && (
          <div className="payment-section">
            <div className="selected-plan-summary">
              <h3>{selectedPlan.name}</h3>
              <div className="credits-amount">{selectedPlan.credits} Credits</div>
              <div className="credits-price">${selectedPlan.price.toFixed(2)}</div>
            </div>
            <Elements stripe={stripePromise} options={{
              clientSecret,
              appearance: {
                theme: 'stripe',
                variables: {
                  colorPrimary: '#dcc282',
                },
              },
            }}>
              <PaymentForm 
                clientSecret={clientSecret}
                plan={selectedPlan}
                onSuccess={() => {
                  setCurrentView('initial');
                  onClose();
                  fetchCredits(user);
                }}
              />
            </Elements>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentPortal; 