import React, { useState, useEffect, useCallback } from 'react';
import Slider from 'react-slick';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useDesign } from '../../../hooks/DesignContext';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../decor/Canvas.css';
import { getClosestColor } from '../../../components/colorMapper';
import Spinner from '../../../components/Spinner.js';
import { generateAuthToken } from '../../../utils/encryptExternalID.js';
import { Circle } from 'lucide-react';
import PaymentPortal from '../../../components/Payment/PaymentPortal.js';
import '../../design/floral/Florals.css';
const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};

const getColorInfo = (hexColor) => {
  const rgb = hexToRgb(hexColor);
  const namedColor = getClosestColor(hexColor);
  return {
    namedColor,
    rgb
  };
};

const ProposalsCanvas = ({ 
    progress, 
    loading, 
    setLoading,
    generateVisualizations,
    isGenerationComplete,
    proposalVisualizationUrls = [],
    proposalPrompt
  }) => {
    const {
      selectedLocation,
      selectedTime,
      selectedScene,
      selectedDecor,
      selectedStyle,
      selectedColor,
    } = useDesign();
  
    const [bookmarkedImages, setBookmarkedImages] = useState({});
    const [showMessage, setShowMessage] = useState(false);
    const [user, setUser] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [credits, setCredits] = useState(null);
    const [showCreditsModal, setShowCreditsModal] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
    const generateToken = useCallback(async () => {
      try {
        const token = await generateAuthToken();
        return token;
      } catch (error) {
        return null;
      }
    }, []);
  
    useEffect(() => {
      const auth = getAuth();
      const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser);
        if (currentUser) {
          fetchCredits(currentUser);
        } else {
          setCredits(null);
        }
      });
  
      return () => unsubscribe();
    }, []);
  
    const handleImageError = (url) => {
      console.log(`Failed to load image: ${url}`);
    };
  
    const handleBookmark = async (imageUrl, prompt) => {
      setIsUploading(true);
      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        
        const formData = new FormData();
        formData.append('file', blob, 'proposal-design.jpg');
  
        const colorInfo = getColorInfo(selectedColor);
        const capitalizedColor = colorInfo.namedColor.charAt(0).toUpperCase() + colorInfo.namedColor.slice(1);
  
        const metadataValuesList = [
          { key: "location", value: selectedLocation },
          { key: "scene", value: selectedScene },
          { key: "flavor", value: selectedDecor},
          { key: "style", value: selectedStyle }
        ];
  
        const selectedOptions = JSON.stringify({ metadataValuesList });
  
        const token = await generateToken();
        if (!token) {
          throw new Error('Failed to generate authentication token');
        }
  
        const headers = {
          'contentType': 'jpeg',
          'authService': 'firebase',
          'externalClientId': user.uid,
          'prompt': prompt,
          'explorePage': 'true',
          'cwRed': colorInfo.rgb.r.toString(),
          'cwGreen': colorInfo.rgb.g.toString(),
          'cwBlue': colorInfo.rgb.b.toString(),
          'bookmarked': 'false',
          'selectedOptions': selectedOptions,
          'designCategory': 'proposals',
          'X-Auth-Token': token
        };
  
        const uploadResponse = await fetch('https://wedify.live/api/images/upload/auth/id', {
          method: 'POST',
          headers: headers,
          body: formData
        });
  
        if (!uploadResponse.ok) {
          const errorText = await uploadResponse.text();
          throw new Error(`HTTP error! status: ${uploadResponse.status}, message: ${errorText}`);
        }
  
        setBookmarkedImages(prev => ({
          ...prev,
          [imageUrl]: { bookmarked: true }
        }));
  
        setShowMessage(true);
        setTimeout(() => setShowMessage(false), 3000);
  
      } catch (error) {
        console.error('Error uploading image:', error);
      } finally {
        setIsUploading(false);
      }
    };
    
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      arrows: false,
    };
  
    const renderCombinedCarousel = () => {
      return (
        <div className="cake-image-container">
          <div className="image-wrapper">
            <img
              src={proposalVisualizationUrls[0]}
              alt="Generated proposal"
              className="generated-image"
              onError={() => handleImageError(proposalVisualizationUrls[0])}
            />
            <button
              className={`save-button ${bookmarkedImages[proposalVisualizationUrls[0]] ? 'saved' : ''}`}
              onClick={() => handleBookmark(proposalVisualizationUrls[0], proposalPrompt)}
              disabled={isUploading}
            >
              {bookmarkedImages[proposalVisualizationUrls[0]] ? 'Saved' : 'Save'}
            </button>
          </div>
        </div>
      );
    };
  
    const renderMobileStacked = () => (
      <div className="mobile-stacked-images">
        {proposalVisualizationUrls.map((url, index) => (
          <div key={index} className="mobile-image-item">
            <div className="image-label">Wedding Cake</div>
            <img 
              src={url}
              alt={`Generated proposal ${index + 1}`}
              className="generated-image"
              onError={() => handleImageError(url)}
            />
            <button 
              className={`save-button ${bookmarkedImages[url]?.bookmarked ? 'saved' : ''}`}
              onClick={() => handleBookmark(url, 'Proposal', proposalPrompt)}
              disabled={bookmarkedImages[url]?.bookmarked}
            >
              {bookmarkedImages[url]?.bookmarked ? 'Saved' : 'Save'}
            </button>
          </div>
        ))}
      </div>
    );
  
    const renderProgressCircle = () => {
      
      const steps = [
        { name: 'location', isComplete: !!selectedLocation },
        { name: 'time', isComplete: !!selectedTime },
        { name: 'scene', isComplete: !!selectedScene },
        { name: 'Flavor', isComplete: !!selectedDecor},
        { name: 'style', isComplete: !!selectedStyle},
        { name: 'Color', isComplete: !!(selectedColor && selectedColor !== '#ffffff') }
      ];
      
      return (
        <div className="progress-circle-container">
          <div className="steps-progress">
            {steps.map((step, index) => (
              <div 
                key={step.name} 
                className={`step-indicator ${step.isComplete ? 'completed' : ''}`}
              >
                <Circle 
                  location={24} 
                  className={`circle ${step.isComplete ? 'filled' : ''}`} 
                />
                <span className="step-label">{step.name}</span>
              </div>
            ))}
          </div>
          <div className="progress-text">
            {steps.filter(step => step.isComplete).length} of {steps.length} steps completed
          </div>
        </div>
      );
    };
  
    const handleVisualizationClick = async () => {
      if (!selectedLocation || !selectedTime || !selectedScene || !selectedDecor|| !selectedStyle || !selectedColor) {
        alert('Please complete all proposal options before generating');
        return;
      }
  
      if (credits <= 0) {
        setShowCreditsModal(true);
        return;
      }
  
      try {
        await generateVisualizations();
        // Fetch credits after generation is complete
        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (currentUser) {
          await fetchCredits(currentUser);
        }
      } catch (error) {
        console.error('Error during visualization process:', error);
      }
    };
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    const fetchCredits = async (currentUser) => {
      if (!currentUser) return;
      
      try {
        const token = await generateAuthToken();
        if (!token) {
          console.log('No auth token generated');
          return;
        }
  
        if (!process.env.REACT_APP_API_BASE_URL) {
          console.log('API base URL not configured');
          return;
        }
  
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/login/generation/credits/left?authService=firebase&externalClientId=${currentUser.uid}`,
          {
            method: 'GET',
            headers: {
              'content-type': 'application/json',
              'X-Auth-Token': token
            }
          }
        ).catch(error => {
          console.log('Network error:', error);
          return null;
        });
  
        if (response && response.ok) {
          const creditsData = await response.text();
          setCredits(Number(creditsData));
        }
      } catch (error) {
        console.log('Error in fetchCredits:', error);
      }
    };
  
    return (
      <div className="wedding-cakes-canvas">
        {!isMobile && credits !== null && (
          <button 
            className="credits-display desktop-only"
            onClick={() => setShowCreditsModal(true)}
          >
            <span>{credits} credits</span>
          </button>
        )}
  
        <h2 className="canvas-title">Your AI Generated Proposal</h2>
        <div className="canvas-grid">
          <div className="canvas-left">
            {!loading && !proposalVisualizationUrls.length && renderProgressCircle()}
          </div>
          <div className="canvas-center">
            {loading || proposalVisualizationUrls.length > 0 ? (
              <>
                {loading ? (
                  <div className="loading-container">
                    <Spinner text="Dream Proposal Generating" />
                    <div className="progress-indicator">
                      <div className="progress-bar">
                        <div 
                          className="progress" 
                          style={{ width: `${progress}%` }}
                        ></div>
                      </div>
                      <p>Progress: {progress}%</p>
                      <p>Generating your dream proposal...</p>
                    </div>
                  </div>
                ) : (
                  renderCombinedCarousel()
                )}
              </>
            ) : null}
          </div>
          <div className="canvas-right"></div>
        </div>
        <button 
          onClick={handleVisualizationClick}
          className={`visualization-button ${(!selectedLocation || !selectedTime || !selectedScene || !selectedDecor|| !selectedStyle || selectedColor === '#ffffff') ? 'disabled' : ''}`}
          disabled={!selectedLocation || !selectedTime || !selectedScene || !selectedDecor|| !selectedStyle || selectedColor === '#ffffff'}
        >
          {loading ? 'Generating...' : 
           credits <= 0 ? 'Out of Credits' : 
           (!selectedLocation || !selectedTime || !selectedScene || !selectedDecor|| !selectedStyle || selectedColor === '#ffffff') ? 'Complete All Options' :
           (proposalVisualizationUrls?.length > 0) ? 'Regenerate' : 'Generate Visualization'}
        </button>
        
        {showMessage && (
          <div className="bookmark-message">
            {isUploading ? 'Uploading...' : 'Added To User Profile'}
          </div>
        )}
        {isUploading && (
          <div className="upload-overlay">
            <div className="spinner"></div>
            <p>Uploading image...</p>
          </div>
        )}
        <PaymentPortal 
          isOpen={showCreditsModal}
          onClose={() => setShowCreditsModal(false)}
          user={user}
          fetchCredits={fetchCredits}
          credits={credits}
        />
      </div>
    );
  };
  
  export default ProposalsCanvas; 