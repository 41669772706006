import React from 'react';
import PricingPlanContent from './PricingPlanContent';
import './PricingPlan.css';
import Footer from '../landingPage/Footer';

const PricingPlan = () => {
    const renderList = (list) => (
        <ul className="list">
            {list.map((item, index) => (
                <li key={index}>{item}</li>
            ))}
        </ul>
    );

    const renderTable = (headers, data) => (
        <table className="pricing-table">
            <thead>
                <tr>
                    {headers.map((header, index) => (
                        <th key={index}>{header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((row, index) => (
                    <tr key={index}>
                        {row.map((cell, cellIndex) => (
                            <td key={cellIndex}>{cell}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );

    return (
        <div className="pricing-plan-wrapper">
            <div className="pricing-plan-container">
                <h1>{PricingPlanContent[0].title}</h1>
                <p className="effective-date">Effective Date: {PricingPlanContent[0].effectiveDate}</p>

                <section>
                    <p>{PricingPlanContent[0].introduction}</p>
                </section>

                {PricingPlanContent[0].sections.map((section, index) => (
                    <section key={index}>
                        <h2>{section.title}</h2>
                        <p>{section.content}</p>
                        {section.subtitles && section.subtitles.map((subtitle, subIndex) => (
                            <div key={subIndex}>
                                <strong>{subtitle.subtitle}</strong>
                                <p>{subtitle.content}</p>
                                {subtitle.tableHeaders && subtitle.tableData && (
                                    renderTable(subtitle.tableHeaders, subtitle.tableData)
                                )}
                                {subtitle.list && renderList(subtitle.list)}
                            </div>
                        ))}
                    </section>
                ))}
            </div>
            <Footer />
        </div>
    );
};

export default PricingPlan;
