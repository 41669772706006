import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './DesignOptions.css';

const DesignOptions = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const createParticles = () => {
      const container = document.querySelector('.particles-container');
      if (!container) return;
      
      const particleCount = 100;
      container.innerHTML = '';
      
      for (let i = 0; i < particleCount; i++) {
        const particle = document.createElement('div');
        particle.className = 'particle';
        
        // Enhanced random properties
        const size = Math.random() * 4 + 1;
        const moveX = Math.random() * 200 - 100;
        const moveY = Math.random() * 200 - 100;
        
        particle.style.cssText = `
          width: ${size}px;
          height: ${size}px;
          left: ${Math.random() * 100}%;
          top: ${Math.random() * 100}%;
          --moveX: ${moveX}px;
          --moveY: ${moveY}px;
          animation: floatParticle ${Math.random() * 20 + 10}s infinite,
                     particleFadeInOut ${Math.random() * 4 + 2}s infinite ${Math.random() * -4}s;
        `;
        
        // Add particle trail
        const trail = document.createElement('div');
        trail.className = 'particle-trail';
        trail.style.left = particle.style.left;
        trail.style.top = particle.style.top;
        
        container.appendChild(particle);
        container.appendChild(trail);
      }
    };

    createParticles();
    const resizeTimer = setInterval(createParticles, 10000); // Recreate particles every 10 seconds
    window.addEventListener('resize', createParticles);
    
    return () => {
      clearInterval(resizeTimer);
      window.removeEventListener('resize', createParticles);
    };
  }, []);

  useEffect(() => {
    // Create orbs
    const createOrbs = () => {
      const container = document.querySelector('.design-options');
      if (!container) return;
      
      for (let i = 0; i < 5; i++) {
        const orb = document.createElement('div');
        orb.className = 'orb';
        const size = Math.random() * 200 + 100;
        orb.style.cssText = `
          width: ${size}px;
          height: ${size}px;
          left: ${Math.random() * 100}%;
          top: ${Math.random() * 100}%;
          --moveX: ${Math.random() * 200 - 100}px;
          --moveY: ${Math.random() * 200 - 100}px;
          animation-delay: ${Math.random() * -15}s;
        `;
        container.appendChild(orb);
      }
      
      // Create constellation
      const constellation = document.createElement('div');
      constellation.className = 'constellation';
      for (let i = 0; i < 50; i++) {
        const star = document.createElement('div');
        star.className = 'star';
        star.style.cssText = `
          left: ${Math.random() * 100}%;
          top: ${Math.random() * 100}%;
          animation-delay: ${Math.random() * -4}s;
        `;
        constellation.appendChild(star);
      }
      container.appendChild(constellation);
    };

    createOrbs();
    return () => {
      const container = document.querySelector('.design-options');
      if (container) {
        const orbs = container.querySelectorAll('.orb');
        const constellation = container.querySelector('.constellation');
        orbs.forEach(orb => orb.remove());
        if (constellation) constellation.remove();
      }
    };
  }, []);

  const options = [
    {
      title: "Wedding Decor",
      description: "Design Your Perfect Wedding Venue",
      path: "/design/decor",
      image: "/themesOption/Rustic.jpg",
      available: true
    },
    {
      title: "Florals",
      description: "Create Your Dream Floral Arrangement, from Bouquets to Boutonnieres",
      path: "/design/florals",
      image: "/themesOption/Bouquet.jpg",
      available: true
    },
    {
      title: "Wedding Cake",
      description: "Craft Your Dream Wedding Cake",
      path: "/design/weddingcake",
      image: "/themesOption/Cake.jpg",
      available: true
    },
    {
      title: "Proposal",
      description: "Bring Your Ultimate Proposal Vision to Life",
      path: "/design/proposals",
      image: "/themesOption/Proposal.jpg",
      available: true
    },
    {
      title: "Wedding Attire",
      description: "Elevate Your Style with Tailored Outfits for the Bride and Groom",
      path: "/design/attire",
      image: "/themesOption/Attire.jpg",
      available: true
    },
    {
      title: "More Categories Coming Soon",
      description: "Check Back Soon For More Design Options",
      path: "/design/morecategories",
      image: "/themesOption/Invitation.jpg",
      available: false
    },
  ];

  return (
    <div className="design-options">
      <div className="background-gradient" />
      <div className="particles-container" />
      <div className="content-wrapper">
        <h1>Choose Your Design Experience</h1>
        <div className="options-container">
          {options.map((option) => (
            <div 
              key={option.title} 
              className={`option-card ${!option.available ? 'disabled' : ''}`}
              onClick={() => option.available && navigate(option.path)}
            >
              {!option.available && <div className="coming-soon-banner">Coming Soon</div>}
              <img src={option.image} alt={option.title} />
              <h2>{option.title}</h2>
              <p>{option.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DesignOptions; 