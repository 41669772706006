import React, { useState, useEffect, useCallback, useRef } from 'react';
import ColorPicker from '../../../components/ColorPicker';
import { getClosestColor } from '../../../components/colorMapper';
import { useDesign } from '../../../hooks/DesignContext';
import sendToDiscord from '../../../utils/discordWebhook';
import '../Page.css';
import '../../design/decor/Design.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { getAuth } from 'firebase/auth';
import CryptoJS from 'crypto-js';
import { generateAuthToken } from '../../../utils/encryptExternalID';
import getImageUrl from '../../../utils/imageUtils';
import ProposalsCanvas from './ProposalsCanvas';

import '../../design/floral/Florals.css';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const SECRET_KEY = process.env.REACT_APP_WEDIFY_APP_SECRET_KEY;

const encryptPrompt = (plaintext, key) => {
  try {
    const keyBytes = CryptoJS.enc.Hex.parse(key);
    const encrypted = CryptoJS.AES.encrypt(plaintext, keyBytes, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  } catch (error) {
    return null;
  }
};

const Proposals = () => {
    const { 
   
    selectedColor, setSelectedColor,
    selectedLocation, setSelectedLocation,
    selectedTime, setSelectedTime,
    selectedScene, setSelectedScene,
    selectedDecor, setSelectedDecor,
    selectedStyle, setSelectedStyle,
    proposalVisualizationUrls, setProposalVisualizationUrls,
    isGenerationComplete,setIsGenerationComplete,
    proposalPrompt, setProposalPrompt,
    loading, setLoading,
    progress, setProgress
    } = useDesign();
    
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [showAddInput, setShowAddInput] = useState({ location: false, time: false, decor: false, style: false});
    const [newInput, setNewInput] = useState({ location: '', time: '', decor: '', style: '' });
    const [currentStep, setCurrentStep] = useState(1);
    

    const locationOptions = [
        { name: 'Beach', image: '/proposalPics/location/beach.jpg' },
        { name: 'Restaurant', image: '/proposalPics/location/restaurant.jpg' },
        { name: 'Garden', image: '/proposalPics/location/garden.jpg' },
        { name: 'Rooftop', image: '/proposalPics/location/rooftop.jpg' },
        { name: 'Mountain', image: '/proposalPics/location/mountain.jpg' },
        { name: 'Lake', image: '/proposalPics/location/lake.jpg' },
        { name: 'Hot Air Balloon', image: '/proposalPics/location/hotAir.jpg' },
        { name: 'Add', image: '/themesOption/add-plus.jpg' }
    ];
  
    const timeOptions = [
        { name: 'Sunset', image: '/proposalPics/time/sunset.jpg' },
        { name: 'Daytime', image: '/proposalPics/time/dayTime.jpg' },
        { name: 'Night', image: '/proposalPics/time/nightTime.jpg' },
        { name: 'Golden Hour', image: '/proposalPics/time/goldenHour.jpg' },
        { name: 'Candlelit', image: '/proposalPics/time/candleLit.jpg' },
        { name: 'Add', image: '/themesOption/add-plus.jpg' }
    ];
  
    const sceneOptions = [
        { name: 'Picnic', image: '/proposalPics/scene/picnic.jpg' },
        { name: 'Dinner Table', image: '/proposalPics/scene/dinnerTable.jpg' },
        { name: 'Garden Path', image: '/proposalPics/scene/gardenPath.jpg' },
        { name: 'Beach Setup', image: '/proposalPics/scene/beachSetup.jpg' },
        { name: 'Gazebo', image: '/proposalPics/scene/gazebo.jpg' },
        { name: 'Add', image: '/themesOption/add-plus.jpg'}
    ];
  
    const decorOptions = [
        { name: 'Rose Petals', image: '/proposalPics/decor/petals.jpg' },
        { name: 'Candles', image: '/proposalPics/decor/candles.jpg' },
        { name: 'String Lights', image: '/proposalPics/decor/stringLights.jpg' },
        { name: 'Flowers', image: '/proposalPics/decor/flowers.jpg' },
        { name: 'Lanterns', image: '/proposalPics/decor/lanterns.jpg' },
        { name: 'Balloons', image: '/proposalPics/decor/baloons.jpg' },
        { name: 'Add', image: '/themesOption/add-plus.jpg' }
    ];
  
    const styleOptions = [
        { name: 'Romantic', image: '/proposalPics/style/romantic.jpg' },
        { name: 'Cinematic', image: '/proposalPics/style/cinematic.jpg' },
        { name: 'Dreamy', image: '/proposalPics/style/dreamy.jpg' },
        { name: 'Classic', image: '/proposalPics/style/classic.jpg' },
        { name: 'Add', image: '/themesOption/add-plus.jpg' }
    ];
  
    // ... (keep color presets)
    const colorPresets = [
      // Romantic & Classic Colors
      { name: 'Rose Gold', hex: '#B76E79' },
      { name: 'Blush Pink', hex: '#FFB6C1' },
      { name: 'Burgundy', hex: '#800020' },
      { name: 'Deep Red', hex: '#DC143C' },
      
      // Evening & Elegant Colors
      { name: 'Navy Blue', hex: '#000080' },
      { name: 'Royal Purple', hex: '#7851A9' },
      { name: 'Deep Purple', hex: '#301934' },
      { name: 'Midnight Blue', hex: '#191970' },
      
      // Natural & Outdoor Colors
      { name: 'Forest Green', hex: '#228B22' },
      { name: 'Sage', hex: '#9DC183' },
      { name: 'Ocean Blue', hex: '#4F97A3' },
      { name: 'Sunset Orange', hex: '#FD5E53' },
      
      // Neutral & Ambient Colors
      { name: 'Gold', hex: '#dcc282' },
      { name: 'Silver', hex: '#C0C0C0' },
      { name: 'Champagne', hex: '#F7E7CE' },
      { name: 'Warm White', hex: '#FAF9F6' },
      
      // Custom Option
      { name: 'Custom', image: getImageUrl('themesOption/colorPicker.png') }
    ];
  
    const handleLocationChange = (location) => {
        setSelectedLocation(location);
      };
    
      const handleTimeChange = (time) => {
        setSelectedTime(time);
      };
    
      const handleSceneChange = (scene) => {
        setSelectedScene(scene);
      };
    
      const handleDecorChange = (decor) => {
        setSelectedDecor(decor);
      };
    
      const handleStyleChange = (style) => {
        setSelectedStyle(style);
      };
    
  
    const handleColorChange = (color) => {
  
      setSelectedColor(color.hex);
    };
  
      // ... (kept similar checkGenerationStatus)
    const checkGenerationStatus = async (generationId) => {
      let attempts = 0;
      const maxAttempts = 30;
      const initialDelay = 1000;
      const maxDelay = 10000;
        
      const poll = async () => {
        if (attempts >= maxAttempts) {
          throw new Error('Generation timed out');
        }
    
        attempts++;
    
        try {
          const token = generateAuthToken(); // Generate token for each request
          const response = await fetch(`${API_BASE_URL}/api/images/get/image/retrieve`, {
              method: 'GET',
              headers: {
                'imageId': generationId,
                'X-Auth-Token': token, // Add the token to the headers
              },
            });
            
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const contentType = response.headers.get('Content-Type');
            const contentLength = response.headers.get('Content-Length');
    
            if (contentType && contentType.includes('image') && contentLength && parseInt(contentLength) > 0) {
              const blob = await response.blob();
              return URL.createObjectURL(blob);
            } else {
              const delay = Math.min(initialDelay * Math.pow(1.5, attempts), maxDelay);
              await new Promise(resolve => setTimeout(resolve, delay));
              return poll();
            }
          } catch (error) {
            const delay = Math.min(initialDelay * Math.pow(1.5, attempts), maxDelay);
            await new Promise(resolve => setTimeout(resolve, delay));
            return poll();
          }
        };
    
      return poll();
    };
  
    const fetchCredits = async () => {
      try {
        const token = await generateAuthToken();
        const response = await fetch(`${API_BASE_URL}/api/credits`, {
          method: 'GET',
          headers: {
            'X-Auth-Token': token,
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        
        // Update state or context with the fetched credits if needed
      } catch (error) {
        
      }
    };
  
    const generateVisualizations = async () => {
      if (!selectedLocation || !selectedTime || !selectedScene || !selectedDecor|| !selectedStyle || !selectedColor) {
        console.error('Not all required fields are selected');
        return;
      }
  
      setLoading(true);
      setIsGenerationComplete(false);
      setProgress(0);
      setCurrentStep(7);
  
      try {
        const auth = getAuth();
        const currentUser = auth.currentUser;
          
        if (!currentUser) {
            console.error('Authentication error: No user found');
            throw new Error('No authenticated user found');
        }
    
        setProgress(10);
    
        const token = await generateAuthToken();
        if (!token) {
            console.error('Token generation failed');
            throw new Error('Failed to generate authentication token');
        }
    
        // Get color name - handle both preset and custom colors
        let colorName;
        const selectedColorObj = colorPresets.find(color => color.hex === selectedColor);
        
        if (selectedColorObj) {
          // If it's a preset color, use its name
          colorName = selectedColorObj.name;
        } else {
          // If it's a custom color, get the closest named color
          colorName = getClosestColor(selectedColor);
         
        }
        
        // Define prompts with the color name
        const proposalPromptText = `A beautifully arranged ${selectedLocation} proposal setup during ${selectedTime}, featuring a stunning ${selectedScene} decorated with ${selectedDecor} and styled in a ${selectedStyle} aesthetic, all in a ${colorName} color scheme. The focus is entirely on the elegant and romantic arrangement, perfect for capturing the ambiance with professional photography.`;
   
        setProposalPrompt(proposalPromptText);
    
        // Generate image
        const proposalUrl = await generateImage(proposalPromptText, token, currentUser.uid);
       
    
        setProposalVisualizationUrls([proposalUrl]);
        setIsGenerationComplete(true);
        setProgress(100);
    
        // Fetch credits after the image is generated
        await fetchCredits();
    
      } catch (error) {
        console.error('Error in generateVisualizations:', error);
        setIsGenerationComplete(false);
        // Optionally show error to user
      } finally {
        setLoading(false);
      }
    };
  
    const generateImage = async (prompt, token, userId) => {
      try {
        const encryptedPrompt = encryptPrompt(prompt, SECRET_KEY);
        if (!encryptedPrompt) {
            console.error('Prompt encryption failed');
            throw new Error('Failed to encrypt prompt');
        }

        const generateResponse = await fetch(`${API_BASE_URL}/api/images/get/image/generate`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': token,
                'whatYouLookinAt': encryptedPrompt,
                'authId': userId,
                'authenticationService': 'firebase',
            }
        });
        
        if (!generateResponse.ok) {
            const responseText = await generateResponse.text();
            console.error('API Error Response:', {
                status: generateResponse.status,
                statusText: generateResponse.statusText,
                body: responseText
            });
            throw new Error(`HTTP error! status: ${generateResponse.status}`);
        }

        const generateData = await generateResponse.json();

        if (generateData?.sdGenerationJob?.generationId) {
            const generationId = generateData.sdGenerationJob.generationId;
            const imageUrl = await checkGenerationStatus(generationId);
            return imageUrl;
        } else {
            throw new Error('No generationId returned');
        }
    } catch (error) {
        console.error('Error in generateImage:', error);
        throw error;
    }
};
  
    const locationSliderRef = useRef(null);
    const timeSliderRef = useRef(null);
    const sceneSliderRef = useRef(null);
    const decorSliderRef = useRef(null);
    const styleSliderRef = useRef(null);

    const locationIntervalRef = useRef(null);
    const timeIntervalRef = useRef(null);
    const sceneIntervalRef = useRef(null);
    const decorIntervalRef = useRef(null);
    const styleIntervalRef = useRef(null);

    const startLocationAutoplay = (direction) => {
      if (locationSliderRef.current?.swiper) {
        const swiper = locationSliderRef.current.swiper;
        if (direction === 'left') {
          swiper.slidePrev();
        } else {
          swiper.slideNext();
        }
        if (locationIntervalRef.current) {
          clearInterval(locationIntervalRef.current);
        }
        locationIntervalRef.current = setInterval(() => {
          if (direction === 'left') {
            swiper.slidePrev();
          } else {
            swiper.slideNext();
          }
        }, 300);
      }
    };

    const stopLocationAutoplay = () => {
      if (locationIntervalRef.current) {
        clearInterval(locationIntervalRef.current);
        locationIntervalRef.current = null;
      }
    };

    const startTimeAutoplay = (direction) => {
      if (timeSliderRef.current?.swiper) {
        const swiper = timeSliderRef.current.swiper;
        if (direction === 'left') {
          swiper.slidePrev();
        } else {
          swiper.slideNext();
        }
        if (timeIntervalRef.current) {
          clearInterval(timeIntervalRef.current);
        }
        timeIntervalRef.current = setInterval(() => {
          if (direction === 'left') {
            swiper.slidePrev();
          } else {
            swiper.slideNext();
          }
        }, 300);
      }
    };

    const stopTimeAutoplay = () => {
      if (timeIntervalRef.current) {
        clearInterval(timeIntervalRef.current);
        timeIntervalRef.current = null;
      }
    };

    const sliderSettings = {
      modules: [Navigation, Autoplay, Pagination],
      spaceBetween: 1,
      slidesPerView: 3,
      speed: 200,
      loop: true,
      allowTouchMove: false,
      pagination: {
        clickable: true,
        type: 'bullets',
        dynamicBullets: true,
        dynamicMainBullets: 3
      }
    };
  
    const renderMobileStep = () => {
      switch(currentStep) {
        case 1:
          return (
            <>
              <div className="step-header">
                <h2>Choose Your location</h2>
              </div>
              <div className="mobile-options-grid">
                {locationOptions.map((location) => (
                  <div 
                    key={location.name}
                    onClick={() => location.name === 'Add' ? handleAddClick('location') : handleLocationChange(location.name)}
                    className={`mobile-option-item ${selectedLocation === location.name ? 'selected' : ''}`}
                  >
                    <img src={location.image} alt={location.name} />
                    <p>{location.name}</p>
                  </div>
                ))}
              </div>
              {renderContinueButton(selectedLocation, 2)}
            </>
          );
        case 2:
          return (
            <>
              <div className="step-header">
                <h2>Choose Your Time</h2>
              </div>
              <div className="mobile-options-grid">
                {timeOptions.map((time) => (
                  <div 
                    key={time.name}
                    onClick={() => {
                      if (time.name === 'Add') {
                        handleAddClick('time');
                        return;
                      }
                      handleTimeChange(time.name);
                    }}
                    className={`mobile-option-item ${selectedTime === time.name ? 'selected' : ''}`}
                  >
                    <img src={time.image} alt={time.name} />
                    <p>{time.name}</p>
                  </div>
                ))}
              </div>
              {renderContinueButton(selectedTime, 3)}
            </>
          );
          case 3:
          return (
            <>
              <div className="step-header">
                <h2>Choose Your Scene</h2>
              </div>
              <div className="mobile-options-grid">
                {sceneOptions.map((scene) => (
                  <div 
                    key={scene.name}
                    onClick={() => {
                      if (scene.name === 'Add') {
                        handleAddClick('scene');
                        return;
                      }
                      handleSceneChange(scene.name);
                    }}
                    className={`mobile-option-item ${selectedScene === scene.name ? 'selected' : ''}`}
                  >
                    <img src={scene.image} alt={scene.name} />
                    <p>{scene.name}</p>
                  </div>
                ))}
              </div>
              {renderContinueButton(selectedScene, 4)}
            </>
          );
        case 4:
          return (
            <>
              <div className="step-header">
                <h2>Select Your Decor</h2>
              </div>
              <div className="mobile-options-grid">
                {decorOptions.map((decor) => (
                  <div 
                    key={decor.name}
                    onClick={() => {
                      if (decor.name === 'Add') {
                        handleAddClick('decor');
                        return;
                      }
                      handleDecorChange(decor.name);
                    }}
                    className={`mobile-option-item ${selectedDecor=== decor.name ? 'selected' : ''}`}
                  >
                    <img src={decor.image} alt={decor.name} />
                    <p>{decor.name}</p>
                  </div>
                ))}
              </div>
              {renderContinueButton(selectedDecor, 5)}
            </>
          );
        case 5:
          return (
            <>
              <div className="step-header">
                <h2>Choose Your Style</h2>
              </div>
              <div className="mobile-options-grid">
                {styleOptions.map((style) => (
                  <div 
                    key={style.name}
                    onClick={() => {
                      if (style.name === 'Add') {
                        handleAddClick('style');
                        return;
                      }
                      handleStyleChange(style.name);
                    }}
                    className={`mobile-option-item ${selectedStyle === style.name ? 'selected' : ''}`}
                  >
                    <img src={style.image} alt={style.name} />
                    <p>{style.name}</p>
                    {selectedStyle === style.name && style.subOptions && (
                      <select
                        onChange={(e) => {
                          e.stopPropagation();
                        }}
                        className="style-sub-option"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <option value="">Select {style.name} Type</option>
                        {style.subOptions.map((option) => (
                          <option key={option} value={option}>{option}</option>
                        ))}
                      </select>
                    )}
                  </div>
                ))}
              </div>
              {selectedStyle && (
                <button 
                  className="continue-button"
                  onClick={() => setCurrentStep(6)}
                >
                  Continue
                </button>
              )}
            </>
          );
        case 6:
          return (
            <>
              <div className="step-header">
                <h2>Choose Your Color</h2>
              </div>
              <div className="color-presets-container">
                {colorPresets.map((color) => (
                  <div 
                    key={color.name}
                    onClick={() => color.name === 'Custom' 
                      ? setShowColorPicker(true) 
                      : handleColorChange({ hex: color.hex })}
                    className={`color-preset-item ${selectedColor === color.hex ? 'selected' : ''}`}
                  >
                    {color.name === 'Custom' ? (
                      <>
                        <img src={color.image} alt="Custom color" className="color-wheel-icon" />
                        <p>Custom Color</p>
                      </>
                    ) : (
                      <>
                        <div 
                          className="color-swatch" 
                          style={{ backgroundColor: color.hex }}
                        />
                        <p>{color.name}</p>
                      </>
                    )}
                  </div>
                ))}
              </div>
              <div className="visualization-container">
                <button
                  onClick={generateVisualizations}
                  disabled={
                    !selectedLocation ||
                    !selectedTime ||
                    !selectedScene ||
                    !selectedDecor|| 
                    !selectedStyle || 
                    !selectedColor || 
                    selectedColor === '#ffffff' || 
                    loading
                  }
                  className={`visualization-button mobile-generate-button ${
                    (!selectedLocation || !selectedTime || !selectedScene || !selectedDecor|| !selectedStyle || !selectedColor || selectedColor === '#ffffff') ? 'disabled' : ''
                  }`}
                >
                  {loading ? 'Generating...' : isGenerationComplete ? 'Regenerate' : 'Generate Visualization'}
                </button>
              </div>
              {loading && (
                <div className="progress-container">
                  <div className="progress-bar">
                    <div className="progress" style={{ width: `${progress}%` }}></div>
                  </div>
                </div>
              )}
            </>
          );
        case 7:
          return (
            <>
              <ProposalsCanvas 
                progress={progress}
                loading={loading}
                setLoading={setLoading}
                generateVisualizations={generateVisualizations}
                isGenerationComplete={isGenerationComplete}
                proposalVisualizationUrls={proposalVisualizationUrls}
                proposalPrompt={proposalPrompt}
              />
            </>
          );
        default:
          return null;
      }
    };
  
    // ... (keep slider refs and settings)
    
    const renderContinueButton = (condition, nextStep) => {
      if (condition) {
        if (nextStep === 6) {
          return (
            <button
              className="continue-button"
              onClick={async () => {
                setCurrentStep(nextStep);
                await generateVisualizations();
              }}
              disabled={loading}
            >
              {loading ? 'Generating...' : 'Generate'}
            </button>
          );
        }
        return (
          <button
            className="continue-button"
            onClick={() => setCurrentStep(nextStep)}
          >
            Continue
          </button>
        );
      }
      return null;
    };
  
    const renderProgressBar = () => {
      const steps = [
        { num: 1, name: 'Location' },
        { num: 2, name: 'Time' },
        { num: 3, name: 'Scene' },
        { num: 4, name: 'Decor' },
        { num: 5, name: 'Style' },
        { num: 6, name: 'Color'},
        { num: 7, name: 'Preview'}
      ];
  
      // Determine which steps to show
      const visibleSteps = currentStep <= 4 
        ? steps.slice(0, 4)  // Show steps 1-4
        : steps.slice(3, 7); // Show steps 4-7
  
      return (
        <div className="mobile-progress-bar">
          {visibleSteps.map((step) => (
            <div 
              key={step.num}
              className={`step ${currentStep >= step.num ? 'completed' : ''} ${currentStep === step.num ? 'active' : ''}`}
              onClick={() => {
                // Only allow clicking on completed steps or the next available step
                if (step.num <= currentStep + 1) {
                  setCurrentStep(step.num);
                }
              }}
            >
              <div className="step-number">{step.num}</div>
              <div className="step-name">{step.name}</div>
            </div>
          ))}
        </div>
      );
    };
  
    // Autoplay functions for sliders
    const startAutoplay = (sliderRef, intervalRef, direction) => {
      if (sliderRef.current && sliderRef.current.swiper) {
        const swiper = sliderRef.current.swiper;
        
        if (direction === 'left') {
          swiper.slidePrev();
        } else {
          swiper.slideNext();
        }
  
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
  
        intervalRef.current = setInterval(() => {
          if (direction === 'left') {
            swiper.slidePrev();
          } else {
            swiper.slideNext();
          }
        }, 200);
      }
    };
  
    const stopAutoplay = (intervalRef) => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  
    const handleAddClick = (type) => {
      setShowAddInput(prev => ({ ...prev, [type]: true }));
    };
  
    const handleInputChange = (e, type) => {
      setNewInput(prev => ({ ...prev, [type]: e.target.value }));
    };
  
    const handleNewInputSubmit = async (type) => {
      if (!newInput[type].trim()) {
        return;
      }
  
      // Get current user
      const auth = getAuth();
      const user = auth.currentUser;
      const userEmail = user ? user.email : 'Anonymous';
  
      // Prepare message for Discord
      const message = {
        username: "Wedify Bot",
        content: `New ${type} suggestion from ${userEmail}`,
        embeds: [{
          title: `New ${type} Suggestion`,
          description: `Suggested ${type}: ${newInput[type]}`,
          fields: [
            {
              name: 'Category',
              value: `Proposal ${type.charAt(0).toUpperCase() + type.slice(1)}`
            },
            {
              name: 'Submitted by',
              value: userEmail
            }
          ],
          color: 3066993 // Green color in decimal
        }]
      };
  
      try {
        await sendToDiscord(message);
        setNewInput(prev => ({ ...prev, [type]: '' }));
        setShowAddInput(prev => ({ ...prev, [type]: false }));
        alert('Thank you for your suggestion! We will review it shortly.');
      } catch (error) {
        console.error('Error sending suggestion:', error);
        alert('Sorry, there was an error submitting your suggestion. Please try again later.');
      }
    };
  
    useEffect(() => {
    }, [selectedColor]);
  
    // Add this ref at the top of your component
    const stepsContainerRef = useRef(null);
  
    // Add this effect to handle scrolling when step changes
    useEffect(() => {
      if (currentStep > 4 && stepsContainerRef.current) {
        const stepWidth = 80; // Approximate width of each step including margins
        const scrollPosition = (currentStep - 4) * stepWidth;
        stepsContainerRef.current.scrollTo({
          left: scrollPosition,
          behavior: 'smooth'
        });
      }
    }, [currentStep]);
  
    return (
      <div className="wedding-cakes-design-page">
        {/* Mobile Content*/}
        <div className="wedding-cakes-mobile-content">
          <div className="mobile-design">
            <div className="mobile-content">
              {renderProgressBar()}
              <div className="mobile-step">
                {renderMobileStep()}
              </div>
            </div>
          </div>
        </div>
  
        {/* Desktop Layout*/}
        <div className="wedding-cakes-desktop-content">
          <div className="design-container">
            <h2 className="page-title">Design Your Proposal</h2>
  
              {/* Location Section */}
              <div className="design-section tiers">
                <h3>Select Location</h3>
                <div className="slider-container">
                  <div 
                    className="hover-zone hover-zone-left"
                    onMouseEnter={() => startLocationAutoplay('left')}
                    onMouseLeave={stopLocationAutoplay}
                  />
                  <Swiper
                    ref={locationSliderRef}
                    {...sliderSettings}
                    className="location-swiper"
                  >
                    {locationOptions.map((location) => (
                      <SwiperSlide key={location.name}>
                        <div 
                          onClick={() => {
                            if (location.name === 'Add') {
                              handleAddClick('location');
                              return;
                            }
                            handleLocationChange(location.name);
                          }}
                          className={`carousel-item ${selectedLocation === location.name ? 'selected' : ''}`}
                        >
                          <img src={location.image} alt={location.name} className="carousel-image" />
                          <p>{location.name}</p>
                        </div>
                      </SwiperSlide>
                    ))}
                    <div className="swiper-pagination location-pagination"></div>
                  </Swiper>
                  <div 
                    className="hover-zone hover-zone-right"
                    onMouseEnter={() => startLocationAutoplay('right')}
                    onMouseLeave={stopLocationAutoplay}
                  />
                </div>
              </div>
  
              {/* time Section */}
              <div className="design-section tiers">
                <h3>Select Time</h3>
                <div className="slider-container">
                  <div 
                    className="hover-zone hover-zone-left"
                    onMouseEnter={() => startTimeAutoplay('left')}
                    onMouseLeave={stopTimeAutoplay}
                  />
                  <Swiper
                    ref={timeSliderRef}
                    {...sliderSettings}
                    className="time-swiper"
                  >
                    {timeOptions.map((time) => (
                      <SwiperSlide key={time.name}>
                        <div 
                          onClick={() => {
                            if (time.name === 'Add') {
                              handleAddClick('time');
                              return;
                            }
                            handleTimeChange(time.name);
                          }}
                          className={`carousel-item ${selectedTime === time.name ? 'selected' : ''}`}
                        >
                          <img src={time.image} alt={time.name} className="carousel-image" />
                          <p>{time.name}</p>
                        </div>
                      </SwiperSlide>
                    ))}
                    <div className="swiper-pagination time-pagination"></div>
                  </Swiper>
                  <div 
                    className="hover-zone hover-zone-right"
                    onMouseEnter={() => startTimeAutoplay('right')}
                    onMouseLeave={stopTimeAutoplay}
                  />
                </div>
              </div>
  
              {/* scene Section */}
              <div className="design-section shapes">
                <h3>Select Scene</h3>
                <div className="slider-container">
                  <div 
                    className="hover-zone hover-zone-left"
                    onMouseEnter={() => startAutoplay(sceneSliderRef, sceneIntervalRef, 'left')}
                    onMouseLeave={() => stopAutoplay(sceneIntervalRef)}
                  />
                  <Swiper
                    ref={sceneSliderRef}
                    {...sliderSettings}
                    className="scene-swiper"
                  >
                    {sceneOptions.map((scene) => (
                      <SwiperSlide key={scene.name}>
                        <div 
                          onClick={() => {
                            if (scene.name === 'Add') {
                              handleAddClick('scene');
                              return;
                            }
                            handleSceneChange(scene.name);
                          }}
                          className={`carousel-item ${selectedScene === scene.name ? 'selected' : ''}`}
                        >
                          <img src={scene.image} alt={scene.name} className="carousel-image" />
                          <p>{scene.name}</p>
                        </div>
                      </SwiperSlide>
                    ))}
                    <div className="swiper-pagination scene-pagination"></div>
                  </Swiper>
                  <div 
                    className="hover-zone hover-zone-right"
                    onMouseEnter={() => startAutoplay(sceneSliderRef, sceneIntervalRef, 'right')}
                    onMouseLeave={() => stopAutoplay(sceneIntervalRef)}
                  />
                </div>
              </div>
  
              {/* decor Section */}
              <div className="design-section flavors">
                <h3>Select Decor</h3>
                <div className="slider-container">
                <div 
                    className="hover-zone hover-zone-left"
                    onMouseEnter={() => startAutoplay(decorSliderRef, decorIntervalRef, 'left')}
                    onMouseLeave={() => stopAutoplay(decorIntervalRef)}
                  />
                  <Swiper
                    ref={decorSliderRef}
                    {...sliderSettings}
                    className="decor-swiper"
                  >
                    {decorOptions.map((decor) => (
                      <SwiperSlide key={decor.name}>
                        <div 
                          onClick={() => {
                            if (decor.name === 'Add') {
                              handleAddClick('decor');
                              return;
                            }
                            handleDecorChange(decor.name);
                          }}
                          className={`carousel-item ${selectedDecor=== decor.name ? 'selected' : ''}`}
                        >
                          <img src={decor.image} alt={decor.name} className="carousel-image" />
                          <p>{decor.name}</p>
                        </div>
                      </SwiperSlide>
                    ))}
                    <div className="swiper-pagination decor-pagination"></div>
                  </Swiper>
                  <div 
                    className="hover-zone hover-zone-right"
                    onMouseEnter={() => startAutoplay(decorSliderRef, decorIntervalRef, 'right')}
                    onMouseLeave={() => stopAutoplay(decorIntervalRef)}
                  />
                </div>
              </div>
  
              {/* style Section */}
              <div className="design-section decorations">
                <h3>Select Your Style</h3>
                <div className="slider-container">
                <div 
                    className="hover-zone hover-zone-left"
                    onMouseEnter={() => startAutoplay(styleSliderRef, styleIntervalRef, 'left')}
                    onMouseLeave={() => stopAutoplay(styleIntervalRef)}
                  />
                  <Swiper
                    ref={styleSliderRef}
                    {...sliderSettings}
                    className="style-swiper"
                  >
                    {styleOptions.map((style) => (
                      <SwiperSlide key={style.name}>
                        <div 
                          onClick={() => {
                            if (style.name === 'Add') {
                              handleAddClick('style');
                              return;
                            }
                            handleStyleChange(style.name);
                          }}
                          className={`carousel-item ${selectedStyle === style.name ? 'selected' : ''}`}
                        >
                          <img src={style.image} alt={style.name} className="carousel-image" />
                          <p>{style.name}</p>
                          {selectedStyle === style.name && style.subOptions && (
                            <select
                              onClick={(e) => e.stopPropagation()}
                              className="style-sub-option"
                            >
                              <option value="">Select Style</option>
                              {style.subOptions.map((subOption) => (
                                <option key={subOption} value={subOption}>{subOption}</option>
                              ))}
                            </select>
                          )}
                        </div>
                      </SwiperSlide>
                    ))}
                    <div className="swiper-pagination style-pagination"></div>
                  </Swiper>
                  <div 
                    className="hover-zone hover-zone-right"
                    onMouseEnter={() => startAutoplay(styleSliderRef, styleIntervalRef, 'right')}
                    onMouseLeave={() => stopAutoplay(styleIntervalRef)}
                  />
                </div>
              </div>
  
              {/* Color Section */}
              <div className="design-section colors">
                <h3>Select Color</h3>
                {!showColorPicker ? (
                  <div className="color-presets-container">
                    {colorPresets.map((color) => (
                      <div 
                        key={color.name}
                        onClick={() => color.name === 'Custom' 
                          ? setShowColorPicker(true) 
                          : handleColorChange({ hex: color.hex })}
                        className={`color-preset-item ${selectedColor === color.hex ? 'selected' : ''}`}
                      >
                        {color.name === 'Custom' ? (
                          <>
                            <img src={color.image} alt="Custom color" className="color-wheel-icon" />
                            <p>Custom Color</p>
                          </>
                        ) : (
                          <>
                            <div 
                              className="color-swatch" 
                              style={{ backgroundColor: color.hex }}
                            />
                            <p>{color.name}</p>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="color-picker-container">
                    <button 
                      onClick={() => setShowColorPicker(false)}
                      className="back-to-presets-btn"
                    >
                      ← Back to Presets
                    </button>
                    <ColorPicker 
                      selectedColor={selectedColor} 
                      onColorChange={handleColorChange} 
                    />
                  </div>
                )}
              </div>
  
              {/* Generate Button Section */}
              <div className="design-section generate">
                <div className="visualization-container">
                  <button
                    onClick={generateVisualizations}
                    disabled={
                      !selectedLocation ||
                      !selectedTime ||
                      !selectedScene ||
                      !selectedDecor|| 
                      !selectedStyle || 
                      !selectedColor || 
                      selectedColor === '#ffffff' || 
                      loading
                    }
                    className={`visualization-button ${
                      (!selectedLocation || !selectedTime || !selectedScene || !selectedDecor|| !selectedStyle || !selectedColor || selectedColor === '#ffffff') ? 'disabled' : ''
                    }`}
                  >
                    {loading ? 'Generating...' : isGenerationComplete ? 'Regenerate' : 'Generate Visualization'}
                  </button>
                  
                  {loading && (
                    <div className="progress-container">
                      <div className="progress-bar">
                        <div className="progress" style={{ width: `${progress}%` }}></div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
  
            {/* Canvas Section - Updated with proper container */}
            <div className="canvas-container">
              <ProposalsCanvas 
                progress={progress}
                loading={loading}
                setLoading={setLoading}
                generateVisualizations={generateVisualizations}
                isGenerationComplete={isGenerationComplete}
                proposalVisualizationUrls={proposalVisualizationUrls}
                proposalPrompt={proposalPrompt}
              />
            </div>
        </div>
        
    
        {showAddInput.location && (
          <div className="add-theme-overlay">
            <div className="add-theme-input">
              <h4>What other location should we add?</h4>
              <input 
                type="text" 
                value={newInput.location}
                onChange={(e) => handleInputChange(e, 'location')}
                placeholder="Suggest new location..."
                autoFocus
              />
              <div className="add-theme-buttons">
                <button onClick={() => setShowAddInput(prev => ({ ...prev, location: false }))}>Cancel</button>
                <button onClick={() => handleNewInputSubmit('location')}>Submit</button>
              </div>
            </div>
          </div>
        )}
  
        {showAddInput.time && (
          <div className="add-theme-overlay">
            <div className="add-theme-input">
              <h4>What other time should we add?</h4>
              <input 
                type="text" 
                value={newInput.time}
                onChange={(e) => handleInputChange(e, 'time')}
                placeholder="Suggest new location..."
                autoFocus
              />
              <div className="add-theme-buttons">
                <button onClick={() => setShowAddInput(prev => ({ ...prev, time: false }))}>Cancel</button>
                <button onClick={() => handleNewInputSubmit('time')}>Submit</button>
              </div>
            </div>
          </div>
        )}
  
        {showAddInput.scene && (
          <div className="add-theme-overlay">
            <div className="add-theme-input">
              <h4>What other scene should we add?</h4>
              <input 
                type="text" 
                value={newInput.scene}
                onChange={(e) => handleInputChange(e, 'scene')}
                placeholder="Suggest new location..."
                autoFocus
              />
              <div className="add-theme-buttons">
                <button onClick={() => setShowAddInput(prev => ({ ...prev, scene: false }))}>Cancel</button>
                <button onClick={() => handleNewInputSubmit('scene')}>Submit</button>
              </div>
            </div>
          </div>
        )}
  
  
        {showAddInput.decor && (
          <div className="add-theme-overlay">
            <div className="add-theme-input">
              <h4>What other decor should we add?</h4>
              <input 
                type="text" 
                value={newInput.decor}
                onChange={(e) => handleInputChange(e, 'decor')}
                placeholder="Suggest new decor..."
                autoFocus
              />
              <div className="add-theme-buttons">
                <button onClick={() => setShowAddInput(prev => ({ ...prev, decor: false }))}>Cancel</button>
                <button onClick={() => handleNewInputSubmit('decor')}>Submit</button>
              </div>
            </div>
          </div>
        )}
  
        {showAddInput.style && (
          <div className="add-theme-overlay">
            <div className="add-theme-input">
              <h4>What style should we add?</h4>
              <input 
                type="text" 
                value={newInput.style}
                onChange={(e) => handleInputChange(e, 'style')}
                placeholder="Suggest new style..."
                autoFocus
              />
              <div className="add-theme-buttons">
                <button onClick={() => setShowAddInput(prev => ({ ...prev, style: false }))}>Cancel</button>
                <button onClick={() => handleNewInputSubmit('style')}>Submit</button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  
  export default Proposals;