const colorMap = {
    '#FF0000': 'red',
    '#00FF00': 'green',
    '#0000FF': 'blue',
    '#FFFF00': 'yellow',
    '#FF00FF': 'magenta',
    '#00FFFF': 'cyan',
    '#800000': 'maroon',
    '#808000': 'olive',
    '#008000': 'dark green',
    '#800080': 'purple',
    '#008080': 'teal',
    '#000080': 'navy',
    '#FFA500': 'orange',
    '#FFC0CB': 'pink',
    '#A52A2A': 'brown',
    '#808080': 'gray',
    '#FFFFFF': 'white',
    '#000000': 'black'
  };
  
  // (TODO) Look into nearest-color package for this
  function getClosestColor(hexColor) {
    let closestColor = null;
    let closestDistance = Infinity;
  
    for (const [hex, name] of Object.entries(colorMap)) {
      const distance = calculateColorDistance(hexColor, hex);
      if (distance < closestDistance) {
        closestColor = name;
        closestDistance = distance;
      }
    }
  
    return closestColor;
  }
  
  function calculateColorDistance(hex1, hex2) {
    const r1 = parseInt(hex1.slice(1, 3), 16);
    const g1 = parseInt(hex1.slice(3, 5), 16);
    const b1 = parseInt(hex1.slice(5, 7), 16);
  
    const r2 = parseInt(hex2.slice(1, 3), 16);
    const g2 = parseInt(hex2.slice(3, 5), 16);
    const b2 = parseInt(hex2.slice(5, 7), 16);
  
    return Math.sqrt(
      Math.pow(r1 - r2, 2) +
      Math.pow(g1 - g2, 2) +
      Math.pow(b1 - b2, 2)
    );
  }
  
  export { getClosestColor };