import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate, Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { DesignProvider } from './hooks/DesignContext';
import Home from './pages/landingPage/Home.js';
import Design from './pages/design/decor/Design.js';
import Explore from './pages/explore/Explore.js';
import LoginPage from './pages/login/LoginPage.js';
import UserProfile from './pages/profile/UserProfile.js';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy.jsx';
import TermsOfService from './pages/termsOfService/TermsOfService.jsx';
import PricingPlan from './pages/pricingPlan/PricingPlan.jsx';
import './App.css';
import { UserCheck } from 'lucide-react';
import DesignOptions from './pages/design/main/DesignOptions.js';
import Florals from './pages/design/floral/Florals.js';
import WeddingCakes from './pages/design/weddingCake/WeddingCakes.js';
import { generateAuthToken } from './utils/encryptExternalID';
import PaymentPortal from './components/Payment/PaymentPortal.js';
import Proposals from './pages/design/proposal/Proposals.js';
import WeddingAttire from './pages/design/attire/WeddingAttire.js';

const App = () => {
  const [user, setUser] = useState(null);
  const [userType, setUserType] = useState(null);
  const [credits, setCredits] = useState(null);
  const [showCreditsModal, setShowCreditsModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const navigate = useNavigate();
 

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();
    
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        fetchCredits(currentUser);
        try {
          const userDocRef = doc(db, "users", currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setUserType(userData.userType);
          } else {
            setUserType(null);
          }
        } catch (err) {
          setError("Failed to fetch user data");
          setUserType(null);
        }
      } else {
        setUser(null);
        setUserType(null);
        setCredits(null);
      }
      setLoading(false);
    });
    
    return () => unsubscribe();
  }, []);

  const handleAuthAction = () => {
    if (user) {
      const auth = getAuth();
      signOut(auth).then(() => {
        navigate('/');
        setMenuOpen(false);
      }).catch(() => {
        setError("Failed to log out");
      });
    } else {
      navigate('/login');
      setMenuOpen(false);
    }
  };

  const getDisplayName = () => {
    if (user) {
      return userType === 'couple' ? 'Couple' : 'Vendor';
    }
    return '';
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const NavigationLinks = ({ isMobile }) => (
    <>
      <li><Link to="/" className="nav-link" onClick={() => setMenuOpen(false)}>Home</Link></li>
      {user && (
        <>
          <li><Link to="/design" className="nav-link" onClick={() => setMenuOpen(false)}>Design</Link></li>
          <li><Link to="/explore" className="nav-link" onClick={() => setMenuOpen(false)}>Explore</Link></li>
          <li>
            <Link to="/user-profile" className="nav-link profile-link" onClick={() => setMenuOpen(false)}>
              <div className="profile-icon">
                <UserCheck size={32} />
              </div>
              Profile
            </Link>
          </li>
        </>
      )}
      {!isMobile && (
        <li>
          <button onClick={handleAuthAction} className="auth-button">
            {user ? 'Logout' : 'Sign Up'}
          </button>
        </li>
      )}
    </>
  );

  const fetchCredits = async (currentUser) => {
    if (!currentUser) return;
    
    try {
      const token = await generateAuthToken();
      const response = await fetch(
        `https://wedify.live/api/login/generation/credits/left?authService=firebase&externalClientId=${currentUser.uid}`,
        {
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'X-Auth-Token': token
          }
        }
      );

      if (response.ok) {
        const creditsData = await response.text();
        setCredits(Number(creditsData));
      }
    } catch (error) {
      console.error('Error fetching credits:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <DesignProvider>
      <div className="app">  
        <div className="app-container">
          <header className="header">
            <Link to="/" className="title-link">
              <h1 className="title">
                <span className="title-w">W</span>edify
              </h1>
            </Link>
            {isMobile ? (
              <>
                {credits !== null && (
                  <div 
                    className="mobile-credits-display"
                    onClick={() => setShowCreditsModal(true)}
                  >
                    <svg className="coin-icon" viewBox="0 0 24 24" width="20" height="20">
                      <circle 
                        cx="12" 
                        cy="12" 
                        r="10" 
                        fill="none" 
                        stroke="black" 
                        strokeWidth="1.5"
                      />
                      <text 
                        x="12" 
                        y="16" 
                        textAnchor="middle" 
                        fill="black" 
                        fontSize="12" 
                        fontWeight="bold"
                      >
                        $
                      </text>
                    </svg>
                    <span>{credits}</span>
                  </div>
                )}
                <button className={`menu-toggle ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                  <div className="hamburger-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </button>
              </>
            ) : (
              <nav className="nav">
                <ul className="nav-list">
                  <NavigationLinks isMobile={false} />
                </ul>
              </nav>
            )}
          </header>
          
          {isMobile && (
            <nav className={`nav mobile ${menuOpen ? 'open' : ''}`}>
              <div className="empty-space" /> {/* Empty space using CSS class */}
              {/* <button className="close-menu" onClick={toggleMenu}>×</button> */}
              <ul className="nav-list">
                <NavigationLinks isMobile={true} />
              </ul>
              <button onClick={handleAuthAction} className="auth-button mobile">
                {user ? 'Logout' : 'Sign Up'}
              </button>
            </nav>
          )}
          
          <PaymentPortal 
            isOpen={showCreditsModal}
            onClose={() => setShowCreditsModal(false)}
            user={user}
            fetchCredits={() => fetchCredits(user)}
            credits={credits}
          />
          
          <main className="main-content">
            <div className="scrollable-content">
              {error && <div style={{color: 'red'}}>{error}</div>}
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/design" element={user ? <DesignOptions /> : <Navigate to="/login" />} />
                <Route path="/design/decor" element={
                  user ? 
                    <Design 
                      userType={userType} 
                      credits={credits}
                      setCredits={setCredits}
                      showCreditsModal={showCreditsModal}
                      setShowCreditsModal={setShowCreditsModal}
                    /> : 
                    <Navigate to="/login" />
                } />
                <Route path="/design/bouquet" element={user ? <Design userType={userType} /> : <Navigate to="/login" />} />
                <Route path="/design/proposal" element={user ? <Design userType={userType} /> : <Navigate to="/login" />} />
                <Route path="/design/florals" element={user ? <Florals userType={userType} /> : <Navigate to="/login" />} />
                <Route path="/design/weddingcake" element={<WeddingCakes />} />
                <Route path="/design/proposals" element={<Proposals />} />
                <Route path="/design/attire" element={user ? <WeddingAttire /> : <Navigate to="/login" />} />
                <Route path="/explore" element={user ? <Explore /> : <Navigate to="/login" />} />
                <Route path="/user-profile" element={user ? <UserProfile user={user} userType={userType} /> : <Navigate to="/login" />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/pricing-plan" element={<PricingPlan />} />
              </Routes>
            </div>
          </main>
        </div>
      </div>
    </DesignProvider>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
