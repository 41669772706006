import React, { useState, useEffect, useCallback } from 'react';
import { CircleUserRound, Bookmark, Heart, Trash2 } from 'lucide-react';
import Masonry from 'react-masonry-css';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import './UserProfile.css';
import Spinner from '../../components/Spinner';
import ImagePopup from '../explore/components/ImagePopup';
import { generateAuthToken } from '../../utils/encryptExternalID';

const UserProfile = () => {
  const [activeTab, setActiveTab] = useState('generated');
  const [userData, setUserData] = useState(null);
  const [bookmarks, setBookmarks] = useState([]);
  const [isContentLoading, setIsContentLoading] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshTimestamp, setRefreshTimestamp] = useState(Date.now());
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMoreImages, setHasMoreImages] = useState(true);
  const [likedImages, setLikedImages] = useState([]);
  const [likedImagesPage, setLikedImagesPage] = useState(0);
  const [hasMoreLikedImages, setHasMoreLikedImages] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1
  };

  const generateToken = useCallback(async () => {
    try {
      const currentUtcTime = new Date().toISOString();
      const token = await generateAuthToken(currentUtcTime);
     
      return token;
    } catch (error) {
      console.error("Error generating auth token:", error);
      setError(error.message);
      return null;
    }
  }, []);

  // Fetch user profile data only once on component mount
  useEffect(() => {
    const fetchUserProfile = async () => {
      const auth = getAuth();
      const db = getFirestore();
      
      if (auth.currentUser) {
        try {
          const userDocRef = doc(db, "users", auth.currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setUserData(userData);
          }
        } catch (error) {
          console.error("Error fetching user profile:", error);
          setError(error.message);
        } finally {
          setIsInitialLoading(false);
        }
      }
    };

    fetchUserProfile();
  }, []);

  const fetchUserData = useCallback(async () => {
    setIsContentLoading(true);
    setError(null);
    const auth = getAuth();
    
    try {
      const token = await generateToken();
      if (!token) {
        throw new Error('Failed to generate authentication token');
      }

      const response = await axios.get(`https://wedify.live/api/images/get/pagination/external/client/id`, {
        params: {
          externalClientId: auth.currentUser.uid,
          authenticationService: 'firebase',
          page: currentPage
        },
        headers: {
          'X-Auth-Token': token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      if (Array.isArray(response.data)) {
        setBookmarks(prevBookmarks => currentPage === 0 ? response.data : [...prevBookmarks, ...response.data]);
        setHasMoreImages(response.data.length > 0);
      } else {
        console.error("Received data structure is unexpected:", response.data);
        setBookmarks([]);
        setHasMoreImages(false);
      }
    } catch (error) {
      console.error("Error fetching bookmarks:", error);
      setError(error.message);
    } finally {
      setIsContentLoading(false);
    }
  }, [currentPage, generateToken]);

  const fetchLikedImages = useCallback(async () => {
    setIsContentLoading(true);
    setError(null);
    const auth = getAuth();
    
    try {
      const token = await generateToken();
      if (!token) {
        throw new Error('Failed to generate authentication token');
      }

      const response = await axios.get(`https://wedify.live/api/favorites/get/image/external`, {
        params: {
          authenticationService: 'firebase',
          externalClientId: auth.currentUser.uid,
          page: likedImagesPage
        },
        headers: {
          'X-Auth-Token': token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      if (Array.isArray(response.data)) {
        setLikedImages(prevLikedImages => likedImagesPage === 0 ? response.data : [...prevLikedImages, ...response.data]);
        setHasMoreLikedImages(response.data.length > 0);
      } else {
        console.error("Received data structure is unexpected:", response.data);
        setLikedImages([]);
        setHasMoreLikedImages(false);
      }
    } catch (error) {
      console.error("Error fetching liked images:", error);
      setError(error.message);
    } finally {
      setIsContentLoading(false);
    }
  }, [likedImagesPage, generateToken]);

  const loadMoreImages = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const loadMoreLikedImages = () => {
    setLikedImagesPage(prevPage => prevPage + 1);
  };

  useEffect(() => {
    if (activeTab === 'generated') {
      fetchUserData();
    } else if (activeTab === 'likes') {
      fetchLikedImages();
    }
  }, [fetchUserData, fetchLikedImages, activeTab, refreshTimestamp]);

  const handleDeleteGeneratedImage = async (imageId) => {
    if (!window.confirm('Are you sure you want to delete this image?')) {
      return;
    }

    try {
      const token = await generateToken();
      if (!token) {
        throw new Error('Failed to generate authentication token');
      }

      const response = await axios.delete(`https://wedify.live/api/images/delete/image/${imageId}`, {
        headers: { 
          'X-Auth-Token': token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        setBookmarks(prevBookmarks => prevBookmarks.filter(bookmark => bookmark.id !== imageId));
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error deleting image:', error);
      alert(`Failed to delete the image. Error: ${error.message}`);
    }
  };

  const handleDeleteLikedImage = async (imageId) => {
    if (!window.confirm('Are you sure you want to remove this liked image?')) {
      return;
    }

    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.error('User not authenticated');
      return;
    }

    try {
      const token = await generateToken();
      if (!token) {
        throw new Error('Failed to generate authentication token');
      }

      const response = await axios.delete(`https://wedify.live/api/favorites/delete/external`, {
        params: {
          externalClientId: user.uid,
          imageId: imageId,
          authenticationService: 'firebase'
        },
        headers: { 
          'X-Auth-Token': token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        setLikedImages(prevLikedImages => prevLikedImages.filter(image => image.id !== imageId));

      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error removing liked image:', error);
    }
  };

  const extractHashtags = (selectedOptions) => {
    if (!selectedOptions) {
      console.warn('selectedOptions is null or undefined:', selectedOptions);
      return [];
    }

    let metadataValuesList;

    if (typeof selectedOptions === 'string') {
      try {
        const parsedOptions = JSON.parse(selectedOptions);
        metadataValuesList = parsedOptions.metadataValuesList;
      } catch (error) {
        console.error('Error parsing selectedOptions:', error);
        return [];
      }
    } else if (typeof selectedOptions === 'object') {
      metadataValuesList = selectedOptions.metadataValuesList;
    }

    if (!Array.isArray(metadataValuesList)) {
      console.warn('metadataValuesList is not an array:', metadataValuesList);
      return [];
    }
    
    const relevantKeys = [
        'theme', 
        'color', 
        'flowers', 
        'seating', 
        'venue',
        'greenery',
        'size',
        'shape',
        'flavor',
        'decoration',
        // Suit-related keys
        'fit', 
        'lapel', 
        'material', 
        'pattern', 
        'jacket',
        // Dress-related keys
        'style', 
        'neckline', 
        'fabric', 
        'length', 
        'details',
        // Proposal-related keys
        'location', 
        'scene', 
        'decor', 
        'style'
    ];
    return metadataValuesList
      .filter(option => relevantKeys.includes(option.key))
      .map(option => {
        const capitalizedValue = option.value
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join('');
        const cleanedValue = option.key === 'seating' ? capitalizedValue.replace(/\(|\)/g, '') : capitalizedValue;
        return `#${cleanedValue.replace(/\s+/g, '')}`;
      });
  };

  const renderBookmarkImage = (image, isLiked = false) => {
    const hashtags = extractHashtags(image.selectedOptions);
    
    return (
      <div key={image.id} className="grid-item">
        {image.s3Url ? (
          <img 
            src={image.s3Url} 
            alt={`Image ${image.id}`}
            onClick={() => setSelectedImage(image)}
            onError={(e) => {
              console.error(`Failed to load image: ${image.s3Url}`);
              e.target.style.display = 'none';
            }}
          />
        ) : (
          <div className="image-placeholder">Image not available</div>
        )}
        <div className="item-overlay">
          <div className="item-details">
            <div className="hashtags">
              {hashtags.length > 0 ? (
                hashtags.map((tag, index) => (
                  <span key={index} className="hashtag">{tag}</span>
                ))
              ) : (
                <span className="hashtag">#NoTagsAvailable</span>
              )}
            </div>
            <p>Created: {new Date(image.createdAt).toLocaleDateString()}</p>
          </div>
          <button 
            className="delete-button" 
            onClick={() => isLiked ? handleDeleteLikedImage(image.id) : handleDeleteGeneratedImage(image.id)}
          >
            <Trash2 size={16} />
          </button>
        </div>
      </div>
    );
  };

  if (isInitialLoading) {
    return <Spinner text="Loading Profile..." />;
  }

  if (error && !userData) {
    return <div className="error">Error: {error}</div>;
  }

  if (!userData) {
    return <div className="not-found">User not found</div>;
  }

  return (
    <div className="profile-container">
      <div className="profile-header">
        <div className="profile-info">
          <div className="profile-avatar">
            <CircleUserRound size={40} className="avatar-icon" />
          </div>
          <div className="profile-details">
            <h1>{userData.name}</h1>
            <p className="email">{userData.email}</p>
            <p className="user-type">{userData.userType === 'vendor' ? 'Vendor' : 'Couple'}</p>
            <p className="bio">{userData.bio}</p>
            {userData.userType === 'vendor' && (
              <>
                <p><strong>Company Name:</strong> {userData.companyName}</p>
                <p><strong>Location:</strong> {userData.locationName}</p> {/* Changed from location to locationName */}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="tabs">
        <button
          className={`tab ${activeTab === 'generated' ? 'active' : ''}`}
          onClick={() => setActiveTab('generated')}
        >
          <Bookmark className="tab-icon" size={20} />
          Created By You
        </button>
        <button
          className={`tab ${activeTab === 'likes' ? 'active' : ''}`}
          onClick={() => setActiveTab('likes')}
        >
          <Heart className="tab-icon heart-icon" size={20} />
          Likes
        </button>
      </div>

      <div className="content-area">
        {isContentLoading ? (
          <div className="content-spinner">
            <Spinner text="Loading content..." />
          </div>
        ) : (
          <>
            {activeTab === 'generated' && (
              <>
                <Masonry
                  key={bookmarks.length}
                  breakpointCols={breakpointColumnsObj}
                  className="masonry-grid"
                  columnClassName="masonry-grid_column"
                >
                  {bookmarks.map((bookmark) => renderBookmarkImage(bookmark, false))}
                </Masonry>
                {hasMoreImages && (
                  <button className="load-more-button" onClick={loadMoreImages}>
                    Load More
                  </button>
                )}
              </>
            )}

            {activeTab === 'likes' && (
              <>
                <Masonry
                  key={likedImages.length}
                  breakpointCols={breakpointColumnsObj}
                  className="masonry-grid"
                  columnClassName="masonry-grid_column"
                >
                  {likedImages.map((image) => renderBookmarkImage(image, true))}
                </Masonry>
                {hasMoreLikedImages && (
                  <button className="load-more-button" onClick={loadMoreLikedImages}>
                    Load More Liked Images
                  </button>
                )}
              </>
            )}
          </>
        )}
      </div>

      {showPopup && selectedImage && (
        <ImagePopup
          image={selectedImage}
          onClose={() => {
            setShowPopup(false);
            setSelectedImage(null);
          }}
        />
      )}
    </div>
  );
};

export default UserProfile;
