import React, { useState, useEffect, useCallback, useRef } from 'react';
import ColorPicker from '../../../components/ColorPicker';
import { getClosestColor } from '../../../components/colorMapper';
import { useDesign } from '../../../hooks/DesignContext';
import sendToDiscord from '../../../utils/discordWebhook';
import '../Page.css';
import '../decor/Design.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { getAuth } from 'firebase/auth';
import CryptoJS from 'crypto-js';
import { generateAuthToken } from '../../../utils/encryptExternalID';
import getImageUrl from '../../../utils/imageUtils';
import FloralsCanvas from './FloralsCanvas';

import './Florals.css';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const SECRET_KEY = process.env.REACT_APP_WEDIFY_APP_SECRET_KEY;

const encryptPrompt = (plaintext, key) => {
  try {
    const keyBytes = CryptoJS.enc.Hex.parse(key);
    const encrypted = CryptoJS.AES.encrypt(plaintext, keyBytes, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  } catch (error) {
    return null;
  }
};

const Florals = () => {
  const { 
    selectedGreenery, setSelectedGreenery,
    selectedFlower, setSelectedFlower,
    selectedColor, setSelectedColor,
    bouquetVisualizationUrls, setBouquetVisualizationUrls,
    boutonniereVisualizationUrls, setBoutonniereVisualizationUrls,
    bouquetPrompt, setBouquetPrompt,
    boutonnierePrompt, setBoutonnierePrompt,
    isGenerationComplete, setIsGenerationComplete,
    loading, setLoading,
    progress, setProgress
  } = useDesign();

  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showAddInput, setShowAddInput] = useState({ greenery: false, flower: false });
  const [newInput, setNewInput] = useState({ greenery: '', flower: '' });
  const [currentStep, setCurrentStep] = useState(1);

  // Options for selections
  const greeneryOptions = [
    { name: 'Eucalyptus', image: getImageUrl('leaves/Eucalyptus.jpg') },
    { name: 'Ruscus', image: getImageUrl('leaves/Ruscus.jpg') },
    { name: 'Dusty Miller', image: getImageUrl('leaves/DustyMiller.jpg') },
    { name: 'Salal Lemon Leaf', image: getImageUrl('leaves/SalalLemonLeaf.jpg') },
    { name: 'Leaf Fern', image: getImageUrl('leaves/LeafFern.jpg') },
    { name: 'Olive Branches', image: getImageUrl('leaves/OliveBranches.jpg') },
    { name: 'Myrtle', image: getImageUrl('leaves/Myrtle.jpg') },
    { name: 'Ivy', image: getImageUrl('leaves/Ivy.jpg') },
    { name: 'Aspidistra', image: getImageUrl('leaves/Aspidistra.jpg') },
    { name: 'Add', image: getImageUrl('themesOption/add-plus.jpg') }
  ];

  const flowerOptions = [
    { name: 'Wisteria', image: getImageUrl('wisteria.jpg') },
    { name: 'Hydrangeas', image: getImageUrl('hydrangea.jpg') },
    { name: 'Roses', image: getImageUrl('roses.jpg') },
    { name: 'Tulips', image: getImageUrl('tulips.jpg') },
    { name: "Baby's Breath", image: getImageUrl('BabysBreath.jpg') },
    { name: 'Orchids', image: getImageUrl('Orchids.jpg') },
    { name: 'Marigolds', image: getImageUrl('Marigolds.jpg') },
    { name: 'Lilies', image: getImageUrl('Lilies.jpg') },
    { name: 'Peonies', image: getImageUrl('Peonies.jpg') },
    { name: 'Sunflowers', image: getImageUrl('Sunflowers.jpg') },
    { name: 'Add', image: getImageUrl('themesOption/add-plus.jpg') }
  ];

  const colorPresets = [
    { name: 'Gold', hex: '#dcc282' },
    { name: 'Silver', hex: '#C0C0C0' },
    { name: 'Blush Pink', hex: '#FFB6C1' },
    { name: 'Sage Green', hex: '#9DC183' },
    { name: 'Navy Blue', hex: '#000080' },
    { name: 'Burgundy', hex: '#800020' },
    { name: 'Dusty Blue', hex: '#6699CC' },
    { name: 'Champagne', hex: '#F7E7CE' },
    { name: 'Lavender', hex: '#E6E6FA' },
    { name: 'Pearl White', hex: '#F0EAD6' },
    { name: 'Terracotta', hex: '#E2725B' },
    { name: 'Rust', hex: '#B7410E' },
    { name: 'Gray', hex: '#808080' },
    { name: 'Emerald', hex: '#50C878' },
    { name: 'Coral', hex: '#FF7F50' },
    { name: 'Mint', hex: '#98FF98' },
    { name: 'Peach', hex: '#FFE5B4' },
    { name: 'Custom', image: '/themesOption/colorPicker.png' }
  ];

  // Handlers
  const handleGreeneryChange = (greenery) => {
  
    setSelectedGreenery(greenery);
  };

  const handleFlowerChange = (flower) => {
   
    setSelectedFlower(flower);
  };

  const handleColorChange = (color) => {
  
    setSelectedColor(color.hex);
  };

  const checkGenerationStatus = async (generationId) => {
    let attempts = 0;
    const maxAttempts = 30;
    const initialDelay = 1000;
    const maxDelay = 10000;

    const poll = async () => {
      if (attempts >= maxAttempts) {
        throw new Error('Generation timed out');
      }

      attempts++;

      try {
        const token = await generateAuthToken();
        const response = await fetch(`${API_BASE_URL}/api/images/get/image/retrieve`, {
          method: 'GET',
          headers: {
            'imageId': generationId,
            'X-Auth-Token': token,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const contentType = response.headers.get('Content-Type');
        const contentLength = response.headers.get('Content-Length');

        if (contentType && contentType.includes('image') && contentLength && parseInt(contentLength) > 0) {
          const blob = await response.blob();
          return URL.createObjectURL(blob);
        } else {
          const delay = Math.min(initialDelay * Math.pow(1.5, attempts), maxDelay);
          await new Promise(resolve => setTimeout(resolve, delay));
          return poll();
        }
      } catch (error) {
        const delay = Math.min(initialDelay * Math.pow(1.5, attempts), maxDelay);
        await new Promise(resolve => setTimeout(resolve, delay));
        return poll();
      }
    };

    return poll();
  };

  const generateVisualizations = async () => {
    if (!selectedGreenery || !selectedFlower || !selectedColor) {
      console.error('Not all required fields are selected');
      return;
    }
  
    setLoading(true);
    setIsGenerationComplete(false);
    setProgress(0);
  
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      
      if (!currentUser) {
        throw new Error('No authenticated user found');
      }
  
      setProgress(10);
      
  
      // Generate auth token
      const token = await generateAuthToken();
      if (!token) {
        throw new Error('Failed to generate authentication token');
      }
  
      const namedColor = getClosestColor(selectedColor);
  
      // Define prompts
      const bouquetPromptText = `A beautiful wedding bouquet with ${selectedGreenery} greenery and ${selectedFlower} flowers in ${namedColor} color scheme, professional photography, high end floral design`;
      const boutonnierePromptText = `A wedding boutonniere with ${selectedGreenery} greenery and ${selectedFlower} flowers in ${namedColor} color scheme, professional photography, high end floral design`;
  
   
      setBouquetPrompt(bouquetPromptText);
      setBoutonnierePrompt(boutonnierePromptText);
  
      // Generate both images in parallel using Promise.all
      const [bouquetUrl, boutonniereUrl] = await Promise.all([
        generateImage(bouquetPromptText, token, currentUser.uid),
        generateImage(boutonnierePromptText, token, currentUser.uid)
      ]);

  
      setBouquetVisualizationUrls([bouquetUrl]);
      setBoutonniereVisualizationUrls([boutonniereUrl]);
  
      setIsGenerationComplete(true);
      setProgress(100);
  
  
      // Remove or comment out the Discord notification line
      // await sendToDiscord(`Generated floral designs for user ${currentUser.uid}`);
  
    } catch (error) {
      console.error('Error generating visualizations:', error);
    } finally {
      setLoading(false);
    }
  };
  
  // Helper function to generate a single image
  const generateImage = async (prompt, token, userId) => {
    try {
     
      const encryptedPrompt = encryptPrompt(prompt, SECRET_KEY);
  
      const generateResponse = await fetch(`${API_BASE_URL}/api/images/get/image/generate`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': token,
          'whatYouLookinAt': encryptedPrompt,
          'authId': userId,
          'authenticationService': 'firebase'
        }
      });
  
      if (!generateResponse.ok) {
        throw new Error(`HTTP error! status: ${generateResponse.status}`);
      }
  
      const generateData = await generateResponse.json();
  
      if (generateData && generateData.sdGenerationJob && generateData.sdGenerationJob.generationId) {
        const generationId = generateData.sdGenerationJob.generationId;
        const imageUrl = await checkGenerationStatus(generationId);
        return imageUrl;
      } else {
        throw new Error('No generationId returned');
      }
    } catch (error) {
      console.error('Error in generateImage:', error);
      throw error;
    }
  };

  // Slider refs and settings
  const greenerySliderRef = useRef(null);
  const flowerSliderRef = useRef(null);
  const greeneryIntervalRef = useRef(null);
  const flowerIntervalRef = useRef(null);

  const sliderSettings = {
    modules: [Navigation, Autoplay, Pagination],
    spaceBetween: 1,
    slidesPerView: 3,
    speed: 200,
    loop: true,
    allowTouchMove: false,
    pagination: {
      clickable: true,
      type: 'bullets',
      dynamicBullets: true,
      dynamicMainBullets: 3
    }
  };

  const renderMobileStep = () => {
    switch(currentStep) {
      case 1:
        return (
          <>
            <div className="step-header">
              <h2>Choose Your Greenery</h2>
            </div>
            <div className="mobile-options-grid">
              {greeneryOptions.map((greenery) => (
                <div 
                  key={greenery.name}
                  onClick={() => greenery.name === 'Add' ? handleAddClick('greenery') : handleGreeneryChange(greenery.name)}
                  className={`mobile-option-item ${selectedGreenery === greenery.name ? 'selected' : ''}`}
                >
                  <img src={greenery.image} alt={greenery.name} />
                  <p>{greenery.name}</p>
                </div>
              ))}
            </div>
            {renderContinueButton(selectedGreenery, 2)}
          </>
        );
      case 2:
        return (
          <>
            <div className="step-header">
              <h2>Select Your Flowers</h2>
            </div>
            <div className="mobile-options-grid">
              {flowerOptions.map((flower) => (
                <div 
                  key={flower.name}
                  onClick={() => flower.name === 'Add' ? handleAddClick('flower') : handleFlowerChange(flower.name)}
                  className={`mobile-option-item ${selectedFlower === flower.name ? 'selected' : ''}`}
                >
                  <img src={flower.image} alt={flower.name} />
                  <p>{flower.name}</p>
                </div>
              ))}
            </div>
            {renderContinueButton(selectedFlower, 3)}
          </>
        );
      case 3:
        return (
          <>
            <div className="step-header">
              <h2>Choose Your Color</h2>
            </div>
            <div className="color-picker-container">
              {!showColorPicker ? (
                <div className="color-presets-container">
                  {colorPresets.map((color) => (
                    <div 
                      key={color.name}
                      onClick={() => color.name === 'Custom' 
                        ? setShowColorPicker(true) 
                        : handleColorChange({ hex: color.hex })}
                      className={`color-preset-item ${selectedColor === color.hex ? 'selected' : ''}`}
                    >
                      {color.name === 'Custom' ? (
                        <>
                          <img src={color.image} alt="Custom color" className="color-wheel-icon" />
                          <p>Custom Color</p>
                        </>
                      ) : (
                        <>
                          <div 
                            className="color-swatch" 
                            style={{ backgroundColor: color.hex }}
                          />
                          <p>{color.name}</p>
                          </>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  <button 
                    onClick={() => setShowColorPicker(false)}
                    className="back-to-presets"
                  >
                    ← Back to Colors
                  </button>
                  <ColorPicker
                    selectedColor={selectedColor} 
                    onColorChange={handleColorChange}
                    className="color-picker"
                  />
                </div>
              )}
            </div>
            {renderContinueButton(selectedColor !== '#ffffff', 4)}
          </>
        );
      case 4:
        return (
          <>
            <FloralsCanvas 
              progress={progress}
              loading={loading}
              setLoading={setLoading}
              generateVisualizations={generateVisualizations}
              isGenerationComplete={isGenerationComplete}
              bouquetVisualizationUrls={bouquetVisualizationUrls}
              boutonniereVisualizationUrls={boutonniereVisualizationUrls}
              bouquetPrompt={bouquetPrompt}
              boutonnierePrompt={boutonnierePrompt}
            />
          </>
        );
      default:
        return null;
    }
  };

  const renderContinueButton = (condition, nextStep) => {
    if (condition) {
      if (nextStep === 4) {
        return (
          <button
            className="continue-button"
            onClick={async () => {
              setCurrentStep(nextStep);
              await generateVisualizations();
            }}
            disabled={loading}
          >
            {loading ? 'Generating...' : 'Generate'}
          </button>
        );
      }
      return (
        <button
          className="continue-button"
          onClick={() => setCurrentStep(nextStep)}
        >
          Continue
        </button>
      );
    }
    return null;
  };

  const renderProgressBar = () => {
    const steps = [
      { num: 1, name: 'Greenery' },
      { num: 2, name: 'Flowers' },
      { num: 3, name: 'Color' },
      { num: 4, name: 'Preview' }
    ];

    return (
      <div className="mobile-progress-bar">
        {steps.map((step) => (
          <div 
            key={step.num}
            className={`step ${currentStep >= step.num ? 'completed' : ''} ${currentStep === step.num ? 'active' : ''}`}
            onClick={() => setCurrentStep(step.num)}
          >
            <div className="step-number">{step.num}</div>
            <div className="step-name">{step.name}</div>
          </div>
        ))}
      </div>
    );
  };

  // Autoplay functions for sliders
  const startAutoplay = (sliderRef, intervalRef, direction) => {
    if (sliderRef.current && sliderRef.current.swiper) {
      const swiper = sliderRef.current.swiper;
      
      if (direction === 'left') {
        swiper.slidePrev();
      } else {
        swiper.slideNext();
      }

      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      intervalRef.current = setInterval(() => {
        if (direction === 'left') {
          swiper.slidePrev();
        } else {
          swiper.slideNext();
        }
      }, 300);
    }
  };

  const stopAutoplay = (intervalRef) => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const handleAddClick = (type) => {
    setShowAddInput(prev => ({ ...prev, [type]: true }));
  };

  const handleInputChange = (e, type) => {
    setNewInput(prev => ({ ...prev, [type]: e.target.value }));
  };

  const handleNewInputSubmit = async (type) => {
    if (!newInput[type].trim()) {
      return;
    }

    // Get current user
    const auth = getAuth();
    const user = auth.currentUser;
    const userEmail = user ? user.email : 'Anonymous';

    // Prepare message for Discord
    const message = {
      username: "Wedify Bot",
      content: `New ${type} suggestion from ${userEmail}`,
      embeds: [{
        title: `New ${type} Suggestion`,
        description: `Suggested ${type}: ${newInput[type]}`,
        fields: [
          {
            name: 'Category',
            value: `Wedding Florals ${type.charAt(0).toUpperCase() + type.slice(1)}`
          },
          {
            name: 'Submitted by',
            value: userEmail
          }
        ],
        color: 3066993 // Green color in decimal
      }]
    };

    try {
      await sendToDiscord(message);
      setNewInput(prev => ({ ...prev, [type]: '' }));
      setShowAddInput(prev => ({ ...prev, [type]: false }));
      alert('Thank you for your suggestion! We will review it shortly.');
    } catch (error) {
      console.error('Error sending suggestion:', error);
      alert('Sorry, there was an error submitting your suggestion. Please try again later.');
    }
  };

  return (
    <div className="florals-design-page">
      {/* Mobile Content */}
      <div className="florals-mobile-content">
        <div className="mobile-design">
          <div className="mobile-content">
            {renderProgressBar()}
            <div className="mobile-step">
              {renderMobileStep()}
            </div>
          </div>
        </div>
      </div>

      {/* Desktop Content */}
      <div className="florals-desktop-content">
        <div className="design-container">
          <h2 className="page-title">Design Your Wedding Florals</h2>

          {/* Greenery Section */}
          <div className="design-section greenery">
            <h3>Select Greenery</h3>
            <div className="slider-container">
              <div 
                className="hover-zone hover-zone-left"
                onMouseEnter={() => startAutoplay(greenerySliderRef, greeneryIntervalRef, 'left')}
                onMouseLeave={() => stopAutoplay(greeneryIntervalRef)}
              />
              <Swiper
                ref={greenerySliderRef}
                {...sliderSettings}
                className="greenery-swiper"
              >
                {greeneryOptions.map((greenery) => (
                  <SwiperSlide key={greenery.name}>
                    <div 
                      onClick={() => greenery.name === 'Add' ? handleAddClick('greenery') : handleGreeneryChange(greenery.name)}
                      className={`carousel-item ${selectedGreenery === greenery.name ? 'selected' : ''}`}
                    >
                      <img src={greenery.image} alt={greenery.name} className="carousel-image" />
                      <p>{greenery.name}</p>
                    </div>
                  </SwiperSlide>
                ))}
                <div className="swiper-pagination greenery-pagination"></div>
              </Swiper>
              <div 
                className="hover-zone hover-zone-right"
                onMouseEnter={() => startAutoplay(greenerySliderRef, greeneryIntervalRef, 'right')}
                onMouseLeave={() => stopAutoplay(greeneryIntervalRef)}
              />
            </div>
          </div>

          {/* Flower Section */}
          <div className="design-section flowers">
            <h3>Select Flowers</h3>
            <div className="slider-container">
            <div 
                className="hover-zone hover-zone-left"
                onMouseEnter={() => startAutoplay(flowerSliderRef, flowerIntervalRef, 'left')}
                onMouseLeave={() => stopAutoplay(flowerIntervalRef)}
              />
              <Swiper
                ref={flowerSliderRef}
                {...sliderSettings}
                className="flower-swiper"
              >
                {flowerOptions.map((flower) => (
                  <SwiperSlide key={flower.name}>
                    <div 
                      onClick={() => flower.name === 'Add' ? handleAddClick('flower') : handleFlowerChange(flower.name)}
                      className={`carousel-item ${selectedFlower === flower.name ? 'selected' : ''}`}
                    >
                      <img src={flower.image} alt={flower.name} className="carousel-image" />
                      <p>{flower.name}</p>
                    </div>
                  </SwiperSlide>
                ))}
                <div className="swiper-pagination flower-pagination"></div>
              </Swiper>
              <div 
                className="hover-zone hover-zone-right"
                onMouseEnter={() => startAutoplay(flowerSliderRef, flowerIntervalRef, 'right')}
                onMouseLeave={() => stopAutoplay(flowerIntervalRef)}
              />
            </div>
          </div>

          {/* Color Section */}
          <div className="design-section colors">
            <h3>Select Color</h3>
            {!showColorPicker ? (
              <div className="color-presets-container">
                {colorPresets.map((color) => (
                  <div 
                    key={color.name}
                    onClick={() => color.name === 'Custom' 
                      ? setShowColorPicker(true) 
                      : handleColorChange({ hex: color.hex })}
                    className={`color-preset-item ${selectedColor === color.hex ? 'selected' : ''}`}
                  >
                    {color.name === 'Custom' ? (
                      <>
                        <img src={color.image} alt="Custom color" className="color-wheel-icon" />
                        <p>Custom Color</p>
                      </>
                    ) : (
                      <>
                        <div 
                          className="color-swatch" 
                          style={{ backgroundColor: color.hex }}
                        />
                        <p>{color.name}</p>
                      </>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="color-picker-container">
                <button 
                  onClick={() => setShowColorPicker(false)}
                  className="back-to-presets-btn"
                >
                  ← Back to Presets
                </button>
                <ColorPicker 
                  selectedColor={selectedColor} 
                  onColorChange={handleColorChange} 
                />
              </div>
            )}
          </div>

          {/* Generate Button Section */}
          <div className="design-section generate">
            <div className="visualization-container">
              <button
                onClick={generateVisualizations}
                disabled={
                  !selectedGreenery || 
                  !selectedFlower || 
                  !selectedColor || 
                  selectedColor === '#ffffff' || 
                  loading
                }
                className={`visualization-button ${
                  (!selectedGreenery || !selectedFlower || !selectedColor || selectedColor === '#ffffff') ? 'disabled' : ''
                }`}
              >
                {loading ? 'Generating...' : isGenerationComplete ? 'Regenerate' : 'Generate Visualizations'}
              </button>
              
              {loading && (
                <div className="progress-container">
                  <div className="progress-bar">
                    <div className="progress" style={{ width: `${progress}%` }}></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Canvas Section */}
        <div className="canvas-container">
          <FloralsCanvas 
            progress={progress}
            loading={loading}
            setLoading={setLoading}
            generateVisualizations={generateVisualizations}
            isGenerationComplete={isGenerationComplete}
            bouquetVisualizationUrls={bouquetVisualizationUrls}
            boutonniereVisualizationUrls={boutonniereVisualizationUrls}
            bouquetPrompt={bouquetPrompt}
            boutonnierePrompt={boutonnierePrompt}
          />
        </div>
      </div>

      {/* Add Input Overlays */}
      {showAddInput.greenery && (
        <div className="add-theme-overlay">
          <div className="add-theme-input">
            <h4>What other greenery should we add?</h4>
            <input 
              type="text" 
              value={newInput.greenery}
              onChange={(e) => handleInputChange(e, 'greenery')}
              placeholder="Suggest new greenery..."
              autoFocus
            />
            <div className="add-theme-buttons">
              <button onClick={() => setShowAddInput(prev => ({ ...prev, greenery: false }))}>Cancel</button>
              <button onClick={() => handleNewInputSubmit('greenery')}>Submit</button>
            </div>
          </div>
        </div>
      )}

      {showAddInput.flower && (
        <div className="add-theme-overlay">
          <div className="add-theme-input">
            <h4>What other flower should we add?</h4>
            <input 
              type="text" 
              value={newInput.flower}
              onChange={(e) => handleInputChange(e, 'flower')}
              placeholder="Suggest new flower..."
              autoFocus
            />
            <div className="add-theme-buttons">
              <button onClick={() => setShowAddInput(prev => ({ ...prev, flower: false }))}>Cancel</button>
              <button onClick={() => handleNewInputSubmit('flower')}>Submit</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Florals;