import React, { useState, useEffect, useCallback } from 'react';
import Slider from 'react-slick';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useDesign } from '../../../hooks/DesignContext';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../design/decor/Canvas.css';
import './WeddingCakesCanvas.css';
import { getClosestColor } from '../../../components/colorMapper';
import Spinner from '../../../components/Spinner.js';
import { generateAuthToken } from '../../../utils/encryptExternalID.js';
import { Circle } from 'lucide-react';
import PaymentPortal from '../../../components/Payment/PaymentPortal.js';
import './WeddingCakes.css'

const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};

const getColorInfo = (hexColor) => {
  const rgb = hexToRgb(hexColor);
  const namedColor = getClosestColor(hexColor);
  return {
    namedColor,
    rgb
  };
};

const WeddingCakesCanvas = ({ 
  progress, 
  loading, 
  setLoading,
  generateVisualizations,
  isGenerationComplete,
  cakeVisualizationUrls,
  cakePrompt
}) => {
  const {
    selectedSize,
    selectedTier,
    selectedShape,
    selectedFlavor,
    selectedDecoration,
    selectedColor,
  } = useDesign();

  const [bookmarkedImages, setBookmarkedImages] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [user, setUser] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [credits, setCredits] = useState(null);
  const [showCreditsModal, setShowCreditsModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const generateToken = useCallback(async () => {
    try {
      const token = await generateAuthToken();
      return token;
    } catch (error) {
      return null;
    }
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        fetchCredits(currentUser);
      } else {
        setCredits(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleImageError = (url) => {
    console.log(`Failed to load image: ${url}`);
  };

  const handleBookmark = async (imageUrl, prompt) => {
    setIsUploading(true);
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      
      const formData = new FormData();
      formData.append('file', blob, 'cake-design.jpg');

      const colorInfo = getColorInfo(selectedColor);
      const capitalizedColor = colorInfo.namedColor.charAt(0).toUpperCase() + colorInfo.namedColor.slice(1);

      const metadataValuesList = [
        { key: "size", value: selectedSize },
        { key: "shape", value: selectedShape },
        { key: "flavor", value: selectedFlavor },
        { key: "decoration", value: selectedDecoration }
      ];

      const selectedOptions = JSON.stringify({ metadataValuesList });

      const token = await generateToken();
      if (!token) {
        throw new Error('Failed to generate authentication token');
      }

      const headers = {
        'contentType': 'jpeg',
        'authService': 'firebase',
        'externalClientId': user.uid,
        'prompt': prompt,
        'explorePage': 'true',
        'cwRed': colorInfo.rgb.r.toString(),
        'cwGreen': colorInfo.rgb.g.toString(),
        'cwBlue': colorInfo.rgb.b.toString(),
        'bookmarked': 'false',
        'selectedOptions': selectedOptions,
        'designCategory': 'wedding cakes',
        'X-Auth-Token': token
      };

      const uploadResponse = await fetch('https://wedify.live/api/images/upload/auth/id', {
        method: 'POST',
        headers: headers,
        body: formData
      });

      if (!uploadResponse.ok) {
        const errorText = await uploadResponse.text();
        throw new Error(`HTTP error! status: ${uploadResponse.status}, message: ${errorText}`);
      }

      setBookmarkedImages(prev => ({
        ...prev,
        [imageUrl]: { bookmarked: true }
      }));

      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 3000);

    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setIsUploading(false);
    }
  };
  
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
  };

  const renderCombinedCarousel = () => {
    return (
      <div className="cake-image-container">
        <div className="image-wrapper">
          <img
            src={cakeVisualizationUrls[0]}
            alt="Generated cake design"
            className="generated-image"
            onError={() => handleImageError(cakeVisualizationUrls[0])}
          />
          <button
            className={`save-button ${bookmarkedImages[cakeVisualizationUrls[0]] ? 'saved' : ''}`}
            onClick={() => handleBookmark(cakeVisualizationUrls[0], cakePrompt)}
            disabled={isUploading}
          >
            {bookmarkedImages[cakeVisualizationUrls[0]] ? 'Saved' : 'Save'}
          </button>
        </div>
      </div>
    );
  };

  const renderMobileStacked = () => (
    <div className="mobile-stacked-images">
      {cakeVisualizationUrls.map((url, index) => (
        <div key={index} className="mobile-image-item">
          <div className="image-label">Wedding Cake</div>
          <img 
            src={url}
            alt={`Generated cake visualization ${index + 1}`}
            className="generated-image"
            onError={() => handleImageError(url)}
          />
          <button 
            className={`save-button ${bookmarkedImages[url]?.bookmarked ? 'saved' : ''}`}
            onClick={() => handleBookmark(url, 'Wedding Cake', cakePrompt)}
            disabled={bookmarkedImages[url]?.bookmarked}
          >
            {bookmarkedImages[url]?.bookmarked ? 'Saved' : 'Save'}
          </button>
        </div>
      ))}
    </div>
  );

  const renderProgressCircle = () => {
    
    const steps = [
      { name: 'Size', isComplete: !!selectedSize },
      { name: 'Tier', isComplete: !!selectedTier },
      { name: 'Shape', isComplete: !!selectedShape },
      { name: 'Flavor', isComplete: !!selectedFlavor },
      { name: 'Decoration', isComplete: !!selectedDecoration},
      { name: 'Color', isComplete: !!(selectedColor && selectedColor !== '#ffffff') }
    ];
    
    return (
      <div className="progress-circle-container">
        <div className="steps-progress">
          {steps.map((step, index) => (
            <div 
              key={step.name} 
              className={`step-indicator ${step.isComplete ? 'completed' : ''}`}
            >
              <Circle 
                size={24} 
                className={`circle ${step.isComplete ? 'filled' : ''}`} 
              />
              <span className="step-label">{step.name}</span>
            </div>
          ))}
        </div>
        <div className="progress-text">
          {steps.filter(step => step.isComplete).length} of {steps.length} steps completed
        </div>
      </div>
    );
  };

  const handleVisualizationClick = async () => {
    if (!selectedSize || !selectedTier || !selectedShape || !selectedFlavor || !selectedDecoration || !selectedColor) {
      alert('Please complete all cake design options before generating');
      return;
    }

    if (credits <= 0) {
      setShowCreditsModal(true);
      return;
    }

    try {
      await generateVisualizations();
      // Fetch credits after generation is complete
      const auth = getAuth();
      const currentUser = auth.currentUser;
      if (currentUser) {
        await fetchCredits(currentUser);
      }
    } catch (error) {
      console.error('Error during visualization process:', error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchCredits = async (currentUser) => {
    if (!currentUser) return;
    
    try {
      const token = await generateAuthToken();
      if (!token) {
        console.log('No auth token generated');
        return;
      }

      if (!process.env.REACT_APP_API_BASE_URL) {
        console.log('API base URL not configured');
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/login/generation/credits/left?authService=firebase&externalClientId=${currentUser.uid}`,
        {
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'X-Auth-Token': token
          }
        }
      ).catch(error => {
        console.log('Network error:', error);
        return null;
      });

      if (response && response.ok) {
        const creditsData = await response.text();
        setCredits(Number(creditsData));
      }
    } catch (error) {
      console.log('Error in fetchCredits:', error);
    }
  };

  return (
    <div className="wedding-cakes-canvas">
      {!isMobile && credits !== null && (
        <button 
          className="credits-display desktop-only"
          onClick={() => setShowCreditsModal(true)}
        >
          <span>{credits} credits</span>
        </button>
      )}

      <h2 className="canvas-title">Your AI Generated Cake Design</h2>
      <div className="canvas-grid">
        <div className="canvas-left">
          {!loading && !cakeVisualizationUrls.length && renderProgressCircle()}
        </div>
        <div className="canvas-center">
          {loading || cakeVisualizationUrls.length > 0 ? (
            <>
              {loading ? (
                <div className="loading-container">
                  <Spinner text="Dream Wedding Cake Generating" />
                  <div className="progress-indicator">
                    <div className="progress-bar">
                      <div 
                        className="progress" 
                        style={{ width: `${progress}%` }}
                      ></div>
                    </div>
                    <p>Progress: {progress}%</p>
                    <p>Generating your dream wedding cake...</p>
                  </div>
                </div>
              ) : (
                renderCombinedCarousel()
              )}
            </>
          ) : null}
        </div>
        <div className="canvas-right"></div>
      </div>
      <button 
        onClick={handleVisualizationClick}
        className={`visualization-button ${(!selectedSize || !selectedTier || !selectedShape || !selectedFlavor || !selectedDecoration || selectedColor === '#ffffff') ? 'disabled' : ''}`}
        disabled={!selectedSize || !selectedTier || !selectedShape || !selectedFlavor || !selectedDecoration || selectedColor === '#ffffff'}
      >
        {loading ? 'Generating...' : 
         credits <= 0 ? 'Out of Credits' : 
         (!selectedSize || !selectedTier || !selectedShape || !selectedFlavor || !selectedDecoration || selectedColor === '#ffffff') ? 'Complete All Options' :
         (cakeVisualizationUrls?.length > 0) ? 'Regenerate' : 'Generate Visualization'}
      </button>
      
      {showMessage && (
        <div className="bookmark-message">
          {isUploading ? 'Uploading...' : 'Added To User Profile'}
        </div>
      )}
      {isUploading && (
        <div className="upload-overlay">
          <div className="spinner"></div>
          <p>Uploading image...</p>
        </div>
      )}
      <PaymentPortal 
        isOpen={showCreditsModal}
        onClose={() => setShowCreditsModal(false)}
        user={user}
        fetchCredits={fetchCredits}
        credits={credits}
      />
    </div>
  );
};

export default WeddingCakesCanvas; 