import React from 'react';
import './Home.css';
import Footer from './Footer';
import LandingHero from './LandingHero';

const Home = () => {
  return (
    <div className="home-wrapper">
      <LandingHero />
      <Footer />
    </div>
  );
};

export default Home;