import React, { useState, useEffect, useRef } from 'react';
import ColorPicker from '../../../components/ColorPicker';
import { getClosestColor } from '../../../components/colorMapper';
import { useDesign } from '../../../hooks/DesignContext';
import sendToDiscord from '../../../utils/discordWebhook';
import './WeddingAttire.css';
import '../Page.css';
import '../decor/Design.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { getAuth } from 'firebase/auth';
import CryptoJS from 'crypto-js';
import { generateAuthToken } from '../../../utils/encryptExternalID';
import getImageUrl from '../../../utils/imageUtils';
import WeddingAttireCanvas from './WeddingAttireCanvas';

import '../floral/Florals.css';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const SECRET_KEY = process.env.REACT_APP_WEDIFY_APP_SECRET_KEY;

const encryptPrompt = (plaintext, key) => {
  try {
    const keyBytes = CryptoJS.enc.Hex.parse(key);
    const encrypted = CryptoJS.AES.encrypt(plaintext, keyBytes, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  } catch (error) {
    return null;
  }
};

const suitColorPresets = [
  // Classic Suit Colors
  { name: 'Charcoal Grey', hex: '#36454F' },
  { name: 'Navy Blue', hex: '#000080' },
  { name: 'Black', hex: '#000000' },
  { name: 'Oxford Grey', hex: '#4E5754' },
  
  // Business & Professional
  { name: 'Steel Blue', hex: '#4682B4' },
  { name: 'Dark Brown', hex: '#3C2A21' },
  { name: 'Slate Grey', hex: '#708090' },
  { name: 'Midnight Blue', hex: '#191970' },
  
  // Modern & Trendy
  { name: 'Burgundy', hex: '#800020' },
  { name: 'Forest Green', hex: '#228B22' },
  { name: 'Royal Blue', hex: '#4169E1' },
  { name: 'Plum', hex: '#673147' },
  
  // Light & Summer
  { name: 'Light Grey', hex: '#D3D3D3' },
  { name: 'Khaki', hex: '#C3B091' },
  { name: 'Tan', hex: '#D2B48C' },
  { name: 'Stone', hex: '#928E85' },
  
  // Custom Option
  { name: 'Custom', image: getImageUrl('themesOption/colorPicker.png') }
];

const dressColorPresets = [
  // Traditional Whites
  { name: 'Pure White', hex: '#FFFFFF' },
  { name: 'Ivory', hex: '#FFFFF0' },
  { name: 'Cream', hex: '#FFFDD0' },
  { name: 'Off-White', hex: '#FAF9F6' },
  
  // Soft Pastels
  { name: 'Blush Pink', hex: '#FEC5E5' },
  { name: 'Champagne', hex: '#F7E7CE' },
  { name: 'Pearl', hex: '#F0EAD6' },
  { name: 'Rose Gold', hex: '#B76E79' },
  
  // Modern Options
  { name: 'Silver', hex: '#C0C0C0' },
  { name: 'Light Gold', hex: '#B29700' },
  { name: 'Dusty Blue', hex: '#8BA9A5' },
  { name: 'Soft Lavender', hex: '#E6E6FA' },
  
  // Statement Colors
  { name: 'Blush', hex: '#DE6FA1' },
  { name: 'Misty Rose', hex: '#FFE4E1' },
  { name: 'Antique White', hex: '#FAEBD7' },
  { name: 'Seashell', hex: '#FFF5EE' },
  
  // Custom Option
  { name: 'Custom', image: getImageUrl('themesOption/colorPicker.png') }
];

const WeddingAttire = () => {
    const { 
        selectedColor, setSelectedColor,
        selectedFit, setSelectedFit,
        selectedLapel, setSelectedLapel,
        selectedMaterial, setSelectedMaterial,
        selectedPattern, setSelectedPattern,
        selectedJacket, setSelectedJacket,
        selectedDressStyle, setSelectedDressStyle,
        selectedNeckline, setSelectedNeckline,
        selectedFabric, setSelectedFabric,
        selectedLength, setSelectedLength,
        selectedDetails, setSelectedDetails,
        attireType, setAttireType,
        attireVisualizationUrls, setAttireVisualizationUrls,
        isGenerationComplete, setIsGenerationComplete,
        attirePrompt, setAttirePrompt,
        loading, setLoading,
        progress, setProgress
    } = useDesign();

    const [showColorPicker, setShowColorPicker] = useState(false);
    const [showAddInput, setShowAddInput] = useState({ 
        fit: false, 
        lapel: false, 
        material: false, 
        pattern: false, 
        jacket: false,
        dressStyle: false,
        neckline: false,
        fabric: false,
        length: false,
        details: false
    });
    const [newInput, setNewInput] = useState({ 
        fit: '', 
        lapel: '', 
        material: '', 
        pattern: '', 
        jacket: '',
        dressStyle: '',
        neckline: '',
        fabric: '',
        length: '',
        details: ''
    });
    const [currentStep, setCurrentStep] = useState(1);
    

    const attireTypeOptions = [
        { name: 'Suit/Tuxedo', image: '/wedding/tux.jpg'},
        { name: 'Wedding Dress', image: '/wedding/dress.jpg' }
    ];
  
    const fitOptions = [
        { name: 'Slim Fit', image: '/wedding/slimFit.jpg' },
        { name: 'Tailored Fit', image: '/wedding/tailoredFit.jpg' },
        { name: 'Regular Fit', image: '/wedding/regularFit.jpg' },
        { name: 'Relaxed Fit', image: '/wedding/relaxedFit.jpg' },
        { name: 'Add', image: '/themesOption/add-plus.jpg' }
    ];
  
    const lapelOptions = [
        { name: 'Notch Lapel', image: '/wedding/notchLapel.jpg' },
        { name: 'Peak Lapel', image: '/wedding/peakLapel.jpg' },
        { name: 'Shawl Lapel', image: '/wedding/shawLapel.jpg' },
        { name: 'Wide Lapel', image: '/wedding/wideLapel.jpg' },
        { name: 'Slim Lapel', image: '/wedding/slimLapel.jpg' },
        { name: 'Add', image: '/themesOption/add-plus.jpg' }
    ];
  
    const materialOptions = [
        { name: 'Wool', image: '/wedding/wool.jpg' },
        { name: 'Linen', image: '/wedding/linen.jpg' },
        { name: 'Velvet', image: '/wedding/velvet.jpg' },
        { name: 'Tweed', image: '/wedding/tweed.jpg' },
        { name: 'Silk Blend', image: '/wedding/silkBlend.jpg' },
        { name: 'Add', image: '/themesOption/add-plus.jpg' }
    ];
  
    const patternOptions = [
        { name: 'Pinstripe', image: '/wedding/pinstripe.jpg' },
        { name: 'Plaid', image: '/wedding/plaid.jpg' },
        { name: 'Windowpane', image: '/wedding/windowpane.jpg' },
        { name: 'Herringbone', image: '/wedding/herringbone.jpg' },
        { name: 'Checkered', image: '/wedding/checkered.jpg' },
        { name: 'Solid', image: '/wedding/solid.jpg' },
        { name: 'Add', image: '/themesOption/add-plus.jpg' }
    ];
  
    const jacketOptions = [
        { name: 'Single-Breasted', image: '/wedding/singleBreasted.jpg' },
        { name: 'Double-Breasted', image: '/wedding/doubleBreasted.jpg' },
        { name: 'Dinner Jacket', image: '/wedding/dinnerJacket.jpg' },
        { name: 'Morning Coat', image: '/wedding/morningCoat.jpg' },
        { name: 'Blazer', image: '/wedding/blazer.jpg' },
        { name: 'Add', image: '/themesOption/add-plus.jpg' }
    ];
  
    const colorPresets = attireType === 'dress' ? dressColorPresets : suitColorPresets;
  
    const handleOptionClick = (type, value) => {
        if (value === 'Add') {
            setShowAddInput(prev => ({ ...prev, [type]: true }));
            return;
        }

        switch(type) {
            // Dress options
            case 'dressStyle':
                setSelectedDressStyle(value);
                break;
            case 'neckline':
                setSelectedNeckline(value);
                break;
            case 'fabric':
                setSelectedFabric(value);
                break;
            case 'length':
                setSelectedLength(value);
                break;
            case 'details':
                setSelectedDetails(value);
                break;
            // Suit options
            case 'fit':
                setSelectedFit(value);
                break;
            case 'lapel':
                setSelectedLapel(value);
                break;
            case 'material':
                setSelectedMaterial(value);
                break;
            case 'pattern':
                setSelectedPattern(value);
                break;
            case 'jacket':
                setSelectedJacket(value);
                break;
            // Color handling
            case 'color':
                setSelectedColor(value.hex);
                break;
            default:
                break;
        }
    };
  
      // ... (kept similar checkGenerationStatus)
    const checkGenerationStatus = async (generationId) => {
      let attempts = 0;
      const maxAttempts = 30;
      const initialDelay = 1000;
      const maxDelay = 10000;
        
      const poll = async () => {
        if (attempts >= maxAttempts) {
          throw new Error('Generation timed out');
        }
    
        attempts++;
    
        try {
          const token = generateAuthToken(); // Generate token for each request
          const response = await fetch(`${API_BASE_URL}/api/images/get/image/retrieve`, {
              method: 'GET',
              headers: {
                'imageId': generationId,
                'X-Auth-Token': token, // Add the token to the headers
              },
            });
            
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const contentType = response.headers.get('Content-Type');
            const contentLength = response.headers.get('Content-Length');
    
            if (contentType && contentType.includes('image') && contentLength && parseInt(contentLength) > 0) {
              const blob = await response.blob();
              return URL.createObjectURL(blob);
            } else {
              const delay = Math.min(initialDelay * Math.pow(1.5, attempts), maxDelay);
              await new Promise(resolve => setTimeout(resolve, delay));
              return poll();
            }
          } catch (error) {
            const delay = Math.min(initialDelay * Math.pow(1.5, attempts), maxDelay);
            await new Promise(resolve => setTimeout(resolve, delay));
            return poll();
          }
        };
    
      return poll();
    };
  
    const fetchCredits = async () => {
      try {
        const token = await generateAuthToken();
        const response = await fetch(`${API_BASE_URL}/api/credits`, {
          method: 'GET',
          headers: {
            'X-Auth-Token': token,
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        
        // Update state or context with the fetched credits if needed
      } catch (error) {
        
      }
    };
  
    const generateVisualizations = async () => {
      // Check if all required fields are selected based on attire type
      if (attireType === 'suit' && (!selectedFit || !selectedLapel || !selectedMaterial || !selectedPattern || !selectedJacket || !selectedColor)) {
        console.error('Not all required suit fields are selected');
        return;
      } else if (attireType === 'dress' && (!selectedDressStyle || !selectedNeckline || !selectedFabric || !selectedLength || !selectedDetails || !selectedColor)) {
        console.error('Not all required dress fields are selected');
        return;
      }

      setLoading(true);
      setIsGenerationComplete(false);
      setProgress(0);
      setCurrentStep(7);

      try {
        const auth = getAuth();
        const currentUser = auth.currentUser;
          
        if (!currentUser) {
            console.error('Authentication error: No user found');
            throw new Error('No authenticated user found');
        }
    
        setProgress(10);
    
        const token = await generateAuthToken();
        if (!token) {
            console.error('Token generation failed');
            throw new Error('Failed to generate authentication token');
        }
    
        // Get color name - handle both preset and custom colors
        let colorName;
        const selectedColorObj = colorPresets.find(color => color.hex === selectedColor);
        
        if (selectedColorObj) {
          colorName = selectedColorObj.name;
        } else {
          colorName = getClosestColor(selectedColor);
        }
        
        // Define prompts based on attire type
        const attirePromptText = attireType === 'suit'
          ? `A photorealistic depiction of a ${selectedFit} men's suit with a ${selectedLapel}, displayed on a mannequin. The suit is crafted from luxurious ${selectedMaterial} fabric in a refined ${colorName} shade, featuring a ${selectedPattern} pattern. The ${selectedJacket} design showcases sharp tailoring and precise craftsmanship. The mannequin is centered against a plain, neutral background with soft, even lighting highlighting the texture of the fabric, the intricate details of the lapel, and the sleek, structured fit.`
          : `A photorealistic depiction of a ${selectedDressStyle} wedding dress with a ${selectedNeckline} neckline, displayed on a mannequin. The gown is made from luxurious ${selectedFabric} fabric in a ${colorName} shade, emphasizing its exquisite craftsmanship. The dress features a ${selectedLength} length and is adorned with intricate ${selectedDetails}, adding to its elegance. The mannequin is centered against a plain, neutral background with soft, even lighting, showcasing the silhouette, fabric texture, and delicate details from top to bottom, highlighting the dress's timeless beauty and sophistication`;

        setAttirePrompt(attirePromptText);
    
        // Generate image
        const attireUrl = await generateImage(attirePromptText, token, currentUser.uid);
       
    
        setAttireVisualizationUrls([attireUrl]);
        setIsGenerationComplete(true);
        setProgress(100);
    
        // Fetch credits after the image is generated
        await fetchCredits();
    
      } catch (error) {
        console.error('Error in generateVisualizations:', error);
        setIsGenerationComplete(false);
        // Optionally show error to user
      } finally {
        setLoading(false);
      }
    };
  
    const generateImage = async (prompt, token, userId) => {
  try {
    const encryptedPrompt = encryptPrompt(prompt, SECRET_KEY);
    if (!encryptedPrompt) {
      console.error('Prompt encryption failed');
      throw new Error('Failed to encrypt prompt');
    }

    // Updated to match the working curl request format
    const generateResponse = await fetch(`${API_BASE_URL}/api/images/get/image/generate`, {
      method: 'PUT',
      headers: {
        'whatYouLookinAt': encryptedPrompt,
        'X-Auth-Token': token,
        'model': 'phoenix',
        'authenticationService': 'firebase',
        'authId': userId
      }
    });

    // Remove Content-Type header as it's not in the working curl request
    // Remove other unnecessary headers

    if (!generateResponse.ok) {
      const responseText = await generateResponse.text();
      console.error('API Error Response:', {
        status: generateResponse.status,
        statusText: generateResponse.statusText,
        body: responseText
      });
      throw new Error(`HTTP error! status: ${generateResponse.status}`);
    }

    const generateData = await generateResponse.json();

    if (generateData?.sdGenerationJob?.generationId) {
      const generationId = generateData.sdGenerationJob.generationId;
      const imageUrl = await checkGenerationStatus(generationId);
      return imageUrl;
    } else {
      throw new Error('No generationId returned');
    }
  } catch (error) {
    console.error('Error in generateImage:', error);
    throw error;
  }
};
    // First, add all the necessary refs at the top of your component
    const fitSliderRef = useRef(null);
    const lapelSliderRef = useRef(null);
    const materialSliderRef = useRef(null);
    const patternSliderRef = useRef(null);
    const jacketSliderRef = useRef(null);
    const dressStyleSliderRef = useRef(null);
    const necklineSliderRef = useRef(null);
    const fabricSliderRef = useRef(null);
    const lengthSliderRef = useRef(null);
    const detailsSliderRef = useRef(null);

    const fitIntervalRef = useRef(null);
    const lapelIntervalRef = useRef(null);
    const materialIntervalRef = useRef(null);
    const patternIntervalRef = useRef(null);
    const jacketIntervalRef = useRef(null);
    const dressStyleIntervalRef = useRef(null);
    const necklineIntervalRef = useRef(null);
    const fabricIntervalRef = useRef(null);
    const lengthIntervalRef = useRef(null);
    const detailsIntervalRef = useRef(null);

    // Add the slider settings
    const sliderSettings = {
      modules: [Navigation, Autoplay, Pagination],
      spaceBetween: 1,
      slidesPerView: 3,
      speed: 200,
      loop: true,
      allowTouchMove: false,
      pagination: {
        clickable: true,
        type: 'bullets',
        dynamicBullets: true,
        dynamicMainBullets: 3
      }
    };

    // Add autoplay functions for each section
    const startFitAutoplay = (direction) => {
      if (fitSliderRef.current?.swiper) {
        const swiper = fitSliderRef.current.swiper;
        if (direction === 'left') {
          swiper.slidePrev();
        } else {
          swiper.slideNext();
        }
        if (fitIntervalRef.current) {
          clearInterval(fitIntervalRef.current);
        }
        fitIntervalRef.current = setInterval(() => {
          if (direction === 'left') {
            swiper.slidePrev();
          } else {
            swiper.slideNext();
          }
        }, 200);
      }
    };

    const stopFitAutoplay = () => {
      if (fitIntervalRef.current) {
        clearInterval(fitIntervalRef.current);
        fitIntervalRef.current = null;
      }
    };

    // Helper functions
    const startAutoplay = (sliderRef, intervalRef, direction) => {
      if (sliderRef.current && sliderRef.current.swiper) {
        const swiper = sliderRef.current.swiper;
        
        if (direction === 'left') {
          swiper.slidePrev();
        } else {
          swiper.slideNext();
        }

        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }

        intervalRef.current = setInterval(() => {
          if (direction === 'left') {
            swiper.slidePrev();
          } else {
            swiper.slideNext();
          }
        }, 300);
      }
    };
  
    const stopAutoplay = (intervalRef) => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  
    const handleAddClick = (type) => {
      setShowAddInput(prev => ({ ...prev, [type]: true }));
    };
  
    const handleInputChange = (e, type) => {
      setNewInput(prev => ({ ...prev, [type]: e.target.value }));
    };
  
    const handleNewInputSubmit = async (type) => {
        if (!newInput[type].trim()) {
            return;
        }

        // Get current user
        const auth = getAuth();
        const user = auth.currentUser;
        const userEmail = user ? user.email : 'Anonymous';

        // Prepare message for Discord
        const message = {
            username: "Wedify Bot",
            content: `New ${type} suggestion from ${userEmail}`,
            embeds: [{
                title: `New ${type} Suggestion`,
                description: `Suggested ${type}: ${newInput[type]}`,
                fields: [
                    {
                        name: 'Category',
                        value: `Wedding Attire ${type.charAt(0).toUpperCase() + type.slice(1)}`
                    },
                    {
                        name: 'Submitted by',
                        value: userEmail
                    }
                ],
                color: 3066993 // Green color in decimal
            }]
        };

        try {
            await sendToDiscord(message);
            setNewInput(prev => ({ ...prev, [type]: '' }));
            setShowAddInput(prev => ({ ...prev, [type]: false }));
            alert('Thank you for your suggestion! We will review it shortly.');
        } catch (error) {
            console.error('Error sending suggestion:', error);
            alert('Sorry, there was an error submitting your suggestion. Please try again later.');
        }
    };
  
    useEffect(() => {
    }, [selectedColor]);
  
    // Add this ref at the top of your component
    const stepsContainerRef = useRef(null);
  
    // Add this effect to handle scrolling when step changes
    useEffect(() => {
      if (currentStep > 4 && stepsContainerRef.current) {
        const stepWidth = 80; // Approximate width of each step including margins
        const scrollPosition = (currentStep - 4) * stepWidth;
        stepsContainerRef.current.scrollTo({
          left: scrollPosition,
          behavior: 'smooth'
        });
      }
    }, [currentStep]);
  
    // Add new dress options
    const dressStyleOptions = [
        { name: 'A-Line', image: '/wedding/aLine.jpg' },
        { name: 'Ball Gown', image: '/wedding/ballGown.jpg' },
        { name: 'Mermaid', image: '/wedding/mermaid.jpg' },
        { name: 'Sheath', image: '/wedding/sheath.jpg' },
        { name: 'Trumpet', image: '/wedding/trumpet.jpg' },
        { name: 'Empire Waist', image: '/wedding/empire.jpg' },
        { name: 'Add', image: '/themesOption/add-plus.jpg' }
    ];

    const dressNecklineOptions = [
        { name: 'Sweetheart', image: '/wedding/sweetheart.jpg' },
        { name: 'V-Neck', image: '/wedding/vLine.jpg' },
        { name: 'Off-Shoulder', image: '/wedding/offShoulder.jpg' },
        { name: 'Halter', image: '/wedding/halter.jpg' },
        { name: 'Strapless', image: '/wedding/strapless.jpg' },
        { name: 'Add', image: '/themesOption/add-plus.jpg' }
    ];

    const dressFabricOptions = [
        { name: 'Silk', image: '/wedding/silk.jpg' },
        { name: 'Lace', image: '/wedding/lace.jpg' },
        { name: 'Tulle', image: '/wedding/tulle.jpg' },
        { name: 'Satin', image: '/wedding/satin.jpg' },
        { name: 'Organza', image: '/wedding/organza.jpg' },
        { name: 'Chiffon', image: '/wedding/chiffon.jpg' },
        { name: 'Add', image: '/themesOption/add-plus.jpg' }
    ];

    const dressLengthOptions = [
        { name: 'Floor Length', image: '/wedding/fullLength.jpg'},
        { name: 'Chapel Train', image: '/wedding/chapelTrain.jpg' },
        { name: 'Tea Length', image: '/wedding/teaLength.jpg' },
        { name: 'Knee Length', image: '/wedding/kneeLength.jpg' },
        { name: 'Add', image: '/themesOption/add-plus.jpg' }
    ];

    const dressDetailsOptions = [
        { name: 'Beading', image: '/wedding/beading.jpg' },
        { name: 'Embroidery', image: '/wedding/embroidery.jpg' },
        { name: 'Appliqués', image: '/wedding/appliques.jpg' },
        { name: 'Ruffles', image: '/wedding/ruffles.jpg' },
        { name: 'Sequins', image: '/wedding/sequins.jpg' },
        { name: 'Pearls', image: '/wedding/pearls.jpg' },
        { name: 'Add', image: '/themesOption/add-plus.jpg' }
    ];

    const renderProgressBar = () => {
        const suitSteps = [
            { num: 1, name: 'Fit' },
            { num: 2, name: 'Lapel' },
            { num: 3, name: 'Material' },
            { num: 4, name: 'Pattern' },
            { num: 5, name: 'Jacket' },
            { num: 6, name: 'Color' },
            { num: 7, name: 'Preview' }
        ];

        const dressSteps = [
            { num: 1, name: 'Style' },
            { num: 2, name: 'Neckline' },
            { num: 3, name: 'Fabric' },
            { num: 4, name: 'Length' },
            { num: 5, name: 'Details' },
            { num: 6, name: 'Color' },
            { num: 7, name: 'Preview' }
        ];

        // If attire type not selected yet, show initial step
        if (!attireType) {
            return (
                <div className="mobile-progress-bar">
                    <div className="step active">
                        <div className="step-number">1</div>
                        <div className="step-name">Type</div>
                    </div>
                </div>
            );
        }

        const steps = attireType === 'suit' ? suitSteps : dressSteps;

        // Determine which steps to show (same logic as Proposals)
        const visibleSteps = currentStep <= 4 
            ? steps.slice(0, 4)  // Show steps 1-4
            : steps.slice(3, 7); // Show steps 4-7

        return (
            <div className="mobile-progress-bar" ref={stepsContainerRef}>
                {visibleSteps.map((step) => (
                    <div 
                        key={step.num}
                        className={`step ${currentStep >= step.num ? 'completed' : ''} ${currentStep === step.num ? 'active' : ''}`}
                        onClick={() => {
                            // Only allow clicking on completed steps or the next available step
                            if (step.num <= currentStep + 1) {
                                setCurrentStep(step.num);
                            }
                        }}
                    >
                        <div className="step-number">{step.num}</div>
                        <div className="step-name">{step.name}</div>
                    </div>
                ))}
            </div>
        );
    };

    const renderContinueButton = (selectedValue, nextStep) => {
        return (
            <button
                className={`continue-button ${!selectedValue ? 'disabled' : ''}`}
                onClick={() => selectedValue && setCurrentStep(nextStep)}
                disabled={!selectedValue}
            >
                Continue
            </button>
        );
    };

    const renderMobileStep = () => {
        // First show attire type selection if not selected
        if (!attireType) {
            return (
                <>
                    <div className="step-header">
                        <h2>Choose Your Attire Type</h2>
                    </div>
                    <div className="mobile-options-grid">
                        {attireTypeOptions.map((option) => (
                            <div 
                                key={option.name}
                                onClick={() => setAttireType(option.name === 'Suit/Tuxedo' ? 'suit' : 'dress')}
                                className="mobile-option-item"
                            >
                                <img src={option.image} alt={option.name} />
                                <p>{option.name}</p>
                            </div>
                        ))}
                    </div>
                </>
            );
        }

        const handleBackToAttireType = () => {
            setAttireType(null);
            setCurrentStep(1);
        };

        if (attireType === 'suit') {
            switch(currentStep) {
                case 1:
                    return (
                        <>
                            <div className="back-section">
                                <button 
                                    className="mobile-back-button"
                                    onClick={handleBackToAttireType}
                                >
                                    ← Back to Attire Type
                                </button>
                            </div>
                            <div className="step-header">
                                <h2>Choose Your Fit</h2>
                            </div>
                            <div className="mobile-options-grid">
                                {fitOptions.map((fit) => (
                                    <div 
                                        key={fit.name}
                                        onClick={() => {
                                            if (fit.name === 'Add') {
                                                handleAddClick('fit');
                                                return;
                                            }
                                            handleOptionClick('fit', fit.name);
                                        }}
                                        className={`mobile-option-item ${selectedFit === fit.name ? 'selected' : ''}`}
                                    >
                                        <img src={fit.image} alt={fit.name} />
                                        <p>{fit.name}</p>
                                    </div>
                                ))}
                            </div>
                            {renderContinueButton(selectedFit, 2)}
                        </>
                    );
                case 2:
                    return (
                        <>
                            <div className="step-header">
                                <h2>Choose Your Lapel</h2>
                            </div>
                            <div className="mobile-options-grid">
                                {lapelOptions.map((lapel) => (
                                    <div 
                                        key={lapel.name}
                                        onClick={() => {
                                            if (lapel.name === 'Add') {
                                                handleAddClick('lapel');
                                                return;
                                            }
                                            handleOptionClick('lapel', lapel.name);
                                        }}
                                        className={`mobile-option-item ${selectedLapel === lapel.name ? 'selected' : ''}`}
                                    >
                                        <img src={lapel.image} alt={lapel.name} />
                                        <p>{lapel.name}</p>
                                    </div>
                                ))}
                            </div>
                            {renderContinueButton(selectedLapel, 3)}
                        </>
                    );
                // ... other suit cases
                case 6:
                    return (
                        <>
                            <div className="step-header">
                                <h2>Choose Your Color</h2>
                            </div>
                            <div className="color-presets-container">
                                {suitColorPresets.map((color) => (
                                    <div 
                                        key={color.name}
                                        onClick={() => color.name === 'Custom' 
                                            ? setShowColorPicker(true) 
                                            : handleOptionClick('color', { hex: color.hex })}
                                        className={`color-preset-item ${selectedColor === color.hex ? 'selected' : ''}`}
                                    >
                                        {color.name === 'Custom' ? (
                                            <>
                                                <img src={color.image} alt="Custom color" className="color-wheel-icon" />
                                                <p>Custom Color</p>
                                            </>
                                        ) : (
                                            <>
                                                <div className="color-swatch" style={{ backgroundColor: color.hex }} />
                                                <p>{color.name}</p>
                                            </>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="visualization-container">
                                <button
                                    onClick={generateVisualizations}
                                    disabled={!selectedFit || !selectedLapel || !selectedMaterial || 
                                             !selectedPattern || !selectedJacket || !selectedColor || loading}
                                    className={`visualization-button mobile-generate-button ${
                                        (!selectedFit || !selectedLapel || !selectedMaterial || 
                                         !selectedPattern || !selectedJacket || !selectedColor) ? 'disabled' : ''
                                    }`}
                                >
                                    {loading ? 'Generating...' : isGenerationComplete ? 'Regenerate' : 'Generate Visualization'}
                                </button>
                            </div>
                            {loading && (
                                <div className="progress-container">
                                    <div className="progress-bar">
                                        <div className="progress" style={{ width: `${progress}%` }}></div>
                                    </div>
                                </div>
                            )}
                        </>
                    );
                case 7:
                    return (
                        <>
                            <WeddingAttireCanvas 
                                progress={progress}
                                loading={loading}
                                setLoading={setLoading}
                                generateVisualizations={generateVisualizations}
                                isGenerationComplete={isGenerationComplete}
                                attireVisualizationUrls={attireVisualizationUrls}
                                attirePrompt={attirePrompt}
                            />
                        </>
                    );
                default:
                    return null;
            }
        } else if (attireType === 'dress') {
            switch(currentStep) {
                case 1:
                    return (
                        <>
                            <div className="back-section">
                                <button 
                                    className="mobile-back-button"
                                    onClick={handleBackToAttireType}
                                >
                                    ← Back to Attire Type
                                </button>
                            </div>
                            <div className="step-header">
                                <h2>Choose Your Dress Style</h2>
                            </div>
                            <div className="mobile-options-grid">
                                {dressStyleOptions.map((style) => (
                                    <div 
                                        key={style.name}
                                        onClick={() => {
                                            if (style.name === 'Add') {
                                                handleAddClick('dressStyle');
                                                return;
                                            }
                                            handleOptionClick('dressStyle', style.name);
                                        }}
                                        className={`mobile-option-item ${selectedDressStyle === style.name ? 'selected' : ''}`}
                                    >
                                        <img src={style.image} alt={style.name} />
                                        <p>{style.name}</p>
                                    </div>
                                ))}
                            </div>
                            {renderContinueButton(selectedDressStyle, 2)}
                        </>
                    );
                case 2:
                    return (
                        <>
                            <div className="step-header">
                                <h2>Choose Your Neckline</h2>
                            </div>
                            <div className="mobile-options-grid">
                                {dressNecklineOptions.map((neckline) => (
                                    <div 
                                        key={neckline.name}
                                        onClick={() => {
                                            if (neckline.name === 'Add') {
                                                handleAddClick('neckline');
                                                return;
                                            }
                                            handleOptionClick('neckline', neckline.name);
                                        }}
                                        className={`mobile-option-item ${selectedNeckline === neckline.name ? 'selected' : ''}`}
                                    >
                                        <img src={neckline.image} alt={neckline.name} />
                                        <p>{neckline.name}</p>
                                    </div>
                                ))}
                            </div>
                            {renderContinueButton(selectedNeckline, 3)}
                        </>
                    );
                case 3:
                    return (
                        <>
                            <div className="step-header">
                                <h2>Choose Your Fabric</h2>
                            </div>
                            <div className="mobile-options-grid">
                                {dressFabricOptions.map((fabric) => (
                                    <div 
                                        key={fabric.name}
                                        onClick={() => {
                                            if (fabric.name === 'Add') {
                                                handleAddClick('fabric');
                                                return;
                                            }
                                            handleOptionClick('fabric', fabric.name);
                                        }}
                                        className={`mobile-option-item ${selectedFabric === fabric.name ? 'selected' : ''}`}
                                    >
                                        <img src={fabric.image} alt={fabric.name} />
                                        <p>{fabric.name}</p>
                                    </div>
                                ))}
                            </div>
                            {renderContinueButton(selectedFabric, 4)}
                        </>
                    );
                case 4:
                    return (
                        <>
                            <div className="step-header">
                                <h2>Choose Your Length</h2>
                            </div>
                            <div className="mobile-options-grid">
                                {dressLengthOptions.map((length) => (
                                    <div 
                                        key={length.name}
                                        onClick={() => {
                                            if (length.name === 'Add') {
                                                handleAddClick('length');
                                                return;
                                            }
                                            handleOptionClick('length', length.name);
                                        }}
                                        className={`mobile-option-item ${selectedLength === length.name ? 'selected' : ''}`}
                                    >
                                        <img src={length.image} alt={length.name} />
                                        <p>{length.name}</p>
                                    </div>
                                ))}
                            </div>
                            {renderContinueButton(selectedLength, 5)}
                        </>
                    );
                case 5:
                    return (
                        <>
                            <div className="step-header">
                                <h2>Choose Your Details</h2>
                            </div>
                            <div className="mobile-options-grid">
                                {dressDetailsOptions.map((details) => (
                                    <div 
                                        key={details.name}
                                        onClick={() => {
                                            if (details.name === 'Add') {
                                                handleAddClick('details');
                                                return;
                                            }
                                            handleOptionClick('details', details.name);
                                        }}
                                        className={`mobile-option-item ${selectedDetails === details.name ? 'selected' : ''}`}
                                    >
                                        <img src={details.image} alt={details.name} />
                                        <p>{details.name}</p>
                                    </div>
                                ))}
                            </div>
                            {renderContinueButton(selectedDetails, 6)}
                        </>
                    );
                case 6:
                    return (
                        <>
                            <div className="step-header">
                                <h2>Choose Your Color</h2>
                            </div>
                            <div className="color-presets-container">
                                {dressColorPresets.map((color) => (
                                    <div 
                                        key={color.name}
                                        onClick={() => color.name === 'Custom' 
                                            ? setShowColorPicker(true) 
                                            : handleOptionClick('color', { hex: color.hex })}
                                        className={`color-preset-item ${selectedColor === color.hex ? 'selected' : ''}`}
                                    >
                                        {color.name === 'Custom' ? (
                                            <>
                                                <img src={color.image} alt="Custom color" className="color-wheel-icon" />
                                                <p>Custom Color</p>
                                            </>
                                        ) : (
                                            <>
                                                <div className="color-swatch" style={{ backgroundColor: color.hex }} />
                                                <p>{color.name}</p>
                                            </>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="visualization-container">
                                <button
                                    onClick={generateVisualizations}
                                    disabled={!selectedDressStyle || !selectedNeckline || !selectedFabric || 
                                             !selectedLength || !selectedDetails || !selectedColor || loading}
                                    className={`visualization-button mobile-generate-button ${
                                        (!selectedDressStyle || !selectedNeckline || !selectedFabric || 
                                         !selectedLength || !selectedDetails || !selectedColor) ? 'disabled' : ''
                                    }`}
                                >
                                    {loading ? 'Generating...' : isGenerationComplete ? 'Regenerate' : 'Generate Visualization'}
                                </button>
                            </div>
                            {loading && (
                                <div className="progress-container">
                                    <div className="progress-bar">
                                        <div className="progress" style={{ width: `${progress}%` }}></div>
                                    </div>
                                </div>
                            )}
                        </>
                    );
                case 7:
                    return (
                        <>
                            <WeddingAttireCanvas 
                                progress={progress}
                                loading={loading}
                                setLoading={setLoading}
                                generateVisualizations={generateVisualizations}
                                isGenerationComplete={isGenerationComplete}
                                attireVisualizationUrls={attireVisualizationUrls}
                                attirePrompt={attirePrompt}
                            />
                        </>
                    );
                default:
                    return null;
            }
        }
    };
  
    return (
      <div className="wedding-cakes-design-page">
        {/* Mobile Content*/}
        <div className="wedding-cakes-mobile-content">
          <div className="mobile-design">
            <div className="mobile-content">
              {renderProgressBar()}
              <div className="mobile-step">
                {renderMobileStep()}
              </div>
            </div>
          </div>
        </div>
  
        {/* Render suggestion input fields */}
        {Object.keys(showAddInput).map(type => (
            showAddInput[type] && (
                <div className="add-theme-overlay" key={type}>
                    <div className="add-theme-input">
                        <h4>What other {type} should we add?</h4>
                        <input 
                            type="text" 
                            value={newInput[type]}
                            onChange={(e) => handleInputChange(e, type)}
                            placeholder={`Suggest new ${type}...`}
                            autoFocus
                        />
                        <div className="add-theme-buttons">
                            <button onClick={() => setShowAddInput(prev => ({ ...prev, [type]: false }))}>Cancel</button>
                            <button onClick={() => handleNewInputSubmit(type)}>Submit</button>
                        </div>
                    </div>
                </div>
            )
        ))}
  
        {/* Desktop Layout*/}
        <div className="wedding-cakes-desktop-content">
          <div className="design-container">
            <h2 className="page-title">Design Your Attire</h2>
  
            {!attireType ? (
              <div className="design-section attire-type">
                <h3>Select Attire Type</h3>
                <div className="attire-options-container">
                  {attireTypeOptions.map((option) => (
                    <div
                      key={option.name}
                      className="attire-option"
                      onClick={() => setAttireType(option.name === 'Suit/Tuxedo' ? 'suit' : 'dress')}
                    >
                      <img src={option.image} alt={option.name} className="attire-image" />
                      <p>{option.name}</p>
                    </div>
                  ))}
                </div>
              </div>
            ) : attireType === 'suit' ? (
              <div className="suit-options">
                <div className="back-section">
                  <button 
                    className="back-button"
                    onClick={() => setAttireType(null)}
                  >
                    ← Back to Attire Type
                  </button>
                </div>

                <div className="design-section fit">
                  <h3>Select Fit</h3>
                  <div className="slider-container">
                    <div 
                      className="hover-zone hover-zone-left"
                      onMouseEnter={() => startFitAutoplay('left')}
                      onMouseLeave={stopFitAutoplay}
                    />
                    <Swiper
                      ref={fitSliderRef}
                      {...sliderSettings}
                      className="fit-swiper"
                    >
                      {fitOptions.map((fit) => (
                        <SwiperSlide key={fit.name}>
                          <div 
                            onClick={() => {
                              if (fit.name === 'Add') {
                                handleAddClick('fit');
                                return;
                              }
                              handleOptionClick('fit', fit.name);
                            }}
                            className={`carousel-item ${selectedFit === fit.name ? 'selected' : ''}`}
                          >
                            <img src={fit.image} alt={fit.name} className="carousel-image" />
                            <p>{fit.name}</p>
                          </div>
                        </SwiperSlide>
                      ))}
                      <div className="swiper-pagination fit-pagination"></div>
                    </Swiper>
                    <div 
                      className="hover-zone hover-zone-right"
                      onMouseEnter={() => startFitAutoplay('right')}
                      onMouseLeave={stopFitAutoplay}
                    />
                  </div>
                </div>

                <div className="design-section lapel">
                  <h3>Select Lapel</h3>
                  <div className="slider-container">
                    <div 
                      className="hover-zone hover-zone-left"
                      onMouseEnter={() => startAutoplay(lapelSliderRef, lapelIntervalRef, 'left')}
                      onMouseLeave={() => stopAutoplay(lapelIntervalRef)}
                    />
                    <Swiper
                      ref={lapelSliderRef}
                      {...sliderSettings}
                      className="lapel-swiper"
                    >
                      {lapelOptions.map((lapel) => (
                        <SwiperSlide key={lapel.name}>
                          <div 
                            onClick={() => {
                              if (lapel.name === 'Add') {
                                handleAddClick('lapel');
                                return;
                              }
                              handleOptionClick('lapel', lapel.name);
                            }}
                            className={`carousel-item ${selectedLapel === lapel.name ? 'selected' : ''}`}
                          >
                            <img src={lapel.image} alt={lapel.name} className="carousel-image" />
                            <p>{lapel.name}</p>
                          </div>
                        </SwiperSlide>
                      ))}
                      <div className="swiper-pagination lapel-pagination"></div>
                    </Swiper>
                    <div 
                      className="hover-zone hover-zone-right"
                      onMouseEnter={() => startAutoplay(lapelSliderRef, lapelIntervalRef, 'right')}
                      onMouseLeave={() => stopAutoplay(lapelIntervalRef)}
                    />
                  </div>
                </div>

                <div className="design-section material">
                  <h3>Select Material</h3>
                  <div className="slider-container">
                    <div 
                      className="hover-zone hover-zone-left"
                      onMouseEnter={() => startAutoplay(materialSliderRef, materialIntervalRef, 'left')}
                      onMouseLeave={() => stopAutoplay(materialIntervalRef)}
                    />
                    <Swiper
                      ref={materialSliderRef}
                      {...sliderSettings}
                      className="material-swiper"
                    >
                      {materialOptions.map((material) => (
                        <SwiperSlide key={material.name}>
                          <div 
                            onClick={() => {
                              if (material.name === 'Add') {
                                handleAddClick('material');
                                return;
                              }
                              handleOptionClick('material', material.name);
                            }}
                            className={`carousel-item ${selectedMaterial === material.name ? 'selected' : ''}`}
                          >
                            <img src={material.image} alt={material.name} className="carousel-image" />
                            <p>{material.name}</p>
                          </div>
                        </SwiperSlide>
                      ))}
                      <div className="swiper-pagination material-pagination"></div>
                    </Swiper>
                    <div 
                      className="hover-zone hover-zone-right"
                      onMouseEnter={() => startAutoplay(materialSliderRef, materialIntervalRef, 'right')}
                      onMouseLeave={() => stopAutoplay(materialIntervalRef)}
                    />
                  </div>
                </div>

                <div className="design-section pattern">
                  <h3>Select Pattern</h3>
                  <div className="slider-container">
                    <div 
                      className="hover-zone hover-zone-left"
                      onMouseEnter={() => startAutoplay(patternSliderRef, patternIntervalRef, 'left')}
                      onMouseLeave={() => stopAutoplay(patternIntervalRef)}
                    />
                    <Swiper
                      ref={patternSliderRef}
                      {...sliderSettings}
                      className="pattern-swiper"
                    >
                      {patternOptions.map((pattern) => (
                        <SwiperSlide key={pattern.name}>
                          <div 
                            onClick={() => {
                              if (pattern.name === 'Add') {
                                handleAddClick('pattern');
                                return;
                              }
                              handleOptionClick('pattern', pattern.name);
                            }}
                            className={`carousel-item ${selectedPattern === pattern.name ? 'selected' : ''}`}
                          >
                            <img src={pattern.image} alt={pattern.name} className="carousel-image" />
                            <p>{pattern.name}</p>
                          </div>
                        </SwiperSlide>
                      ))}
                      <div className="swiper-pagination pattern-pagination"></div>
                    </Swiper>
                    <div 
                      className="hover-zone hover-zone-right"
                      onMouseEnter={() => startAutoplay(patternSliderRef, patternIntervalRef, 'right')}
                      onMouseLeave={() => stopAutoplay(patternIntervalRef)}
                    />
                  </div>
                </div>

                <div className="design-section jacket">
                  <h3>Select Jacket</h3>
                  <div className="slider-container">
                    <div 
                      className="hover-zone hover-zone-left"
                      onMouseEnter={() => startAutoplay(jacketSliderRef, jacketIntervalRef, 'left')}
                      onMouseLeave={() => stopAutoplay(jacketIntervalRef)}
                    />
                    <Swiper
                      ref={jacketSliderRef}
                      {...sliderSettings}
                      className="jacket-swiper"
                    >
                      {jacketOptions.map((jacket) => (
                        <SwiperSlide key={jacket.name}>
                          <div 
                            onClick={() => {
                              if (jacket.name === 'Add') {
                                handleAddClick('jacket');
                                return;
                              }
                              handleOptionClick('jacket', jacket.name);
                            }}
                            className={`carousel-item ${selectedJacket === jacket.name ? 'selected' : ''}`}
                          >
                            <img src={jacket.image} alt={jacket.name} className="carousel-image" />
                            <p>{jacket.name}</p>
                          </div>
                        </SwiperSlide>
                      ))}
                      <div className="swiper-pagination jacket-pagination"></div>
                    </Swiper>
                    <div 
                      className="hover-zone hover-zone-right"
                      onMouseEnter={() => startAutoplay(jacketSliderRef, jacketIntervalRef, 'right')}
                      onMouseLeave={() => stopAutoplay(jacketIntervalRef)}
                    />
                  </div>
                </div>

                {/* Color Section */}
                <div className="design-section colors">
                  <h3>Select Color</h3>
                  {!showColorPicker ? (
                    <div className="color-presets-container">
                      {colorPresets.map((color) => (
                        <div 
                          key={color.name}
                          onClick={() => color.name === 'Custom' 
                            ? setShowColorPicker(true) 
                            : handleOptionClick('color', { hex: color.hex })}
                          className={`color-preset-item ${selectedColor === color.hex ? 'selected' : ''}`}
                        >
                          {color.name === 'Custom' ? (
                            <>
                              <img src={color.image} alt="Custom color" className="color-wheel-icon" />
                              <p>Custom Color</p>
                            </>
                          ) : (
                            <>
                              <div 
                                className="color-swatch" 
                                style={{ backgroundColor: color.hex }}
                              />
                              <p>{color.name}</p>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="color-picker-container">
                      <button 
                        onClick={() => setShowColorPicker(false)}
                        className="back-to-presets-btn"
                      >
                        ← Back to Presets
                      </button>
                      <ColorPicker 
                        selectedColor={selectedColor} 
                        onColorChange={(color) => handleOptionClick('color', color)} 
                      />
                    </div>
                  )}
                </div>

                {/* Generate Button Section */}
                <div className="design-section generate">
                  <div className="visualization-container">
                    <button
                      onClick={generateVisualizations}
                      disabled={
                        !selectedFit ||
                        !selectedLapel ||
                        !selectedMaterial ||
                        !selectedPattern ||
                        !selectedJacket ||
                        !selectedColor ||
                        selectedColor === '#ffffff' ||
                        loading
                      }
                      className={`visualization-button ${
                        (!selectedFit || !selectedLapel || !selectedMaterial || !selectedPattern || !selectedJacket || !selectedColor || selectedColor === '#ffffff') ? 'disabled' : ''
                      }`}
                    >
                      {loading ? 'Generating...' : isGenerationComplete ? 'Regenerate' : 'Generate Visualization'}
                    </button>
                    
                    {loading && (
                      <div className="progress-container">
                        <div className="progress-bar">
                          <div className="progress" style={{ width: `${progress}%` }}></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                
              </div>
            ) : (
              <div className="dress-options">
                <div className="back-section">
                  <button 
                    className="back-button"
                    onClick={() => setAttireType(null)}
                  >
                    ← Back to Attire Type
                  </button>
                </div>

                <div className="design-section dress-style">
                  <h3>Select Dress Style</h3>
                  <div className="slider-container">
                    <div 
                      className="hover-zone hover-zone-left"
                      onMouseEnter={() => startAutoplay(dressStyleSliderRef, dressStyleIntervalRef, 'left')}
                      onMouseLeave={() => stopAutoplay(dressStyleIntervalRef)}
                    />
                    <Swiper
                      ref={dressStyleSliderRef}
                      {...sliderSettings}
                      className="dress-style-swiper"
                    >
                      {dressStyleOptions.map((style) => (
                        <SwiperSlide key={style.name}>
                          <div 
                            onClick={() => handleOptionClick('dressStyle', style.name)}
                            className={`carousel-item ${selectedDressStyle === style.name ? 'selected' : ''}`}
                          >
                            <img src={style.image} alt={style.name} className="carousel-image" />
                            <p>{style.name}</p>
                          </div>
                        </SwiperSlide>
                      ))}
                      <div className="swiper-pagination dress-style-pagination"></div>
                    </Swiper>
                    <div 
                      className="hover-zone hover-zone-right"
                      onMouseEnter={() => startAutoplay(dressStyleSliderRef, dressStyleIntervalRef, 'right')}
                      onMouseLeave={() => stopAutoplay(dressStyleIntervalRef)}
                    />
                  </div>
                </div>

                <div className="design-section neckline">
                  <h3>Select Neckline</h3>
                  <div className="slider-container">
                    <div 
                      className="hover-zone hover-zone-left"
                      onMouseEnter={() => startAutoplay(necklineSliderRef, necklineIntervalRef, 'left')}
                      onMouseLeave={() => stopAutoplay(necklineIntervalRef)}
                    />
                    <Swiper
                      ref={necklineSliderRef}
                      {...sliderSettings}
                      className="neckline-swiper"
                    >
                      {dressNecklineOptions.map((neckline) => (
                        <SwiperSlide key={neckline.name}>
                          <div 
                            onClick={() => handleOptionClick('neckline', neckline.name)}
                            className={`carousel-item ${selectedNeckline === neckline.name ? 'selected' : ''}`}
                          >
                            <img src={neckline.image} alt={neckline.name} className="carousel-image" />
                            <p>{neckline.name}</p>
                          </div>
                        </SwiperSlide>
                      ))}
                      <div className="swiper-pagination neckline-pagination"></div>
                    </Swiper>
                    <div 
                      className="hover-zone hover-zone-right"
                      onMouseEnter={() => startAutoplay(necklineSliderRef, necklineIntervalRef, 'right')}
                      onMouseLeave={() => stopAutoplay(necklineIntervalRef)}
                    />
                  </div>
                </div>

                <div className="design-section fabric">
                  <h3>Select Fabric</h3>
                  <div className="slider-container">
                    <div 
                      className="hover-zone hover-zone-left"
                      onMouseEnter={() => startAutoplay(fabricSliderRef, fabricIntervalRef, 'left')}
                      onMouseLeave={() => stopAutoplay(fabricIntervalRef)}
                    />
                    <Swiper
                      ref={fabricSliderRef}
                      {...sliderSettings}
                      className="fabric-swiper"
                    >
                      {dressFabricOptions.map((fabric) => (
                        <SwiperSlide key={fabric.name}>
                          <div 
                            onClick={() => handleOptionClick('fabric', fabric.name)}
                            className={`carousel-item ${selectedFabric === fabric.name ? 'selected' : ''}`}
                          >
                            <img src={fabric.image} alt={fabric.name} className="carousel-image" />
                            <p>{fabric.name}</p>
                          </div>
                        </SwiperSlide>
                      ))}
                      <div className="swiper-pagination fabric-pagination"></div>
                    </Swiper>
                    <div 
                      className="hover-zone hover-zone-right"
                      onMouseEnter={() => startAutoplay(fabricSliderRef, fabricIntervalRef, 'right')}
                      onMouseLeave={() => stopAutoplay(fabricIntervalRef)}
                    />
                  </div>
                </div>

                <div className="design-section length">
                  <h3>Select Length</h3>
                  <div className="slider-container">
                    <div 
                      className="hover-zone hover-zone-left"
                      onMouseEnter={() => startAutoplay(lengthSliderRef, lengthIntervalRef, 'left')}
                      onMouseLeave={() => stopAutoplay(lengthIntervalRef)}
                    />
                    <Swiper
                      ref={lengthSliderRef}
                      {...sliderSettings}
                      className="length-swiper"
                    >
                      {dressLengthOptions.map((length) => (
                        <SwiperSlide key={length.name}>
                          <div 
                            onClick={() => handleOptionClick('length', length.name)}
                            className={`carousel-item ${selectedLength === length.name ? 'selected' : ''}`}
                          >
                            <img src={length.image} alt={length.name} className="carousel-image" />
                            <p>{length.name}</p>
                          </div>
                        </SwiperSlide>
                      ))}
                      <div className="swiper-pagination length-pagination"></div>
                    </Swiper>
                    <div 
                      className="hover-zone hover-zone-right"
                      onMouseEnter={() => startAutoplay(lengthSliderRef, lengthIntervalRef, 'right')}
                      onMouseLeave={() => stopAutoplay(lengthIntervalRef)}
                    />
                  </div>
                </div>

                <div className="design-section details">
                  <h3>Select Details</h3>
                  <div className="slider-container">
                    <div 
                      className="hover-zone hover-zone-left"
                      onMouseEnter={() => startAutoplay(detailsSliderRef, detailsIntervalRef, 'left')}
                      onMouseLeave={() => stopAutoplay(detailsIntervalRef)}
                    />
                    <Swiper
                      ref={detailsSliderRef}
                      {...sliderSettings}
                      className="details-swiper"
                    >
                      {dressDetailsOptions.map((detail) => (
                        <SwiperSlide key={detail.name}>
                          <div 
                            onClick={() => handleOptionClick('details', detail.name)}
                            className={`carousel-item ${selectedDetails === detail.name ? 'selected' : ''}`}
                          >
                            <img src={detail.image} alt={detail.name} className="carousel-image" />
                            <p>{detail.name}</p>
                          </div>
                        </SwiperSlide>
                      ))}
                      <div className="swiper-pagination details-pagination"></div>
                    </Swiper>
                    <div 
                      className="hover-zone hover-zone-right"
                      onMouseEnter={() => startAutoplay(detailsSliderRef, detailsIntervalRef, 'right')}
                      onMouseLeave={() => stopAutoplay(detailsIntervalRef)}
                    />
                  </div>
                </div>

                {/* Color Section */}
                <div className="design-section colors">
                  <h3>Select Color</h3>
                  {!showColorPicker ? (
                    <div className="color-presets-container">
                      {colorPresets.map((color) => (
                        <div 
                          key={color.name}
                          onClick={() => color.name === 'Custom' 
                            ? setShowColorPicker(true) 
                            : handleOptionClick('color', { hex: color.hex })}
                          className={`color-preset-item ${selectedColor === color.hex ? 'selected' : ''}`}
                        >
                          {color.name === 'Custom' ? (
                            <>
                              <img src={color.image} alt="Custom color" className="color-wheel-icon" />
                              <p>Custom Color</p>
                            </>
                          ) : (
                            <>
                              <div 
                                className="color-swatch" 
                                style={{ backgroundColor: color.hex }}
                              />
                              <p>{color.name}</p>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="color-picker-container">
                      <button 
                        onClick={() => setShowColorPicker(false)}
                        className="back-to-presets-btn"
                      >
                        ← Back to Presets
                      </button>
                      <ColorPicker 
                        selectedColor={selectedColor} 
                        onColorChange={(color) => handleOptionClick('color', color)} 
                      />
                    </div>
                  )}
                </div>

                {/* Generate Button Section */}
                <div className="design-section generate">
                  <div className="visualization-container">
                    <button
                      onClick={generateVisualizations}
                      disabled={
                        !selectedDressStyle ||
                        !selectedNeckline ||
                        !selectedFabric ||
                        !selectedLength ||
                        !selectedDetails ||
                        !selectedColor ||
                        selectedColor === '#ffffff' ||
                        loading
                      }
                      className={`visualization-button ${
                        (!selectedDressStyle || !selectedNeckline || !selectedFabric || !selectedLength || !selectedDetails || !selectedColor || selectedColor === '#ffffff') ? 'disabled' : ''
                      }`}
                    >
                      {loading ? 'Generating...' : isGenerationComplete ? 'Regenerate' : 'Generate Visualization'}
                    </button>
                    
                    {loading && (
                      <div className="progress-container">
                        <div className="progress-bar">
                          <div className="progress" style={{ width: `${progress}%` }}></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

               
              </div>
            )}
          </div>
  
          {/* Canvas Section - Updated with proper container */}
          <div className="canvas-container">
            <WeddingAttireCanvas 
              progress={progress}
              loading={loading}
              setLoading={setLoading}
              generateVisualizations={generateVisualizations}
              isGenerationComplete={isGenerationComplete}
              attireVisualizationUrls={attireVisualizationUrls}
              attirePrompt={attirePrompt}
            />
          </div>
        </div>
        
    
        {showAddInput.fit && (
          <div className="add-theme-overlay">
            <div className="add-theme-input">
              <h4>What other fit should we add?</h4>
              <input 
                type="text" 
                value={newInput.fit}
                onChange={(e) => handleInputChange(e, 'fit')}
                placeholder="Suggest new fit..."
                autoFocus
              />
              <div className="add-theme-buttons">
                <button onClick={() => setShowAddInput(prev => ({ ...prev, fit: false }))}>Cancel</button>
                <button onClick={() => handleNewInputSubmit('fit')}>Submit</button>
              </div>
            </div>
          </div>
        )}
  
        {showAddInput.lapel && (
          <div className="add-theme-overlay">
            <div className="add-theme-input">
              <h4>What other lapel should we add?</h4>
              <input 
                type="text" 
                value={newInput.lapel}
                onChange={(e) => handleInputChange(e, 'lapel')}
                placeholder="Suggest new lapel..."
                autoFocus
              />
              <div className="add-theme-buttons">
                <button onClick={() => setShowAddInput(prev => ({ ...prev, lapel: false }))}>Cancel</button>
                <button onClick={() => handleNewInputSubmit('lapel')}>Submit</button>
              </div>
            </div>
          </div>
        )}
  
        {showAddInput.material && (
          <div className="add-theme-overlay">
            <div className="add-theme-input">
              <h4>What other material should we add?</h4>
              <input 
                type="text" 
                value={newInput.material}
                onChange={(e) => handleInputChange(e, 'material')}
                placeholder="Suggest new material..."
                autoFocus
              />
              <div className="add-theme-buttons">
                <button onClick={() => setShowAddInput(prev => ({ ...prev, material: false }))}>Cancel</button>
                <button onClick={() => handleNewInputSubmit('material')}>Submit</button>
              </div>
            </div>
          </div>
        )}
  
  
        {showAddInput.pattern && (
          <div className="add-theme-overlay">
            <div className="add-theme-input">
              <h4>What other pattern should we add?</h4>
              <input 
                type="text" 
                value={newInput.pattern}
                onChange={(e) => handleInputChange(e, 'pattern')}
                placeholder="Suggest new pattern..."
                autoFocus
              />
              <div className="add-theme-buttons">
                <button onClick={() => setShowAddInput(prev => ({ ...prev, pattern: false }))}>Cancel</button>
                <button onClick={() => handleNewInputSubmit('pattern')}>Submit</button>
              </div>
            </div>
          </div>
        )}
  
        {showAddInput.jacket && (
          <div className="add-theme-overlay">
            <div className="add-theme-input">
              <h4>What jacket should we add?</h4>
              <input 
                type="text" 
                value={newInput.jacket}
                onChange={(e) => handleInputChange(e, 'jacket')}
                placeholder="Suggest new jacket..."
                autoFocus
              />
              <div className="add-theme-buttons">
                <button onClick={() => setShowAddInput(prev => ({ ...prev, jacket: false }))}>Cancel</button>
                <button onClick={() => handleNewInputSubmit('jacket')}>Submit</button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  
  export default WeddingAttire;