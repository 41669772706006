import React from 'react';
import TermsOfServiceContent from './TermsOfServiceContent.js';
import './TermsOfService.css';
import Footer from '../landingPage/Footer.js';

const TermsOfService = () => {
    const renderList = (list) => (
        <ul className="list">
            {list.map((item, index) => (
                <li key={index}>{item}</li>
            ))}
        </ul>
    );

    return (
        <div className="terms-of-service-wrapper">
            <div className="terms-of-service-container">
                <h1>{TermsOfServiceContent[0].title}</h1>
                <p className="effective-date">Effective Date: {TermsOfServiceContent[0].effectiveDate}</p>

                <section>
                    <p>{TermsOfServiceContent[0].introduction}</p>
                </section>

                {TermsOfServiceContent[0].sections.map((section, index) => (
                    <section key={index}>
                        <h2>{section.title}</h2>
                        <p>{section.content}</p>
                        {section.subtitles && section.subtitles.map((subtitle, subIndex) => (
                            <div key={subIndex}>
                                <strong>{subtitle.subtitle}</strong>
                                {Array.isArray(subtitle.content) ? (
                                    subtitle.content.map((item, itemIndex) => (
                                        <div key={itemIndex}>
                                            <strong>{item.title}</strong>
                                            {item.list ? renderList(item.list) : <p>{item.content}</p>}
                                        </div>
                                    ))
                                ) : (
                                    subtitle.list ? renderList(subtitle.list) : <p>{subtitle.content}</p>
                                )}
                            </div>
                        ))}
                    </section>
                ))}
            </div>
            <Footer />
        </div>
    );
};

export default TermsOfService;